import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import axios from "axios";

// import SubscriptionDialog from "./SubscriptionDialog"
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import TasksHeaderMobile from "../Header/TasksHeaderMobile";
import "./main.css";
import "./Accounts.css";

import ProjectAccordian from "./ProjectAccordian"
import { userService } from "../../Services/UserService"
import { Button } from "@material-ui/core";
var config = require("../../Config/config");

class Accounts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: localStorage.getItem("user"),
      pricingOpen: false,
      domain: "",
      redirect: "",
      loading: false,
      buttonActive: true,
      snackbarOpen: false,
      snackbarMessage: undefined,
      projects: [],
      projectId: "",
      errorMsg: "",
      subInfo: ""
    };
  }
  componentWillMount() {
    const user = localStorage.getItem('user')
    if (user === 'Guest') {
      window.location.replace('/login');
    }
  }

  componentDidMount() {
    const auth = config.middleware.url;
    const url = auth + "/tokencheck";
    axios
      .get(url, { headers: { Authorization: "Bearer " + localStorage.getItem("token") } })
      .then(async (res) => {
        if (!res.data.success) { return userService.guest() }
        else {
          let projects = await axios.get(
            `${process.env.REACT_APP_MAI_SERVER_HOST}/api/v1/projects`,
            { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
          );
          if (projects.data.length) {
            this.setState({
              projects: projects.data,
              projectId: projects.data.length > 0 ? projects.data[projects.data.length - 1]._id : ""
            });
          }
        }
      })
      .catch(err => { console.log(err.message); });

    let { message } = window.history.state || { message: "" }
    if (message) {
      this.setState({ snackbarMessage: message, snackbarOpen: true })
      window.history.replaceState(null, '')
    }
  }



  render() {
    const {history}=this.props;

    const handleClosePricing = value => {
      this.setState({ pricingOpen: false });
      const { user } = this.state;
      if (value === "Free") {
        if (user === "Guest") {
          window.history.pushState(
            {
              from: { pathname: window.location.pathname }
            },
            "",
            "/signup"
          );
          window.history.go();
        }
      } else if (value !== "None") {
        if (user === "Guest") {
          window.history.pushState(
            {
              from: { pathname: window.location.pathname }
            },
            "",
            "/login"
          );
          window.history.go();
        } else {
          localStorage.setItem("pricing_plan", value);
          window.location.href = "/payment";
        }
      }
    };

    const { domain } = this.state;
    return (

      <div>
        <div className = "desktop-view">
        <Header
          projectId={this.state.projectId}
          projects={this.state.projects}
          setHeaderSelection={() => { }}
        />
        {localStorage.getItem('user') !== 'Guest' ?
          <div style={{width:"80%", marginLeft:"10%"}} >
            <div className="acc-body" >
              <h2 style={{color: "#515c6f", fontSize: "38px"}}>Accounts</h2>
              
              <div className="userInfoContainer">
                <div className="userInfoBox" >
                  <h3>Projects:</h3>
                  <ProjectAccordian projects={this.state.projects}></ProjectAccordian>
                </div>
              </div>

            </div>
            
          </div> :
          ""}

      </div>

      <div className="mob-view">
      <TasksHeaderMobile hideTitle={true} changeTitleName="Accounts" hideFilters={true} history={history} />
      {localStorage.getItem('user') !== 'Guest' ?
          <div>
            <div className="acc-body">
              <hr></hr>
              <div className="userInfoContainer">
                <div className="userInfoBox">
                  <h3>Projects:</h3>
                  <ProjectAccordian projects={this.state.projects}></ProjectAccordian>
                </div>
              </div>

            </div>
            
          </div> :
          ""}
      <Footer />
      </div>


      </div>

    );
  }
}

export default withRouter(Accounts);
