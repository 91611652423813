import React from "react";
import { userService } from "../../Services";
import TextField from "@material-ui/core/TextField";
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import axios from "axios";
import Header from "../Header/Header.js";
import Footer from "../Footer/Footer";
import userImg from "../image/user.svg";
import passwordImg from "../image/password.svg";
// import arrowImg from "../image/Arrow.svg";
import { GoogleLogin } from "react-google-login";
import { LinkedIn } from "react-linkedin-login-oauth2";

import "../TaskDetails/taskDetail.css";
import "../Tasks/style.css";
import "./LoginPage.css";

import LogoIcon from "../LogoIcon";

var config = require("../../Config/config");

export class LoginView extends React.Component {
  constructor(props) {
    super(props);

    // userService.logout();

    this.state = {
      email: "",
      password: "",
      submitted: false,
      loading: false,
      g_loading: false,
      l_loading: false,
      error: false,
      errorMessage: "",
      loggedOut: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    const auth = config.middleware.url;
    const url = auth + "/tokencheck";
    axios
      .get(url, { headers: { Authorization: "Bearer " + localStorage.getItem("token") } })
      .then(res => {
        if (res.data.success &&
          res.data.message.search("Middleware")) {
          window.location.replace('/home')
        } else {
          this.setState({ loggedOut: true })
        }
      })
      .catch(err => { console.log(err.message); });
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { email, password } = this.state;

    // stop here if form is invalid
    if (!(email && password)) {
      return;
    }

    if (!/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
      this.setState({
        error: true,
        errorMessage: email + " is not a vaild email address",
        loading: false
      })
      /*
      setTimeout(() => {
        this.setState({
          error: false,
          errorMessage: ""
        })
      }, 4000)
      */
      return
    }

    this.setState({ loading: true });
    userService.login(email, password).then(
      message => {
        const { from } = window.history.state || {
          from: { pathname: "/" }
        };
        from.pathname = from.pathname ? from.pathname : "/"
        window.history.replaceState({ message }, '', from.pathname)
        window.history.go()
      },
      // error => this.setState({ error, loading: false })

    ).catch(error => {
      let message = ""
      try {
        message = error.response.data.message
      } catch {
        message = "Error connecting to authentication server"
      }
      this.setState({
        error: true,
        errorMessage: message,
        loading: false
      })
      /*
      setTimeout(() => {
        this.setState({
          error: false,
          errorMessage: ""
        })
      }, 4000)
      */
    });
  }

  googleLogin = response => {
    this.setState({ g_loading: true });
    const tokenId = response.tokenId;
    userService.loginGoogle(tokenId).then(
      message => {
        console.log(message)
        const { from } = window.history.state || {
          from: { pathname: "/" }
        };
        from.pathname = from.pathname ? from.pathname : "/"
        window.history.replaceState({ message }, '', from.pathname)
        window.history.go()
      },
      error => this.setState({ error, g_loading: false })
    );
  };

  linkedinLogin = data => {
    this.setState({ l_loading: true })
    const code = data.code;
    userService.loginLinkedin(code).then(
      message => {
        console.log(message)
        const { from } = window.history.state || {
          from: { pathname: "/" }
        };
        from.pathname = from.pathname ? from.pathname : "/"
        window.history.replaceState({ message }, '', from.pathname)
        window.history.go()
      },
      error => this.setState({ error, l_loading: false })
    );
  };

  handleFailure = error => {
    console.log(error);
  };
  render() {
    const { email, password, loading, error } = this.state;

    return (
      <>
        {/* ..........login/main heading........ */}
        <div className = "desktop-view">
        <div className="login-main-back">
          <section className="mai-heading">
            <LogoIcon width="176px" type="img" />
          </section>
          {/* ..........login/forgot............. */}
          <section className="mai-login-forgot-n">
            {/* <div className="signin-link">
              <a
                style={{ color: "#E7EAF0" }}
                onClick={() => {
                  window.location.replace("/signup");
                }}
              >
                Sign Up
              </a>
            </div> */}
            <div className="mai-login-link-n">
              <a
                onClick={() => {
                  window.location.replace("/login");
                }}
              >
                Log In
              </a>
            </div>&nbsp;
            <div className="mai-forgot-link-n">
              <a
                style={{ color: "#E7EAF0" }}
                onClick={() => {
                  window.location.replace("/forgot");
                }}
              >
                Forgot Password</a>
            </div>
          </section>
          {/* ..........login/input............... */}
          <form onSubmit={this.handleSubmit}>
            <section className="mai-login-input">
              {this.state.error ?
                <div className="error-text">
                  <FormHelperText error id="component-error-text">{this.state.errorMessage}</FormHelperText>
                </div>
                :
                ''
              }
              <div className="mai-login-competitors-container">
                <div className="login-left-icon">
                  <img src={userImg} />
                </div>
                <div className="login-right-icon">
                  <div className="web-address-input-container">
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="E-mail"
                      name="email"
                      value={email}
                      autoComplete="email"
                      onChange={this.handleChange}
                      // error={error && true}
                      autoFocus
                    />
                  </div>
                </div>
              </div>
              <div className="mai-login-competitors-container pass-border">
                <div className="login-left-icon">
                  <img src={passwordImg} />
                </div>
                <div className="login-right-icon">
                  <div className="web-address-input-container">
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      value={password}
                      id="password"
                      autoComplete="current-password"
                      onChange={this.handleChange}
                    // error={error && true}
                    />
                  </div>
                </div>
              </div>
            </section>
            {/* ..........login/login-button.............. */}
            <section className="login-button">
              <button
                type="submit"
                className="mai-login-btn-compare"
                disabled={loading}
              >
                {!loading ? 'LOG IN' : 'Verifying...'}
                <div className="right-icon-container">
                  {
                    this.state.loading ?
                      <CircularProgress className="spinner" size={18} /> :
                      <KeyboardArrowRightIcon />
                  }
                </div>
              </button>
            </section>
          </form>
          <div className="mai-login-below-text">or use</div>
          {/* ..........login/goole-button.............. */}
          <section className="social-button-back">
            <GoogleLogin
              clientId={config.auth.googleClientId}
              render={renderProps => (
                <button
                  onClick={renderProps.onClick}
                  disabled={loading?loading:renderProps.disabled}
                  className="mai-google-button"
                >
                  {
                    this.state.g_loading ?
                      <CircularProgress className="spinner" size={18} /> :
                      'CONTINUE WITH GOOGLE'
                  }
                </button>
              )}
              buttonText="Login"
              onSuccess={this.googleLogin}
              onFailure={this.handleFailure}
              cookiePolicy={"single_host_origin"}
            />
          </section>
          {/* ..........login/linkedin-button.............. */}
          <section className="social-button-back">
            <LinkedIn
              clientId={config.auth.linkedinClientId}
              onFailure={this.handleFailure}
              onSuccess={this.linkedinLogin}
              redirectUri={config.auth.linkedinRedirectUri}
              scope="r_liteprofile r_emailaddress"
              renderElement={({ onClick, disabled }) => (
                <button
                  onClick={onClick}
                  disabled={loading?loading:disabled}
                  className="mai-li-button"
                >
                  {
                    this.state.l_loading ?
                      <CircularProgress className="spinner" size={18} /> :
                      'CONTINUE WITH LinkedIn'
                  }
                </button>
              )}
            />
          </section>
          {/* ..........login/swipe option.............. */}
          <section className="swipe-back">
            <div className="swipe">
              Don’t have an account?&nbsp;
              <a onClick={() => {
                const { from } = window.history.state || {
                  from: { pathname: "/" }
                };
                window.history.replaceState({ from }, '', '/signup')
                window.history.go()
              }}
                style={{ cursor: "pointer", color: 'red' }}
                className="create-acc"
              >
                Create a new account.
              </a>
            </div>
          </section>
          <br/>
          <Footer />
        </div>
        </div>
        <div className="mob-view">
        <div className="mai-login-main-back">
          <section className="mai-heading">
            <LogoIcon width="176px" type="img" />
          </section>
          {/* ..........login/forgot............. */}
          <section className="mai-login-forgot-n">
            {/* <div className="signin-link">
              <a
                style={{ color: "#E7EAF0" }}
                onClick={() => {
                  window.location.replace("/signup");
                }}
              >
                Sign Up
              </a>
            </div> */}
            <div className="mai-login-link-n">
              <a
                onClick={() => {
                  window.location.replace("/login");
                }}
              >
                Log In
              </a>
            </div>&nbsp;
            <div className="mai-forgot-link-n">
              <a
                style={{ color: "#E7EAF0" }}
                onClick={() => {
                  window.location.replace("/forgot");
                }}
              >
                Forgot Password</a>
            </div>
          </section>
          {/* ..........login/input............... */}
          <form onSubmit={this.handleSubmit}>
            <section className="mai-login-input">
              {this.state.error ?
                <div className="error-text">
                  <FormHelperText error id="component-error-text">{this.state.errorMessage}</FormHelperText>
                </div>
                :
                ''
              }
              <div className="mai-login-competitors-container">
                <div className="login-left-icon">
                  <img src={userImg} />
                </div>
                <div className="login-right-icon">
                  <div className="web-address-input-container">
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="E-mail"
                      name="email"
                      value={email}
                      autoComplete="email"
                      onChange={this.handleChange}
                      // error={error && true}
                      autoFocus
                    />
                  </div>
                </div>
              </div>
              <div className="mai-login-competitors-container pass-border">
                <div className="login-left-icon">
                  <img src={passwordImg} />
                </div>
                <div className="login-right-icon">
                  <div className="web-address-input-container">
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      value={password}
                      id="password"
                      autoComplete="current-password"
                      onChange={this.handleChange}
                    // error={error && true}
                    />
                  </div>
                </div>
              </div>
            </section>
            {/* ..........login/login-button.............. */}
            <section className="login-button">
              <button
                type="submit"
                className="mai-login-btn-compare"
                disabled={loading}
              >
                {!loading ? 'LOG IN' : 'Verifying...'}
                <div className="right-icon-container">
                  {
                    this.state.loading ?
                      <CircularProgress className="spinner" size={18} /> :
                      <KeyboardArrowRightIcon />
                  }
                </div>
              </button>
            </section>
          </form>
          <div className="mai-login-below-text">or use</div>
          {/* ..........login/goole-button.............. */}
          <section className="social-button-back">
            <GoogleLogin
              clientId={config.auth.googleClientId}
              render={renderProps => (
                <button
                  onClick={renderProps.onClick}
                  disabled={loading?loading:renderProps.disabled}
                  className="mai-google-button"
                >
                  {
                    this.state.g_loading ?
                      <CircularProgress className="spinner" size={18} /> :
                      'CONTINUE WITH GOOGLE'
                  }
                </button>
              )}
              buttonText="Login"
              onSuccess={this.googleLogin}
              onFailure={this.handleFailure}
              cookiePolicy={"single_host_origin"}
            />
          </section>
          {/* ..........login/linkedin-button.............. */}
          <section className="social-button-back">
            <LinkedIn
              clientId={config.auth.linkedinClientId}
              onFailure={this.handleFailure}
              onSuccess={this.linkedinLogin}
              redirectUri={config.auth.linkedinRedirectUri}
              scope="r_liteprofile r_emailaddress"
              renderElement={({ onClick, disabled }) => (
                <button
                  onClick={onClick}
                  disabled={loading?loading:disabled}
                  className="mai-li-button"
                >
                  {
                    this.state.l_loading ?
                      <CircularProgress className="spinner" size={18} /> :
                      'CONTINUE WITH LinkedIn'
                  }
                </button>
              )}
            />
          </section>
          {/* ..........login/swipe option.............. */}
          <section className="swipe-back">
            <div className="swipe">
              Don’t have an account?&nbsp;
              <a onClick={() => {
                const { from } = window.history.state || {
                  from: { pathname: "/" }
                };
                window.history.replaceState({ from }, '', '/signup')
                window.history.go()
              }}
                style={{ cursor: "pointer", color: 'red' }}
                className="create-acc"
              >
                Create a new account.
              </a>
            </div>
          </section>
          <br/>
          <Footer />
        </div>

        </div>
      </>
    );
  }
}

class LoginPage extends LoginView {
  constructor(props) {
    super(props);
  }
  render() {
    return this.state.loggedOut && (
      <div className="login-wrap" style={{background:"white"}}>
        {/* ..........login/header.............. */}
        {/* <header className="login-header">
          <a href="/home">
            <div className="mai-logo">
            <LogoIcon height="auto" width="150px" type="img" />
            </div>
          </a>
        </header> */}
        <Header hideUserMenu={true}/>
        <LoginView />;
        
      </div>
    );
  }
}
export default LoginPage;
