import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import "./index.css";
import App from "./App";

var SENTRY = process.env.REACT_APP_MAI_SENTRY;

Sentry.init({
  dsn: SENTRY
});

ReactDOM.render(<App />, document.getElementById("root"));
