import React, { Component } from "react";
import PropTypes from 'prop-types';
import warningIcon from '../image/warning.svg'
import '../../App.css'
import '../Header/Header.css'
import '../Footer/Footer.css'
import './pageDetails.css'

class IssuesList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showEntireHTML: false,
        };
    }

    render() {
        const { showEntireHTML } = this.state;
        const { htmlAttrs } = this.props;

        const anchor = htmlAttrs.anchor ? htmlAttrs.anchor : [];
        const headlines = htmlAttrs.headlines ? htmlAttrs.headlines : {h1: [], h2: [], h3: [], h4: [], h5: [], h6: []};
        const schema = htmlAttrs.schema ? htmlAttrs.schema : {};
        const metadata = htmlAttrs.metadata ? htmlAttrs.metadata : {};
        const opengraph = htmlAttrs.opengraph ? htmlAttrs.opengraph : {};
        const twitter = htmlAttrs.twitter ? htmlAttrs.twitter : {};

        return (
            <section className="table">
                {htmlAttrs.title &&
                    <div className="row">
                        <div className="col-sm-3 col-left">
                            <div className="red-circle">
                                <img src={warningIcon} alt="attr" />
                            </div>
                            <div className="right-heading">TITLE</div>
                        </div>
                        <div className="col-sm-9 col-right">
                            
                            <div>{htmlAttrs.title}</div>
                        </div>
                    </div>
                }

                {Object.keys(headlines).map(headline => (
                    headlines[headline].length > 0 &&
                        <div className="row">
                            <div className="col-sm-3 col-left">
                                <div className="red-circle">
                                    <img src={warningIcon} alt="attr" />
                                </div>
                                <div className="right-heading">{headline.toUpperCase()}</div>
                            </div>
                            <div className="col-sm-9 col-right">
                                
                                {headlines[headline].map(h => <div>{h}</div>)}
                            </div>
                        </div>
                ))}

                {showEntireHTML ?
                    <>
                    {Object.keys(schema).length > 0 &&
                        <div className="row">
                            <div className="col-sm-3 col-left">
                                <div className="red-circle">
                                    <img src={warningIcon} alt="attr" />
                                </div>
                                <div className="right-heading">SCHEMA</div>
                            </div>
                            <div className="col-sm-9 col-right">
                                
                                {Object.keys(schema).map(s => <div><b><em>{s}</em></b>: {schema[s]}</div>)}
                            </div>
                        </div>
                    }

                    {Object.keys(metadata).length > 0 &&
                        <div className="row">
                            <div className="col-sm-3 col-left">
                                <div className="red-circle">
                                    <img src={warningIcon} alt="attr" />
                                </div>
                                <div className="right-heading">METADATA</div>
                            </div>
                            <div className="col-sm-9 col-right">
                                
                                {Object.keys(metadata).map(m => <div><b><em>{m}</em></b>: {metadata[m]}</div>)}
                            </div>
                        </div>
                    }

                    {Object.keys(opengraph).length > 0 &&
                        <div className="row">
                            <div className="col-sm-3 col-left">
                                <div className="red-circle">
                                    <img src={warningIcon} alt="attr" />
                                </div>
                                <div className="right-heading">OPENGRAPH</div>
                            </div>
                            <div className="col-sm-9 col-right">
                                
                                {Object.keys(opengraph).map(o => <div><b><em>{o}</em></b>: {opengraph[o]}</div>)}
                            </div>
                        </div>
                    }

                    {Object.keys(twitter).length > 0 &&
                        <div className="row">
                            <div className="col-sm-3 col-left">
                                <div className="red-circle">
                                    <img src={warningIcon} alt="attr" />
                                </div>
                                <div className="right-heading">TWITTER</div>
                            </div>
                            <div className="col-sm-9 col-right">
                                
                                {Object.keys(twitter).map(t => <div><b><em>{t}</em></b>: {twitter[t]}</div>)}
                            </div>
                        </div>
                    }

                    {anchor.length > 0 &&
                        <div className="row">
                            <div className="col-sm-3 col-left">
                                <div className="red-circle">
                                    <img src={warningIcon} alt="attr" />
                                </div>
                                <div className="right-heading">LINKS</div>
                            </div>
                            <div className="col-sm-9 col-right">
                                
                                {anchor.map(a => (
                                    <>
                                        <div><b><em>anchorExists</em></b>: {a.anchorExists}</div>
                                        <div><b><em>anchorText</em></b>: {a.anchorText}</div>
                                        <div><b><em>anchorType</em></b>: {a.anchorType}</div>
                                        <div><b><em>linkTo</em></b>: {a.linkTo}</div>
                                        <div><b><em>sourceUrl</em></b>: {a.sourceUrl}</div>
                                        <hr />
                                    </>
                                ))}
                            </div>
                        </div>
                    }
                    
                    <div className="see-all-task" onClick={() => this.setState({ showEntireHTML: false })}>SHOW LESS</div>
                    </>
                    :
                    htmlAttrs.title && [].concat.apply([], Object.values(headlines)).length > 0 && <div className="see-all-task" onClick={() => this.setState({ showEntireHTML: true })}>SHOW ENTIRE HTML STRUCTURE</div>
                }  
            </section>
        )
    }
}

IssuesList.propTypes = {
    htmlAttrs: PropTypes.object.isRequired,
};

export default IssuesList;
