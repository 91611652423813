import React, { Component } from "react";
import PropTypes from 'prop-types';
import axios from 'axios';
import '../../App.css'
import './taskDetail.css'
import '../Header/Header.css'
import '../Footer/Footer.css'
import picTwo from '../image/pic-2.jpg';
import like from '../image/like.svg';
import heart from '../image/heart.svg';
import downArrow from '../image/arrow-down.svg';
import arrow from '../image/Arrow.svg';
import microphoneOfVoice from '../image/microphone-of-voice.svg';
import share from '../image/share.svg';
import wallpaper from '../image/wallpaper.jpg';
import TaskDetailDesktop from "./TaskDetailDesktop";
import TaskDetailMobile from "./TaskDetailMobile";
const data = [
  {
    name: 'Page A', uv: 4000, pv: 2400, amt: 2400,
  },
  {
    name: 'Page B', uv: 3000, pv: 1398, amt: 2210,
  },
  {
    name: 'Page C', uv: 2000, pv: 9800, amt: 2290,
  },
  {
    name: 'Page D', uv: 2780, pv: 3908, amt: 2000,
  },
  {
    name: 'Page E', uv: 1890, pv: 4800, amt: 2181,
  },
  {
    name: 'Page F', uv: 2390, pv: 3800, amt: 2500,
  },
  {
    name: 'Page G', uv: 3490, pv: 4300, amt: 2100,
  },
];
class Details extends Component {
  constructor(props) {
    super(props);

    this.state = {
      projectId: '',
      projectDetails: {},
      taskId: '',
      errorData: {},
      taskList: [],
      pageCount: 0,
    }
  }

  componentDidMount() {
    const params = (new URL(document.location)).searchParams;
    this.getProjectDetails(params.get('projectId'));
    this.getErrorData(params.get('task'));
    this.getTaskDetails(params.get('projectId'), params.get('task'), 5);
  }

  getProjectDetails = (projectId) => {
    var mai_server_host = localStorage.getItem("server") !== null ?
      process.env.REACT_APP_MAI_SERVER_HOST.split('.')[0] + '.' + localStorage.getItem("server") :
      process.env.REACT_APP_MAI_SERVER_HOST

    axios
      .get(`${mai_server_host}/api/v1/projects/${projectId}`,
        { headers: { Authorization: "Bearer " + localStorage.getItem("token") } })
      .then(res => {
        this.setState({ projectDetails: res.data });
      })
      .catch(err => {
        if(err.response.status == 401){
          console.log("session expired")
          this.redirectHome("Session expired, please login again!")
        }
        else{
          console.log(err);
        }
      }); //token expired);
  }

  getErrorData = (task) => {
    var mai_seo_server_host = localStorage.getItem("server") !== null ?
      process.env.REACT_APP_MAI_SEO_SERVER_HOST.split('.')[0] + '.' + localStorage.getItem("server") :
      process.env.REACT_APP_MAI_SEO_SERVER_HOST

    axios
      .get(`${mai_seo_server_host}/api/v1/seo/error/${task}`)
      .then(res => {
        this.setState({ errorData: res.data });
      })
      .catch(err => console.log(err.message));
  }

  getTaskDetails = (projectId, taskId, pageCount) => {
    var mai_task_manager_host = localStorage.getItem("server") !== null ?
      process.env.REACT_APP_MAI_TASK_MANAGER_HOST.split('.')[0] + '.' + localStorage.getItem("server") :
      process.env.REACT_APP_MAI_TASK_MANAGER_HOST

    axios.get(`${mai_task_manager_host}/api/v1/tasks/pages?task_code=${taskId}&project_id=${projectId}&pageNumber=1&perPage=${pageCount}`,
      { headers: { Authorization: "Bearer " + localStorage.getItem("token") } })
      .then(res => {
        const taskList = res.data.data.result;
        const pageCount = res.data.data.pageCount;
        this.setState({ taskList, pageCount, projectId, taskId });
      })
      .catch(err => {
        if(err.response.status == 401){
          console.log("session expired")
          this.redirectHome("Session expired, please login again!")
        }
        else{
          console.log(err);
        }
      });
  }

  redirectHome= errorMessage =>{
    localStorage.removeItem("token")
    window.history.pushState(
      {
        message: errorMessage
      },
      "",
      "/home"
    );
    window.history.go()
  }
  render() {
    const { errorData, taskList, pageCount, projectId, taskId, projectDetails } = this.state;
    const { history } = this.props;
    const dropDownItems = [
      { name: 'Smarter.Codes' },
      { name: 'Another action' },
      { name: 'Something else here' },
      // { name: 'New action'},
    ]

    const comments = [
      { firstName: 'Leo', lastName: 'Spencer', commentText: 'Believe in yourself, take on your challenges, dig deep within yourself to conquer fears. Never let anyone bring you down. You got to keep going.', image: picTwo, date: '8 Nov', commentCount: '256', likeCount: '260' },
      { firstName: 'Leo', lastName: 'Spencer', commentText: 'Believe in yourself, take on your challenges, dig deep within yourself to conquer fears. Never let anyone bring you down. You got to keep going.', image: picTwo, date: '7 Nov', commentCount: '256', likeCount: '260' },
      { firstName: 'Leo', lastName: 'Spencer', commentText: 'Believe in yourself, take on your challenges, dig deep within yourself to conquer fears. Never let anyone bring you down. You got to keep going.', image: picTwo, date: '6 Nov', commentCount: '256', likeCount: '260' },
      { firstName: 'Leo', lastName: 'Spencer', commentText: 'Believe in yourself, take on your challenges, dig deep within yourself to conquer fears. Never let anyone bring you down. You got to keep going.', image: picTwo, date: '5 Nov', commentCount: '256', likeCount: '260' },
    ]

    // const taskList = [
    //   { path: 'godaddy.com', endPoint: 'robots.txt', graphData: data, visitCount: '786', TaskCount: '231', messageCount: '1' },
    //   { path: 'godaddy.com', endPoint: 'robots.txt', graphData: data, visitCount: '487', TaskCount: '17', messageCount: '3' },
    //   { path: 'godaddy.com', endPoint: 'robots.txt', graphData: data, visitCount: '124', TaskCount: '148', messageCount: '6' },
    //   { path: 'godaddy.com', endPoint: 'robots.txt', graphData: data, visitCount: '879', TaskCount: '17', messageCount: '2' },
    // ]
    return (
      <React.Fragment>
        <TaskDetailDesktop
          history={history}
          dropDownItems={dropDownItems}
          data={data}
          taskList={taskList}
          errorData={errorData}
          projectDetails={projectDetails}
          projectId={projectId}
          taskId={taskId}
          pageCount={pageCount}
          comments={comments}
          getTaskDetails={this.getTaskDetails}
        />
        <TaskDetailMobile
          history={history}
          dropDownItems={dropDownItems}
          data={data}
          taskList={taskList}
          errorData={errorData}
          projectDetails={projectDetails}
          projectId={projectId}
          taskId={taskId}
          pageCount={pageCount}
          comments={comments}
          getTaskDetails={this.getTaskDetails}
        />
      </React.Fragment>
    );
  }
}

Details.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Details;
