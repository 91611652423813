import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import menuIcon from "../image/menu-(2).svg";
import glassIcon from "../image/magnifying-glass.svg";
import checkIcon from "../image/iconfinder_check_alt_118699@2x.png";
import { Link } from 'react-router-dom'
import "../../App.css";
import "../Header/Header.css";
import "../Footer/Footer.css";
import "./pageDetails.css";
import '../Tasks/style.css';
import arrow from '../image/Arrow-black.svg';
class TaskList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSearchTaskInput: false,
      taskList: [],
      fullTaskList: [],
      isTaskListEmpty:false
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ taskList: nextProps.taskList });
  }

  togglePageSearch = () => {
    const { showSearchTaskInput } = this.state;
    this.setState({ showSearchTaskInput: !showSearchTaskInput });
  };

  searchPages = e => {
    const { taskList } = this.props;
    const results = _.filter(taskList, item => {
      return (
        item.title.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
      );
    });
    // this.setState({ fullTaskList: taskList, taskList: results });
    if(results.length==0)
    this.setState({ fullTaskList: taskList, taskList: results, isTaskListEmpty: true });
    else
    this.setState({ fullTaskList: taskList, taskList: results, isTaskListEmpty: false });

  };

  getTasks = () => {
    const { fullTaskList } = this.state;
    const { getTaskList, projectId, page, totalCount } = this.props;
    if (fullTaskList.length === totalCount) {
      this.setState({
        showSearchTaskInput: false,
        taskList: fullTaskList,
        fullTaskList: []
      });
    } else {
      getTaskList(projectId, page, totalCount);
      this.setState({ showSearchTaskInput: false });
    }
  };

  showLessTasks = () => {
    const {taskList}=this.state;
    this.setState({ taskList:taskList.slice(0, 5) });
  }

  render() {
    const { showSearchTaskInput, taskList ,isTaskListEmpty} = this.state;
    const { totalCount, projectId } = this.props;
    let pad = d => {
      if(d) return (d < 10) ? '0' + d.toString() : d.toString();
    }
    return (
      <div>
      <div className="desktop-view">
      <section className="affectedPage">
        <div className="affectedPage-head">
          <span>TASKS FOUND</span>
          <button className="plain-button" onClick={this.togglePageSearch}>
            <img src={glassIcon} alt="search" />
          </button>
        </div>
        {showSearchTaskInput ? (
          <section className="searchTasks">
            <input
              autoFocus
              type="text"
              placeholder="Search for tasks"
              className="write-comments"
              onChange={this.searchPages}
            />
          </section>
        ) : null}

        {taskList.map(t => (
          <Link to={`/taskdetail?projectId=${projectId}&task=${t.error_code}`}>
            <div className="task-container">
             
              <div className="task-name">
                <span>{t.title}</span>
              </div>

              <div className={`task-significance ${t.priority}`}>
                {t.priority}
              </div>

              <div className="pages-affected">
                <span>
                  <b>{t.pageCount} </b>PAGES
                </span>
              </div>
              
              <div className={`task-status-${t.status}`}>
                  <span>
                    <b>
                      {t.status === "Completed"
                        ? "Done"
                        : t.status}{" "}
                    </b>
                  </span>
                </div>

              <div className="traffic-affected">
                {/* <span><b>214K </b>TRAFFIC AFFECTED</span> */}
              </div>

              <div className="message-counter-box">
                <div className="message">
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="33.58" height="26.576" viewBox="0 0 33.58 26.576">
                                    <g id="Group_2121" data-name="Group 2121" transform="translate(-12434.65 1285.35)">
                                        <path id="Path_245" data-name="Path 245"
                                            d="M20.55,32.452l.03.04,3.15,4.35a.4.4,0,0,0,.54-.02l3.17-4.32.04-.05h11.8a1.161,1.161,0,0,0,1.16-1.16V12.222a1.161,1.161,0,0,0-1.16-1.16H8.72a1.161,1.161,0,0,0-1.16,1.16v19.07a1.161,1.161,0,0,0,1.16,1.16Z"
                                            transform="translate(12427.44 -1296.062)" fill="#fff"></path>
                                        <text id="_1" data-name="1" transform="translate(12455 -1271)" fill="#303030" font-size="12"
                                            font-family="OpenSans-Semibold, Open Sans" font-weight="600">
                                            <tspan x="0" y="0">1</tspan>
                                        </text>
                                        <path id="Path_246" data-name="Path 246"
                                            d="M22.688,25.1a4.8,4.8,0,1,1,1.537-1.449l.2,1.729L22.688,25.1"
                                            transform="translate(12427.44 -1296.062)" fill="#ff6969"></path>
                                        <path id="Path_247" data-name="Path 247" d="M16.908,20.213a3.1,3.1,0,0,1,3.063-2.788"
                                            transform="translate(12427.44 -1296.062)" fill="none" stroke="#303030"
                                            stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="0.7">
                                        </path>
                                        <path id="Path_248" data-name="Path 248"
                                            d="M22.688,25.1a4.8,4.8,0,1,1,1.537-1.449l.2,1.729L22.688,25.1"
                                            transform="translate(12427.44 -1296.062)" fill="none" stroke="#303030"
                                            stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="0.7">
                                        </path>
                                        <path id="Path_250" data-name="Path 250"
                                            d="M20.55,32.452l.03.04,3.15,4.35a.4.4,0,0,0,.54-.02l3.17-4.32.04-.05h11.8a1.161,1.161,0,0,0,1.16-1.16V12.222a1.161,1.161,0,0,0-1.16-1.16H8.72a1.161,1.161,0,0,0-1.16,1.16v19.07a1.161,1.161,0,0,0,1.16,1.16Z"
                                            transform="translate(12427.44 -1296.062)" fill="none" stroke="#303030"
                                            stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="0.7">
                                        </path>
                                    </g>
                                </svg> */}
                </div>
              </div>

              <div className="assigned-container">
                {/* <div className="assignedperson-one">
                            </div>
                            <div className="assignedperson-rest">
                            </div> */}
              </div>
              
              <div className="task-id">
                <span>#{pad(t.taskId)}</span>
              </div>
            </div>
          </Link>
        ))}

        {isTaskListEmpty?<div className="see-all-task">No Records Found</div>
          :
          totalCount !== taskList.length ? <div className="see-all-task" onClick={this.getTasks}>CLICK TO SEE ALL TASKS</div> : 
          taskList.length<6?null:<div className="see-all-task" onClick={this.showLessTasks}>Show Less</div>}

      </section>
      </div>
      <div className="mob-view">
      <section className="affectedPage">
        <div className="affectedPage-head">
          <span>TASKS FOUND</span>
          <button className="plain-button" onClick={this.togglePageSearch}>
            <img src={glassIcon} alt="search" />
          </button>
        </div>
        {showSearchTaskInput ? (
          <section className="searchTasks">
            <input
              autoFocus
              type="text"
              placeholder="Search for tasks"
              className="write-comments"
              onChange={this.searchPages}
            />
          </section>
        ) : null}

          {/*TaskList Mobile redesigned*/}
          {taskList.map(t => (

           <Link to={`/taskdetail?projectId=${projectId}&task=${t.error_code}`}>
           <div className="tasks-white-box">
           {/*{console.log(t)}*/}
           
           <div style={{ flex: "auto" }}>
             
               <div style={{ color: "black" }}>
                 <b
                   style={{
                     fontSize: "16px",
                     fontWeight: "600",
                     fontFamily: "IBM Plex Sans",
                   }}
                 >
                   {t.title}
                 </b>
                 {/*<img src={menu} alt="" style={{ width: "24px", float:"right", marginRight:"8px", marginTop:"8px"}} />*/}
               </div>
 
               <div className="sub-tags">
                 <span className="mob-pages-affected"><b>{t.pageCount} </b>PAGES</span>

                 <span
                  className={`task-status-${t.status}`}
                  style={{
                    paddingRight: "7px",
                    marginLeft: "10px",
                    paddingLeft: "7px",
                    borderRadius: "5pt",
                    fontSize: "15px",
                  }}
                >
                  <b>
                    {t.status === "Completed"
                      ? "Done"
                      : t.status}{" "}
                  </b>
                </span>
                 
 
                 <span
                   className={`task-significance ${t.priority}`}
                   style={{
                     paddingRight: "7px",
                     marginLeft: "10px",
                     paddingLeft: "7px",
                     borderRadius: "5pt",
                     fontSize: "15px",
                   }}
                 >
                    {t.priority}
                 </span>
               </div>
            
           </div>
         </div>
         </Link>
   
        ))}

        {isTaskListEmpty?<div className="see-all-task">No Records Found</div>
          :
          totalCount !== taskList.length ? <div className="see-all-task" onClick={this.getTasks}>CLICK TO SEE ALL TASKS</div> : 
          taskList.length<6?null:<div className="see-all-task" onClick={this.showLessTasks}>Show Less</div>}

      </section>
      </div>
      </div>
    );
  }
}

TaskList.propTypes = {
  projectId: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  totalCount: PropTypes.number.isRequired,
  taskList: PropTypes.array.isRequired,
  getTaskList: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default TaskList;
