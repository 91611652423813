import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Checkbox } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import Visualizations from "../Visualizations";
import TasksHeaderMobile from "../../Header/TasksHeaderMobile";
import TaskComp from "../../TaskComp";
import worldwide from "../../image/worldwide.svg";
import Arrow from "../../image/Arrow.svg";
import minus from "../../image/minus.svg";
import add from "../../image/add.svg";
import AppBox from "../../AppBox/AppBox";
import MainModal from "./MainModal";
import UrlModal from "./UrlModal";
import ApplyModal from "./ApplyModal";
import Loader from "../../Loader/Loader";
import LoginDialog from "../../LoginDialog/LoginDialog";
import BulkSelectPriority from "../BulkSelectComponents/Priority";
import BulkSelectStatus from "../BulkSelectComponents/Status";
import "./Mobile.css";

export default class TasksMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: localStorage.getItem("user"),
      loginOpen: false,
      searchUrl: "",
      selectedFilters: {},
      filtersCount: {},
      filters: props.filters,
      noData: true,
      snackbarOpen: false,
      snackbarMessage: undefined,
      taskIdArray: []
    };
  }

  componentDidMount() {
    let { message } = window.history.state || { message: "" };
    if (message) {
      this.setState({ snackbarMessage: message, snackbarOpen: true });
      window.history.replaceState(null, "");
    }
    setTimeout(() => {
      const {
        tasksCount,
        fetchedCount,
        analysedCount,
        discoveredCount
      } = this.props;
      if (
        !(
          discoveredCount > 1 ||
          tasksCount > 0 ||
          fetchedCount > 0 ||
          analysedCount > 0
        )
      ) {
        this.setState({ noData: false });
      }
    }, 30000);
  }

  goToTaskDetails = (projectId, task) => {
    const { user } = this.state;
    if (user === "Guest") {
      window.history.pushState(
        {
          from: { pathname: `/taskdetail?projectId=${projectId}&task=${task}` }
        },
        "",
        "/login"
      );
      window.history.go();
    } else {
      this.props.history.push(
        `/taskdetail?projectId=${projectId}&task=${task}`
      );
    }
  };

  onUrlFilterChange = e => {
    this.setState({ searchUrl: e.target.value });
  };

  setUrlFilter = () => {
    let filters = this.state.filters;
    filters.url = this.state.searchUrl;
    this.setSelectedFilter("url", filters.url);
    this.setState({ filters });
  };

  setFilter = (type, index) => {
    let filters = this.state.filters;
    filters[type].map(item => (item.selected = false));
    filters[type][index].selected = !filters[type][index].selected;
    this.setSelectedFilter(type, filters[type][index].name);
    this.setState({ filters: filters });
  };

  setSelectedFilter = (type, value) => {
    let selectedFilters = this.state.selectedFilters;
    delete selectedFilters[type];
    if (value !== "All") selectedFilters[type] = value;
    this.setState({ selectedFilters });
    this.props.setFiltersApplied(selectedFilters, this.state.filters);
  };

  handleSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };

  extractUser = (email) => {
    let und = email.split("@");
    let user = und[0];
    if (user.length > 12) {
      return user.substring(0, 11) + "..";
    }
    return user;
  }

  /*handleChange = (event, key) => {
    const { checked } = event.target;
    const { taskIdArray } = this.state;
    let temp = taskIdArray;

    if (checked) {
      if (!temp.includes(key)) {
        temp.push(key);
      }
    } else {
      if (temp.includes(key)) {
        let index = temp.indexOf(key);
        temp.splice(index, 1);
      }
    }
    this.setState({
      taskIdArray: temp
    });
  };

  handleAllChange = event => {
    const { checked } = event.target;
    const { tasks } = this.props;
    if (checked) {
      let selectedTaskArray = tasks && tasks.map(t => t.error_code);
      this.setState({
        taskIdArray: selectedTaskArray
      });
    } else {
      this.setState({
        taskIdArray: []
      });
    }
  };

  onApplyBulkChange = () => {
    this.setState({ taskIdArray: [] });
  };*/

  render() {
    const {
      history,
      projectId,
      projects,
      totalCount,
      uniqueCount,
      tasks,
      filters,
      isDomainError,
      errorMessage,
      fetchedCount,
      analysedCount,
      discoveredCount,
      tasksCount,
      crawledCount
    } = this.props;

    const conditionalRendering = !(
      discoveredCount > 1 ||
      tasksCount > 0 ||
      fetchedCount > 0 ||
      analysedCount > 0
    );

    const {
      noData,
      snackbarMessage,
      snackbarOpen,
      taskIdArray,
      user
    } = this.state;


    // const taskList1 = tasks.slice(0, 3).map((task, index) => {
    //   return (
    //     <div onClick={() => this.goToTaskDetails(projectId, task.error_code)}>
    //       <TaskComp
    //         taskName={task.title}
    //         taskSignificance={task.priority}
    //         pageAffected={`${task.pageCount} Pages`}
    //         trafficAffected={'### Traffic'}
    //         number={1}
    //       />
    //     </div>
    //   );
    // });

    // const taskList2 = tasks.slice(3, 6).map((task, index) => {
    //   return (
    //     <div onClick={() => this.goToTaskDetails(projectId, task.error_code)}>
    //       <TaskComp
    //         taskName={task.title}
    //         taskSignificance={task.priority}
    //         pageAffected={`${task.pageCount} Pages`}
    //         trafficAffected={'### Traffic'}
    //         number={1}
    //       />
    //     </div>
    //   );
    // });

    // const taskList3 = tasks.slice(6).map((task, index) => {
    //   return (
    //     <div onClick={() => this.goToTaskDetails(projectId, task.error_code)}>
    //       <TaskComp
    //         taskName={task.title}
    //         taskSignificance={task.priority}
    //         pageAffected={`${task.pageCount} Pages`}
    //         trafficAffected={'### Traffic'}
    //         number={1}
    //       />
    //     </div>
    //   );
    // });

    const taskList = tasks.map((task, index) => {
      return (
        <div class="task-list" key={task.taskId}>
          <TaskComp
            taskName={task.title}
            taskSignificance={task.priority}
            pageAffected={`${task.pageCount} Pages`}
            taskStatus={task.status}
            trafficAffected={"### Traffic"}
            //checked={taskIdArray.includes(task.error_code)}
            //onCheckBoxChange={e => this.handleChange(e, task.error_code)}
            error_code={task.error_code}
            projectId={projectId}
            user={user}
          />
        </div>
      );
    });

    const filterLists = (type) => {
			return this.state.filters[type].map((item, index) => {
        console.log(this.extractUser(item.name));
				if (item.selected) {
					return <div onClick={() => this.setFilter(type, index)} className="filters-all-tag"><b>{this.extractUser(item.name)}</b>{/*<br/><small>{item.count}</small>*/}</div>
				}
				return <div onClick={() => this.setFilter(type, index)} className="filters-seo-tag"><b>{this.extractUser(item.name)}</b>{/*<br/><small>{item.count}</small>*/}</div>
			});
		}


    const getChartData = () => {
      var chartData = {
        SEO: {
          High: 0,
          Low: 0,
          Medium: 0
        },
        PPC: {
          High: 0,
          Low: 0,
          Medium: 0
        },
        Social: {
          High: 0,
          Low: 0,
          Medium: 0
        },
        Crawl: {
          High: 0,
          Low: 0,
          Medium: 0
        }
      };

      tasks.forEach(task => {
        var { tag, priority, pageCount } = task;
        chartData[tag][priority] += pageCount;
      });

      return chartData;
    };

    const selectedProject = _.find(projects, { _id: projectId });
    const chartData = getChartData();

    const allSelected = Object.keys(filters)
      .splice(0, 4)
      .filter((cat) => {
        if (cat === "status") {
          return (
            filters[cat][0].selected === true ||
            filters[cat][1].selected === true
          );
        }
        return filters[cat][0].selected === true;
      });

    const handleClosePricing = () => {
      this.setState({ loginOpen: false });
    };


    return (
      <React.Fragment>
        <div className="mob-view">
          {snackbarMessage !== undefined && (
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={snackbarOpen}
              autoHideDuration={10000}
              onClose={this.handleSnackbarClose}
            >
              <SnackbarContent
                style={{ backgroundColor: "#47d147", fontWeight: "bold" }}
                message={snackbarMessage}
                action={
                  <React.Fragment>
                    <IconButton
                      size="small"
                      aria-label="close"
                      color="inherit"
                      onClick={this.handleSnackbarClose}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </React.Fragment>
                }
              />
            </Snackbar>
          )}

          {!snackbarOpen && discoveredCount > crawledCount && (
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              open={true}
              autoHideDuration={null}
            >
              <SnackbarContent
                style={{
                  backgroundColor: "#f26363",
                  fontFamily: "IBM Plex Sans",
                  fontWeight: "bold",
                  fontSize: 12
                }}
                message={"Crawl limit reached, please upgrade! "}
                action={
                  <React.Fragment>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        history.push(`/Accounts`);
                      }}
                      style={{ backgroundColor: "#f0e9e9", fontSize:"0.775rem" }}
                    >
                      UPGRADE
                    </Button>
                  </React.Fragment>
                }
              />
            </Snackbar>
          )}
          {/* <LoginDialog open={this.state.loginOpen} onClose={handleClosePricing} /> */}

          {conditionalRendering ? (
            noData ? (
              <>
                <TasksHeaderMobile
                  hideTitle={true}
                  hideFilters={true}
                  history={history}
                />

                <div
                  style={{
                    marginTop: "67%",
                    marginLeft: "37%"
                  }}
                >
                  <div class="boxes">
                    <div class="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div class="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div class="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div class="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <TasksHeaderMobile
                  hideTitle={true}
                  hideFilters={true}
                  history={history}
                />
                <div style={{ textAlign: "center" }}>
                  <img
                    src={require("./nodata.png")}
                    style={{
                      maxHeight: "50vh",
                      maxWidth: "50vw",
                      marginTop: "5vh"
                    }}
                  />
                  <p style={{ marginTop: 45 }}>
                    <span style={{ fontSize: 30, color: "#ff6969" }}>
                      No data to be shown
                    </span>
                    <br />
                    <div style={{ marginTop: 15 }}>
                      <small style={{ fontSize: 15 }}>
                        Seems like the website is too lean to crawl and generate
                        data for. Please try another website URL.
                      </small>
                    </div>
                  </p>
                </div>
              </>
            )
          ) : (
            <>
              <TasksHeaderMobile history={history} />
              <section className="mob-filters-container">
                <div className="mob-filters-inner" >{filterLists("tag")}</div>
              </section>

              <section className="mob-tasks-container">
                <div className="main-tasks-container">
                  {isDomainError ? (
                    <p className="domain-error">{errorMessage}</p>
                  ) : (
                    <>
                      {totalCount == undefined ? <Loader /> : null}
                      {totalCount !== 0 && selectedProject ? (
                        <section className="task-head">
                          {uniqueCount.toLocaleString()} Tasks for{" "}
                          {selectedProject.domain}
                        </section>
                      ) : noData && !totalCount && !analysedCount ? (
                        <p className="domain-error" style={{top:"50%", position:"absolute"}}>Tasks are loading...</p>
                      ) : totalCount !== undefined && discoveredCount > 1 ? (
                        <p className="domain-error" style={{top:"50%", position:"absolute"}}>No Tasks Available.</p>
                      ) : null}

                      {allSelected.length === 4 ? (
                        <section  style={{marginBottom:"90px"}}>
                          <Visualizations
                            filters={filters}
                            chartData={chartData}
                            textData={{
                              crawledCount,
                              analysedCount,
                              tasksCount,
                              fetchedCount,
                              discoveredCount
                            }}
                          />
                        </section>
                        ) : null}
                        
                        <section className="tasks-container">
                          {taskList}
                        </section>
                        {/*
                      <section className="tasks-container" style={{marginTop:"60px"}}>
                        {user !== "Guest" && (
                          <div className="Filter-Header">
                            <div className="checkbox-div">
                              <Checkbox
                                checked={tasks.length === taskIdArray.length}
                                onChange={e => this.handleAllChange(e)}
                                inputProps={{
                                  "aria-label": "primary checkbox"
                                }}
                              />
                              
                              <b style={{ padding: "0 10px" }}>{}Select All </b>
                            </div>
                            
                            <div className="bulk-actions">
                              <div className="Priority-Header">
                                <BulkSelectPriority
                                  taskIdList={taskIdArray}
                                  projectId={projectId}
                                  reset={this.onApplyBulkChange}
                                />
                              </div>
                              
                              <div className="Priority-Header">
                                <BulkSelectStatus
                                  taskIdList={taskIdArray}
                                  projectId={projectId}
                                  reset={this.onApplyBulkChange}
                                />
                              </div>
                            </div>
                          </div>
                        )}

                        {taskList}
                      </section>*/}
                    </>
                  )}

                  <section>
                    {/* <button className="add-button">
									<img src={add} style={{ width: "25px" }} alt='' />
								</button> */}
                  </section>
                </div>
              </section>
            </>
          )}
        </div>
        {/*............. Modal box for Filters............. */}
        <MainModal filterLists={filterLists} />
        {/*............. Modal box for page.............. */}
        {/* <UrlModal
					onUrlFilterChange={this.onUrlFilterChange}
					setUrlFilter={this.setUrlFilter}
				/> */}
        {/*............. Modal box for All Filters............. */}
        <ApplyModal />
      </React.Fragment>
    );
  }
}

TasksMobile.propTypes = {
  app: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired,
  projects: PropTypes.array.isRequired,
  totalCount: PropTypes.number.isRequired,
  uniqueCount: PropTypes.number.isRequired,
  tasks: PropTypes.array.isRequired,
  setFiltersApplied: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  isDomainError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired
};
