import React, { useEffect } from "react";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";

import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
  button: {
    display: "block",
    marginTop: theme.spacing(3),
    marginLeft: "3px",
    marginRight: "3px"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
}));

export default function ControlledOpenSelect(props) {
  const classes = useStyles();
  const [status, setStatus] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const handleChange = event => {
    setStatus(event.target.value);
    const { onStatusChange } = props;

    onStatusChange && onStatusChange(event.target.value)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const checkEnalbleSelect = () => {
    const { taskIdList } = props;
    if (taskIdList.length === 0 && !!!status) {
      //   setEnableSelect(true);
      return true;
    } else {
      //   setEnableSelect(true);
      return false;
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <div>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-controlled-open-select-label">
            Status
          </InputLabel>
          <Select
            labelId="demo-controlled-open-select-label"
            id="demo-controlled-open-select"
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={status}
            onChange={handleChange}
            disabled={checkEnalbleSelect()}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={"Open"}>Open</MenuItem>
            <MenuItem value={"Completed"}>Completed</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
}
