import React from "react";
import './Footer.css';
import LogoIcon from "../LogoIcon";

function Footer(props) {

    return (
        <footer>
            <div class="pre-footer-container">
                <div>
                    <div className="pre-foot-mai-logo">
                        <LogoIcon type = "txt" />
                    </div>
                </div>
                <div>
                    <a href="https://sitehike.smarter.codes/contact" className="pre-foot-item">Contact</a>
                    <a href="https://sitehike.smarter.codes/help" className="pre-foot-item">Help</a>
                </div>
            </div>
            <div className="desktop-view">
                <div className="pre-foot-copy">
                    {/* &copy; 2019 Hikewise. All rights reserved. | <a href="#">Terms & Privacy</a> */}
                    &copy; 2020 <a href="https://epochaltechnologies.com/" rel="nofollow noopener noreferrer" target="_blank">Epochal Technologies</a>. All rights reserved. | <a href="https://sitehike.smarter.codes/terms" rel="nofollow">T&amp;C</a> &amp; <a href="https://sitehike.smarter.codes/privacy-policy" rel="nofollow">Privacy</a>
                </div>
            </div>
            <div className="mob-view">
                <div className="mai-pre-foot-copy" style={{marginTop:"50px"}}>
                    {/* &copy; 2019 Hikewise. All rights reserved. | <a href="#">Terms & Privacy</a> */}
                    &copy; 2020 <a href="https://epochaltechnologies.com/" rel="nofollow noopener noreferrer" target="_blank">Epochal Technologies</a>.<br/> All rights reserved. | <a href="https://sitehike.smarter.codes/terms" rel="nofollow">T&amp;C</a> &amp; <a href="https://sitehike.smarter.codes/privacy-policy" rel="nofollow">Privacy</a>
                </div>
            </div>

            {/* <footer className="footer-container row">
                
                <div className="footer-seo-items-container">
                    <div className="footer-items-heading">
                        SEO Tools
            </div>
                    <div className="footer-items">
                        <div className="footer-item">
                            <a href="#">Keyword
                        Research</a>
                        </div>
                        <div className="footer-item">
                            <a href="#">SEO
                        Audit & Crawl</a>
                        </div>
                        <div className="footer-item">
                            <a href="#">Backlink
                        Research</a>
                        </div>
                        <div className="footer-item">
                            <a href="#">Rank
                        Tracking</a>
                        </div>
                        <div className="footer-item">
                            <a href="#">SEO
                        Toolbar</a>
                        </div>
                    </div>
                </div>
                <div className="footer-local-items-container">
                    <div className="footer-items-heading">
                        Local Marketing
            </div>
                    <div className="footer-items">
                        <div className="footer-item">
                            <a href="#">Business
                        Listing Audit</a>
                        </div>
                        <div className="footer-item">
                            <a href="#">Citation
                        Cleanup</a>
                        </div>
                        <div className="footer-item">
                            <a href="#">Local
                        Ranking Factors</a>
                        </div>
                        <div className="footer-item">
                            <a href="#">Local
                        for Enterprise</a>
                        </div>
                    </div>
                </div>
                <div className="footer-marketing-items-container">
                    <div className="footer-items-heading">
                        Marketing Resources
            </div>
                    <div className="footer-items">
                        <div className="footer-item">
                            <a href="#">Hikewise
                        Blog</a>
                        </div>
                        <div className="footer-item">
                            <a href="#">Beginners
                        guide to SEO</a>
                        </div>
                        <div className="footer-item">
                            <a href="#">Community
                        Q&A</a>
                        </div>
                        <div className="footer-item">
                            <a href="#">Workshops
                        & Training</a>
                        </div>
                        <div className="footer-item">
                            <a href="#">Recommended
                        SEO Companies</a>
                        </div>
                    </div>
                </div>
                <div className="footer-separation">
                </div>
                <div className="footer-subscribe-items-container">
                    <div className="subscribe-blog-container">
                        Get the Hikewise Top 10
            </div>
                    <div className="subscribe-body-container">
                        The top 10 most valuable resources we can find for SEOs. Every 2 weeks.
                <button className="subscribe-btn">
                            subscribe today
                </button>
                    </div>
                </div>
            </footer> */}
        </footer>


    )
}
export default Footer;