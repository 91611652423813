import React, { Component } from 'react';
import PropTypes from 'prop-types';
import close from '../../image/close.svg';
import arrowBlack from '../../image/Arrow-black.svg';

export default class MainModal extends Component {
    render() {
        const { filterLists } = this.props;
        return (
            <div className="container">
                {/* The Modal */}
                <div className="modal fade modal-des" id="mainModal">
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content" style={{width:"100vw"}}>
                            {/* Filters */}
                            <div className="filters">
                                {/* Filters/header */}
                                <header className="mob-all-tasks">
                                    <div className="heading-container" style={{ padding: 10, paddingLeft: 0 }}>
                                        {/* <svg
                                            //xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24"
                                            height="24" viewBox="0 0 24 24">
                                            <defs>
                                                <clipPath id="clip-path">
                                                    <rect width="24" height="24" fill="none" />
                                                </clipPath>
                                            </defs>
                                            <g id="icons_back" data-name="icons/back" clipPath="url(#clip-path)">
                                                <rect id="bg" width="24" height="24" fill="none" />
                                                <g id="ico" transform="translate(-178 -48)">
                                                    <path id="union"
                                                        d="M1597.541-4.4l-5.879-5.88a.749.749,0,0,1-.362-.642.748.748,0,0,1,.1-.378.744.744,0,0,1,.128-.171.751.751,0,0,1,.078-.068l5.932-5.932a.751.751,0,0,1,1.061,0,.751.751,0,0,1,0,1.061l-4.739,4.738h12.688a.75.75,0,0,1,.75.751.75.75,0,0,1-.75.75h-12.66l4.711,4.711a.751.751,0,0,1,0,1.061.749.749,0,0,1-.531.219A.749.749,0,0,1,1597.541-4.4Z"
                                                        transform="translate(-1408.3 70.92)" fill="#241332" />
                                                </g>
                                            </g>
                                        </svg> */}
                                        <div className="mob-page-heading">
                                            Filters
                                        </div>
                                    </div>
                                    <div className="close-button">
                                        <div className="hamburger-container">
                                            <div className="modal-button">
                                                <button type="button" className="btn" data-dismiss="modal">
                                                    <img src={close} width="15px" alt='' />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </header>
                                <main className="filters-main-container">
                                    {/* Filters/just show */}
                                    <section className="filters-just-show-container">
                                        <div className="main-container">
                                            <div className="filter-sub-header"><b>Just show</b></div>
                                            <div className="filters-priority-button-container">
                                                {filterLists('tag')}
                                            </div>
                                        </div>
                                    </section>
                                    {/* Filters/Priority */}
                                    <section className="filters-priority-container">
                                        <div className="main-container">
                                            <div className="filter-sub-header"><b>Priority</b></div>
                                            <div className="filters-priority-button-container">
                                                {filterLists('priority')}
                                            </div>
                                        </div>
                                    </section>
                                    {/* Filters/Status */}
                                    <section className="filters-priority-container">
                                        <div className="main-container">
                                            <div className="filter-sub-header"><b>Status</b></div>
                                            <div className="filters-priority-button-container">
                                                {filterLists('status')}
                                            </div>
                                        </div>
                                    </section>
                                    <section className="filters-assigned-to-container">
                                        <div className="main-container">
                                            <div className="filter-sub-header"><b>Assigned to</b></div>
                                            <div className="filters-priority-button-container">
                                                {filterLists('assigned')}
                                            </div>
                                        </div>
                                    </section>
                                    
                                </main>
                                <div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

MainModal.propTypes = {
    filterLists: PropTypes.func.isRequired,
};
