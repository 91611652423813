import axios from "axios";

let mai_task_manager_host =
  localStorage.getItem("server") !== null
    ? process.env.REACT_APP_MAI_TASK_MANAGER_HOST.split(".")[0] +
      "." +
      localStorage.getItem("server")
    : process.env.REACT_APP_MAI_TASK_MANAGER_HOST;

class Request {
  // eslint-disable-next-line no-useless-constructor
  constructor() {}

  updateMultipleTasks(data, projectId) {
      console.log(data)
    return new Promise((resolve) => {
      axios
        .put(
          `${mai_task_manager_host}/api/v1/tasks/multiple?project_id=${projectId}`,
          { ...data },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          // console.log(res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err.message);
          resolve(err.message);
        });
    });
  }
}

export default new Request();
