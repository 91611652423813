import React, { Component } from "react";

import iconfinderCheckImg from '../image/iconfinder_check_alt_118699@2x.png';
import menuIcon from '../image/menu-(2).svg';
import TaskSectionItem from "./TaskSectionItem";

class TaskListSection extends Component {

    render() {
        return (
            <div className="dash-rightCodes">
                <div className="rightCodes-head">
                    <span>1,025 Tasks for godaddy.com</span>
                    <a href="tasks.html" className="dash-view">View all</a>
                </div>
                <div className="tasks-wrapper">
                    <TaskSectionItem title="Multiple H1 tags found" severity="HIGH" />

                    <TaskSectionItem title="Multiple H1 tags found" severity="MEDIUM" />

                    <TaskSectionItem title="Multiple H1 tags found" severity="MEDIUM" />
                </div>
            </div>
        );
    }
}

export default TaskListSection;