import React from "react";
import "./Header.css";
import _ from "lodash";
import { userService } from "../../Services";
import Avatar from "react-avatar";
// import LogoIcon from "../LogoIcon";

import brandLogo from "../../img/brand.png";
import '../SideDrawer/SideDrawer.css';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';


import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';

//import '../Tasks/style.css'
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: props.projects,
      setHeaderSelection: props.setHeaderSelection,
      projectId: props.projectId,
      user: localStorage.getItem("user"),
      anchorEl: null,
      anchorDomainEl: null
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      projectId: props.projectId,
      projects: props.projects,
      setHeaderSelection: props.setHeaderSelection
    });
    if (props.projectId && props.projects) {
      const selectedProject = props.projects.find(project => {
        return project._id === props.projectId;
      });
      if (selectedProject)
        this.setState({ selectedDomain: selectedProject.domain });
    }
  }

  selectDropDown = index => {
    this.setState({ selectedDomain: this.state.projects[index].domain });
    if (this.state.projects[index].server) {
      localStorage.setItem('server', this.state.projects[index].server)
    } else {
      localStorage.removeItem('server')
    }
    window.history.pushState(
      "Project",
      "Tasks",
      "/tasks?projectId=" + this.state.projects[index]._id
    );
    this.state.setHeaderSelection(this.state.projects[index]._id);
    window.history.go()
  };

  handleMenu = () => { this.setState({ anchorEl: null }) }
  handleDomainMenu = () => { this.setState({ anchorDomainEl: null }) }

  render() {
    const ITEM_HEIGHT = 48;
    const projects = this.state.projects;
    const selectedDomain = this.state.selectedDomain;
    let domainList = [];
    if (projects) {
      domainList = projects.map(project => {
        return (
          project.domain
        );
      });
    }
    return (
      <div>
        <div className="desktop-view">
          <header className="des-all-tasks">
            <div className="row bar" style={{ margin: 0 }}>
              <div className="row">
                <a href="/home">
                  <div className="mai-logo">
                  <img className="mob-logo-sidebar" src={brandLogo} alt="logo" />
                  </div>
                </a>

                <div className="smart-codes-dropdown">
                  {domainList.length > 0 ? (
                    <div>
                      <ButtonGroup variant="contained" aria-label="split button">
                        <Button
                          aria-controls="long-menu"
                          style={{ textTransform: 'none' }}
                          variant="outlined"
                          disableElevation
                          disabled>
                          {window.location.pathname === '/' || window.location.pathname === '/home'
                            ? 'Select a project'
                            : selectedDomain}
                        </Button>
                        <Button
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          variant="outlined"
                          size="small"
                          disableElevation
                          onClick={event => {
                            this.setState({ anchorDomainEl: event.currentTarget })
                          }}>
                          <ArrowDropDownIcon />
                        </Button>
                      </ButtonGroup>
                      <Menu
                        id="long-menu"
                        anchorEl={this.state.anchorDomainEl}
                        keepMounted
                        open={Boolean(this.state.anchorDomainEl)}
                        onClose={this.handleDomainMenu}
                        PaperProps={{
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: 200,
                          },
                        }}
                      >
                        {projects.map((project, index) => (
                          <MenuItem key={index} selected={project.domain === selectedDomain}
                            onClick={() => {
                              this.selectDropDown(index)
                              this.handleDomainMenu()
                            }}>
                            {/* <ListItemIcon>
                            </ListItemIcon> */}
                            <FolderOpenIcon fontSize="small" /> &nbsp;
                            <Typography variant="inherit" noWrap> {project.domain}</Typography>
                          </MenuItem>
                        ))}
                      </Menu>
                    </div>
                  ) : (
                      ""
                    )}
                  {/*{domainList.length > 0 ? (
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle top-dropdown"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {selectedDomain}
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <ul>{domainList}</ul>
                    </div>
                  </div>
                  ) : (
                    ""
                  )}*/}
                </div>
              </div>
              {this.props.hideUserMenu || <div className="row">
                <div className="login-btn">
                  {localStorage.getItem("user") === "Guest" && (
                    <button
                      onClick={() => {
                        window.history.pushState(
                          {
                            from: { pathname: window.location.pathname }
                          },
                          "",
                          "/login"
                        );
                        window.history.go();
                      }}
                    >
                      Login
                    </button>
                  )}
                  {localStorage.getItem("user") != "Guest" && (
                    <div>
                      <Avatar name={localStorage.getItem("user")} round={true} size={40}
                        onClick={event => {
                          this.setState({ anchorEl: event.currentTarget })
                        }}
                        style={{ cursor: "pointer" }}
                      />
                      <Menu
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: 'bottom' }}
                        id="user-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleMenu}
                      >
                        <MenuItem button={false} onClick={this.handleMenu}
                          style={{ minWidth: 200 }}>
                          <p>
                            <b>
                              {localStorage.getItem("user").split('@')[0]}
                            </b>
                            <br />
                            @{localStorage.getItem("user").split('@')[1]}
                          </p>
                        </MenuItem>
                        <MenuItem selected onClick={() => {
                          userService.logout();
                          window.location.replace("/");
                        }}>
                          Logout
                        </MenuItem>
                        <MenuItem selected onClick={() => {
                          window.history.pushState("","",`/Accounts`);
                          window.history.go();
                        }}>
                          Accounts
                        </MenuItem>
                      </Menu>
                    </div>
                  )}
                </div>
                <span>&nbsp;</span>
                <div className="register-btn">
                  {localStorage.getItem("user") === "Guest" ?
                    <button
                      onClick={() => {
                        window.history.pushState(
                          {
                            from: { pathname: window.location.pathname }
                          },
                          "",
                          "/signup"
                        );
                        window.history.go();
                      }}
                    >
                      Sign up
                  </button> : ''
                  }
                </div>
              </div>}
            </div>
          </header>
        </div>
        <div className="mob-view">
          {/*<nav className="side-drawer">
              <div class="moblogo"><a href="/"><img className="mob-logo-sidebar" src={brandLogo} alt="logo" /></a></div>
              
              <ul>
                  <li><a href="/">Dashboard</a></li>
                  <li><a href="/">Tasks</a></li>
                  <li><a href="/">Accounts</a></li>
              </ul>
                </nav>  */}
        </div>
      </div>
    );
  }
}
export default Header;
