import React, { Component } from 'react';
import PropTypes from 'prop-types';
import close from '../../image/close.svg';
import magnifyingGlass from '../../image/magnifying-glass.svg';

export default class UrlModal extends Component {
    render() {
        return (
            <div className="container">
                {/* The Modal */}
                <div className="modal fade modal-des" id="urlModal">
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content">
                            {/* Filters */}
                            <div className="filters">
                                {/* Filters/header */}
                                <header className="mob-all-tasks">
                                    <div className="heading-container" style={{ padding: '10px' }}>
                                        <svg
                                            //xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24"
                                            height="24" viewBox="0 0 24 24">
                                            <defs>
                                                <clipPath id="clip-path">
                                                    <rect width="24" height="24" fill="none" />
                                                </clipPath>
                                            </defs>
                                            <g id="icons_back" data-name="icons/back" clipPath="url(#clip-path)">
                                                <rect id="bg" width="24" height="24" fill="none" />
                                                <g id="ico" transform="translate(-178 -48)">
                                                    <path id="union"
                                                        d="M1597.541-4.4l-5.879-5.88a.749.749,0,0,1-.362-.642.748.748,0,0,1,.1-.378.744.744,0,0,1,.128-.171.751.751,0,0,1,.078-.068l5.932-5.932a.751.751,0,0,1,1.061,0,.751.751,0,0,1,0,1.061l-4.739,4.738h12.688a.75.75,0,0,1,.75.751.75.75,0,0,1-.75.75h-12.66l4.711,4.711a.751.751,0,0,1,0,1.061.749.749,0,0,1-.531.219A.749.749,0,0,1,1597.541-4.4Z"
                                                        transform="translate(-1408.3 70.92)" fill="#241332" />
                                                </g>
                                            </g>
                                        </svg>
                                        <div className="mob-page-heading">
                                            Pages
           									</div>
                                    </div>
                                    <div className="close-button">
                                        <div className="hamburger-container">
                                            <div className="modal-button">
                                                <button type="button" className="btn" data-dismiss="modal">
                                                    <img src={close} width="15px" alt='' />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </header>
                                <main className="page-main-container">
                                    {/* Page/just show */}
                                    <section className="page-search-container">
                                        <div className="page-inner-container">
                                            <form>
                                                <div className="search-wrpper">
                                                    <div className="search-icon">
                                                        <img src={magnifyingGlass} width="20px" alt='' />
                                                    </div>
                                                    <input className="input-custom" placeholder="Search Pages" onChange={this.props.onUrlFilterChange} />
                                                </div>
                                            </form>
                                        </div>
                                    </section>
                                    {/* Page/main */}
                                    <section className="page-container">
                                        <div className="page-inner-container">
                                            <div className="page-white-box" style={{ marginBottom: '24px' }}>
                                                <form>
                                                    <div className="page-checkbox">
                                                        <input className="page-checkboxInput" type="checkbox" defaultValue />
                                                        <a href="#">godaddy.com/<b>login</b></a>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="page-white-box" style={{ marginBottom: '24px' }}>
                                                <form>
                                                    <div className="page-checkbox">
                                                        <input className="page-checkboxInput" type="checkbox" defaultValue />
                                                        <a href="#">godaddy.com/<b>singup</b></a>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="page-white-box" style={{ marginBottom: '24px' }}>
                                                <form>
                                                    <div className="page-checkbox">
                                                        <input className="page-checkboxInput" type="checkbox" defaultValue />
                                                        <a href="#">godaddy.com/<b>hompage</b></a>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="page-white-box">
                                                <form>
                                                    <div className="page-checkbox">
                                                        <input className="page-checkboxInput" type="checkbox" defaultValue />
                                                        <a href="#">godaddy.com/<b>homepage</b></a>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </section>
                                    {/* Page/buttons */}
                                    <button className="page-button-container" data-toggle="modal" data-target="#allFilters">
                                        <a href="#">Apply</a>
                                    </button>
                                </main>
                                <div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

UrlModal.propTypes = {
    onUrlFilterChange: PropTypes.func.isRequired,
    setUrlFilter: PropTypes.func.isRequired,
};
