import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import axios from "axios";

import CircularProgress from '@material-ui/core/CircularProgress';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import "./main.css";
import "./Home.css";
import "./HomeIframe.css";
import worldwideImage from "../image/worldwide.svg";


import { userService } from "../../Services/UserService"
var config = require("../../Config/config");

class HomeIFrame extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: localStorage.getItem("user"),
      pricingOpen: false,
      domain: "",
      redirect: "",
      loading: false,
      buttonActive: true,
      snackbarOpen: false,
      snackbarMessage: undefined,
      projects: [],
      projectId: "",
      errorMsg: ""
    };
  }

  componentDidMount() {
    const auth = config.middleware.url;
    const url = auth + "/tokencheck";
    axios
      .get(url, { headers: { Authorization: "Bearer " + localStorage.getItem("token") } })
      .then(async (res) => {
        if (!res.data.success) { return userService.guest() }
        else {
          let projects = await axios.get(
            `${process.env.REACT_APP_MAI_SERVER_HOST}/api/v1/projects`,
            { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
          );
          if (projects.data.length) {
            this.setState({
              projects: projects.data,
              projectId: projects.data.length > 0 ? projects.data[projects.data.length - 1]._id : ""
            });
          }
        }
      })
      .catch(err => { console.log(err.message); });

    let { message } = window.history.state || { message: "" }
    if (message) {
      this.setState({ snackbarMessage: message, snackbarOpen: true })
      window.history.replaceState(null, '')
    }
  }

  handleSnackbarClose = () => {
    this.setState({ snackbarOpen: false })
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    // const urlRegex = /^[\w.\-:/]+(?:\.[\w\.-]+)+$/
    // const urlRegex = /^[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
    // const urlRegex = /^(http:\/\/www\.|HTTP:\/\/WWW\.|https:\/\/www\.|HTTPS:\/\/WWW\.|http:\/\/|HTTP:\/\/|https:\/\/|HTTPS:\/\/)?[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
    // if (value.match(urlRegex)) {
    //   this.setState({ buttonActive: true })
    // } else {
    //   this.setState({ buttonActive: false })
    // }
  };

  handleEnterKeyPress = e => {
    if (e.key && e.key === "Enter") this.handleSubmit();
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.state.domain) {
      this.setState({ loading: true })
      axios
        .post(
          `${process.env.REACT_APP_MAI_SERVER_HOST}/api/v1/projects`,
          {
            domain: this.state.domain,
            depth: 2
          },
          {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") }
          }
        )
        .then(project => {
          let { success, message } = project.data
          if (success === false) {
            this.setState({
              loading: false,
              errorMsg: this.state.domain + " : " + message,
            })
            localStorage.removeItem('server')
          } else {
            let projectId = project.data._id;
            this.setState({ redirect: "tasks", projectId: projectId, errorMsg: "" });
            if (project.data.server) {
              localStorage.setItem('server', project.data.server)
            } else {
              localStorage.removeItem('server')
            }

          }
        })
        .catch(err => {
          let message = ""
          let success = undefined
          try {
            success = err.response.data.success
            message = this.state.domain + " : " + err.response.data.message
          } catch {
            message = "Error connecting to application server"
          }
          if (success === false) {
            this.setState({ errorMsg: message })
          }
          console.log(err.message);
          const a = err.message.search("401");
          this.setState({ loading: false })
          if (a > 0) {
            userService.guest()
            this.handleSubmit()
            // alert("Session expired!! Click OK to reload!!");
            // localStorage.removeItem("token");
            // window.location.replace("/");
          } //token expired
        });
    }
  };

  renderRedirect = () => {
    let to = `/tasks?projectId=${this.state.projectId}`;
    if (this.state.redirect === "tasks") {
      //document.getElementsByClassName("home-wrap").innerHTML=""
      window.top.location.href = to; 
      // localStorage.getItem("user") != "Guest" && this.handleNotification(to);
      //this.props.history.push(to)
    }
  };


  render() {

    const { domain } = this.state;
    return (
      <div>

          <div className="home-wrap">
          {this.renderRedirect()}

            <div className="home-main-back">
              {/* ..........Home/input............... */}
              <form onSubmit={this.handleSubmit}>
                <section className="home-input-iframe">
                  <div className="home-competitors-container-iframe">
                    <div className="home-left-icon">
                      <img src={worldwideImage} alt="website" />
                    </div>
                    <div className="home-right-icon">
                      <div className="web-address-label">
                        enter website address
                    </div>
                      <div className="web-address-input-container">
                        <input
                          required={true}
                          id="domain"
                          name="domain"
                          value={domain}
                          autoFocus
                          className="web-address-input"
                          type="text"
                          // placeholder="your competitor domain.com"
                          style={{
                            border: "none",
                            width: "100%",
                            fontSize: "15px",
                            left: "1%",
                            marginLeft: "1%"
                          }}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </section>
                {/* ..........Home/button.............. */}
                <section className="home-button-iframe">
                  <button
                    type="submit"
                    className="home-btn-compare-iframe"
                    disabled={this.state.loading || !this.state.buttonActive}
                  >
                    {!this.state.loading ? 'GET STARTED' : 'Processing...'}
                    <div className="right-icon-container">
                      {
                        this.state.loading ?
                          <CircularProgress className="spinner" size={18} /> :
                          <KeyboardArrowRightIcon />
                      }
                    </div>
                  </button>
                </section>
              </form>
            </div>
          </div>
        </div>
    );
  }
}

export default withRouter(HomeIFrame);
