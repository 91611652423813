import React, { Component } from "react";
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import '../../App.css'

import DesktopImg from './../image/desktop.png';
import intersectionImg from '../image/Intersection-1@2x.png';
import iconfinderCheckImg from '../image/iconfinder_check_alt_118699@2x.png';
import iconfinderAnalyticsImg from '../image/iconfinder_f-analytics2_128_308020.png';
import iconfinderAdwordsImg from '../image/iconfinder_9_Adwords_4518967@2x.png';
import menuIcon from '../image/menu-(2).svg';
import settingsIcon from '../image/settings.svg';
import arrowWhite from '../image/arrow-white.svg';
import circleImg from '../image/circle.svg';
import backArrow from './../image/back-arrow.svg';
import worldWide from '../image/worldwide.svg';
import arrow from '../image/Arrow.svg';
import minus from '../image/minus.svg';

import '../TaskDetails/taskDetail.css';
import '../Tasks/style.css';
import './Dashboard.css';
import ConnecedAppsDesktop from "./ConnectedAppsDesktop";
import TaskListSection from "./TaskListSection";


class TaskSectionItem extends Component {

    render() {
        return (
            <div className="task-container">
                <div className="dash-task-item">
                    <img src={iconfinderCheckImg} width="23px" />
                </div>

                <div className="dash-task-name">
                    <a href="taskDetail.html">{this.props.title}</a>
                </div>

                <div className={this.props.severity.toUpperCase() === "HIGH" ? "dash-task-significance" : "dash-task-significance green"}>
                    {this.props.severity}
                </div>

                <div className="dash-assigned-container">
                    <div className="assignedperson-one">
                    </div>

                    <div className="assignedperson-rest">
                    </div>
                </div>
                <div className="task-more"><img src={menuIcon} width="24px" /></div>
            </div>
        );
    }
}

export default TaskSectionItem;