import React, { Component } from "react";

import logo from "../img/brand.png";
//import { Icon } from "semantic-ui-react";

//var config = require("../../Config/config");

class LogoIcon extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height : props.height,
      width  : props.width,
      alt    : props.alt,
      title  : props.title,
      BrandName : "SiteHike",
      type   : props.type || "img"
    };
  }

  render() {

    return (
      <span>
        {
  (this.state.type === "img")?<img src={logo} height={this.state.height} width={this.state.width} alt={(this.state.alt === "BrandName")?this.state.BrandName:this.state.alt} title={(this.state.title === "BrandName")?this.state.BrandName:this.state.title} />:<span style={{"font-size":this.state.height}}>{this.state.BrandName}</span>
       }          
      </span>

    );
  }
}

export default LogoIcon;

