import React, { Component } from "react";
import PropTypes from "prop-types";
// import Comments from "./Comments";
import TaskDetailList from "./TaskDetailList";
import AssignContainer from "./AssignContainer";

import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

// import telegram from "../image/telegram.svg";
// import close from "../image/close.svg";
// import correctSignal from "../image/correct-signal.svg";
import Header from "../Header/Header";
import axios from "axios";
import Avatar from "react-avatar";
import Chip from '@material-ui/core/Chip';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
// import AdjustIcon from '@material-ui/icons/Adjust';
// import BlockIcon from '@material-ui/icons/Block';
import { green, red } from '@material-ui/core/colors';
/*
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from "recharts";
*/
import Footer from "../Footer/Footer";

export default class TaskDetailDesktop extends Component {
  constructor(props) {
    super(props);
    let projectId = new URL(document.location).searchParams.get("projectId");
    let taskId = new URL(document.location).searchParams.get("task");
    this.state = {
      projectId: projectId ? projectId : "",
      taskId: taskId ? taskId : "",
      status: "",
      assignees: [],
      snackbarOpen: false,
      snackbarMessage: undefined
    };
  }

  componentDidMount() {
    this.handleTaskStatus(true);

    let { message } = window.history.state || { message: "" }
    if (message) {
      this.setState({ snackbarMessage: message, snackbarOpen: true })
      window.history.replaceState(null, '')
    }
  }

  handleSnackbarClose = () => {
    this.setState({ snackbarOpen: false })
  }

  getTaskDetailsWrapper = () => {
    const { projectId, taskId, pageCount, getTaskDetails } = this.props;
    getTaskDetails(projectId, taskId, pageCount);
  };

  handleTaskStatus = (retrieve = false, update = false, newStatus = "") => {
    const { projectId, taskId } = this.state;
    var mai_task_manager_host = localStorage.getItem("server") !== null ?
      process.env.REACT_APP_MAI_TASK_MANAGER_HOST.split('.')[0] + '.' + localStorage.getItem("server") :
      process.env.REACT_APP_MAI_TASK_MANAGER_HOST

    retrieve &&
      axios
        .get(
          `${mai_task_manager_host}/api/v1/tasks?project_id=${projectId}&error_code=${taskId}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            }
          }
        )
        .then(tasks => {
          this.setState({ status: tasks.data.data.result[0].status });
          // console.log(tasks.data.data.result);
        })
        .catch(err => {
          console.log(err.message);
        });
    update &&
      axios
        .put(
          `${mai_task_manager_host}/api/v1/tasks?project_id=${projectId}&error_code=${taskId}`,
          { status: newStatus },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            }
          }
        )
        .then(res => {
          // console.log(res.data);
        })
        .catch(err => {
          console.log(err.message);
        });
  };

  handleMarkTaskButton = () => {
    const { status } = this.state;
    if (status === "Open") {
      return (
        <div>
          {this.state.assignees.slice(0, 2).map((email, index) => (
            <Avatar
              name={email}
              round={true}
              size={46}
              key={index}
              style={{ margin: "0 20px 0 -42px", zIndex: -index }}
            />
          ))}
          {this.state.assignees.length > 2 ?
            (<Avatar
              name={`+ ${this.state.assignees.length - 2}`}
              round={true}
              size={46}
              key="4"
              color="#737373"
              style={{ margin: "0 20px 0 -42px", zIndex: 10 }}
            />)
            :
            ''}
          <button
            onClick={this.showAssignContainer}
            className="assign"
            data-target="#sideBarModal"
            data-toggle="modal"
          >
            ASSIGN
          </button>
        </div>
      );
    } else if (status === 'Completed' || status === 'Declined') {
      return (
        <button
          className="assign"
          data-target="#openModal"
          data-toggle="modal"
        >
          REOPEN
        </button>
      )
    } else {
      return (
        <span></span>
      )
    }
  };

  render() {
    const {
      history,
      errorData,
      taskList,
      pageCount,
      projectId,
      // taskId,
      projectDetails,
      dropDownItems
      // data,
      // comments
    } = this.props;

    let expert = {};
    if (errorData.experts) {
      expert = errorData.experts[Math.floor(Math.random() * errorData.experts.length)];
    }

    return (
      <div className="desktop-view">
        {this.state.snackbarMessage !== undefined && <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
          open={this.state.snackbarOpen} autoHideDuration={10000}
          onClose={this.handleSnackbarClose}

        >
          <SnackbarContent
            style={{ backgroundColor: '#47d147', fontWeight: "bold" }}
            message={this.state.snackbarMessage}
            action={
              <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit"
                  onClick={this.handleSnackbarClose}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        </Snackbar>}

        <Header menuItems={dropDownItems} />
        {/* Main Division*/}
        {taskList.length > 0 ?
          <main className="container-fluid">
            <div className="mainWrapper">
              {/* Section-1/Assign*/}
              <div className="breadcrumbs-wrapper">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <button
                        className="plain-button"
                        onClick={() =>
                          history.push(`/tasks?projectId=${projectId}`)
                        }
                      >
                        {projectDetails.domain}
                      </button>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Tasks
                   </li>
                  </ol>
                </nav>
              </div>
              <section className="headingBack">
                <div className="text">{errorData.error}</div>
                {this.handleMarkTaskButton()}
                <div className="details-assigned-container">
                  {/* <div className="details-assignPerson-one">
                   </div>
                   <div className="details-assignPerson-rest">
                   </div> */}
                </div>
              </section>
              {/* Section-2/Rating*/}
              <section className="ratingBack">
                <div className="ratingMap-back-q">
                  <div>
                    {/* <div className="ratingMap-text">Improves rating</div> */}
                    <div className={`ratingMap-tag ${errorData.priority}`}>
                      {errorData.priority}
                    </div>
                  </div>
                  {/* <div className="ratingMap-graph">
                   <AreaChart
                     width={175}
                     height={80}
                     data={data}
                     margin={{
                         top: 10, right: 10, left: 0, bottom: 0,
                     }}
                   >
                     <CartesianGrid strokeDasharray="3 3" />
                     <XAxis dataKey="name" />
                     <YAxis />
                     <Tooltip />
                     <Area type="monotone" dataKey="uv" stroke="none" fill="#FF6969" />
                   </AreaChart>
                 </div> */}
                  <div className="ratingPagesCount">
                    <b>{pageCount}</b> PAGES
                 </div>
                </div>
                {/* <div className="ratingTraffic"><b>27K</b> TRAFFIC AFFECTED</div> */}
                <div className="task-actions">
                  {this.state.status === 'Open' ?
                    <React.Fragment>
                      <Chip
                        avatar={<CheckCircleIcon
                          style={{ color: green[500] }} />}
                        label="Mark as done"
                        data-toggle="modal"
                        data-target="#doneModal" />
                      <span>&nbsp;</span>
                      <Chip
                        avatar={<RemoveCircleIcon
                          style={{ color: red[500] }} />}
                        label="Decline task"
                        data-toggle="modal"
                        data-target="#declineModal" />
                    </React.Fragment>
                    :
                    <Chip
                      variant="outlined"
                      color={this.state.status === 'Completed' ? 'primary' : 'secondary'}
                      size="small"
                      label={this.state.status} />
                  }
                </div>
              </section>

              {/* Section-5/Affected page*/}
              <TaskDetailList
                domain={projectDetails.domain}
                history={history}
                projectId={projectId}
                taskList={taskList}
                errorData={errorData}
                expert={expert}
                pageCount={pageCount}
                getTaskDetailsWrapper={this.getTaskDetailsWrapper}
              />
              {/* Section-6/write comment*/}
              {/* <section className="saySomthing">
                             <input type="text" placeholder="Say something" className="write-comments" />
                             <img src={telegram} />
                         </section> */}
              {/* Section-7/ show Comments*/}
              {/* <section className="comments-wrapper">
                             <Comments comments={comments} />
                         </section> */}
            </div>
            <Footer />


            <div class="container">
              <div class="modal" id="openModal">
                <div class="confirm-modal-dialog confirm-modal">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h4 class="modal-title">Reopen Task</h4>
                      <button type="button" class="close" data-dismiss="modal">
                        &times;
                     </button>
                    </div>

                    <div class="confirmModalbody">
                      Do you want to reopen this task ?
                   </div>

                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn no-btn"
                        data-dismiss="modal"
                      >
                        No
                     </button>
                      <button
                        type="button"
                        class="btn yes-btn"
                        data-dismiss="modal"
                        onClick={() => {
                          this.handleTaskStatus(false, true, "Open");
                          this.setState({ status: "Open" });
                        }}
                      >
                        Yes
                     </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="container">
              <div class="modal" id="doneModal">
                <div class="confirm-modal-dialog confirm-modal">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h4 class="modal-title">Mark as done</h4>
                      <button type="button" class="close" data-dismiss="modal">
                        &times;
                     </button>
                    </div>

                    <div class="confirmModalbody">
                      Are you sure this task is done?
                   </div>

                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn no-btn"
                        data-dismiss="modal"
                      >
                        No
                     </button>
                      <button
                        type="button"
                        class="btn yes-btn"
                        data-dismiss="modal"
                        onClick={() => {
                          this.handleTaskStatus(false, true, "Completed");
                          this.setState({ status: "Completed" });
                        }}
                      >
                        Yes
                     </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="container">
              <div class="modal" id="declineModal">
                <div class="confirm-modal-dialog confirm-modal">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h4 class="modal-title">Decline Task</h4>
                      <button type="button" class="close" data-dismiss="modal">
                        &times;
                     </button>
                    </div>

                    <div class="confirmModalbody">
                      Are you sure you want to decline this task?
                   </div>

                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn no-btn"
                        data-dismiss="modal"
                      >
                        No
                     </button>
                      <button
                        type="button"
                        class="btn yes-btn"
                        data-dismiss="modal"
                        onClick={() => {
                          this.handleTaskStatus(false, true, "Declined");
                          this.setState({ status: "Declined" });
                        }}
                      >
                        Yes
                     </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <AssignContainer
              mainProps={this.props}
              assigned={assignees => {
                this.setState({ assignees });
              }}
            />
          </main>
          :
          <div style={{ marginTop: "40vh", marginBottom: "40vh", marginLeft: "50vw" }}>
            <div class="boxes">
              <div class="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div class="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div class="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div class="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

TaskDetailDesktop.propTypes = {
  history: PropTypes.object.isRequired,
  dropDownItems: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  taskList: PropTypes.array.isRequired,
  errorData: PropTypes.object.isRequired,
  projectDetails: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired,
  taskId: PropTypes.string.isRequired,
  pageCount: PropTypes.number.isRequired,
  comments: PropTypes.array.isRequired,
  getTaskDetails: PropTypes.func.isRequired
};
