import React, { Component } from "react";
import { userService } from "../../Services";
import TextField from "@material-ui/core/TextField";
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Header from "../Header/Header.js";
import Footer from "../Footer/Footer";
import LogoIcon from "../LogoIcon";

import "../../App.css";
import "./PasswordStyle.css";

import passwordImg from "../image/password.svg";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    let changePasswordId = new URL(document.location).searchParams.get("changePasswordId");
    this.state = {
      changePasswordId: changePasswordId ? changePasswordId : "",
      password: "",
      confirmPassword: "",
      loading: false,
      error: false,
      errorMsg: "",
      success: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { changePasswordId } = this.state
    if (!changePasswordId) {
      window.location.replace('/home')
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    const { password, confirmPassword, changePasswordId } = this.state;
    if (!password || password !== confirmPassword) {
      this.setState({
        error: true,
        errorMsg: "Passwords did not match",
        loading: false,
      })
      setTimeout(() => {
        this.setState({
          error: false,
          errorMsg: ""
        })
      }, 5000)
      return
    };

    this.setState({ loading: true });
    userService
      .reset(changePasswordId, password)
      .then(() => { this.setState({ success: true }) })
      .catch(error => {
        let message = ""
        try {
          message = error.response.data.message
        } catch {
          message = "Error connecting to authentication server"
        }
        this.setState({
          error: true,
          errorMsg: message,
          loading: false
        })
        setTimeout(() => {
          this.setState({
            error: false,
            errorMsg: ""
          })
        }, 5000)
      });
  }

  handleFailure = error => {
    console.log(error);
  };

  render() {
    const { password, loading, error, confirmPassword, success } = this.state;

    return (
      <div className="login-wrap" style={{background:"white"}}>
        {/* <header className="login-header">
          <a href="/home">
            <div className="mai-logo">
            <LogoIcon height="auto" width="150px" type="img" />
            </div>
          </a>
        </header> */}
        <Header hideUserMenu={true}/>
        <div className="desktop-view">
        <div className="login-main-back">
          <section className="mai-heading">
          <LogoIcon width="176px" type="img" />
          </section>
          {!success && <form onSubmit={this.handleSubmit}>
            <section className="mai-login-input">
              {this.state.error ?
                <div className="error-text">
                  <FormHelperText error id="component-error-text">{this.state.errorMsg}</FormHelperText>
                </div>
                :
                ''
              }
              <div className="mai-login-competitors-container">
                <div className="login-left-icon">
                  <img src={passwordImg} />
                </div>
                <div className="login-right-icon">
                  <div className="web-address-input-container">
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label="New Password"
                      type="password"
                      value={password}
                      id="password"
                      autoComplete="current-password"
                      onChange={this.handleChange}
                      // error={error && true}
                    />
                  </div>
                </div>
              </div>
              <div className="mai-login-competitors-container pass-border">
                <div className="login-left-icon">
                  <img src={passwordImg} />
                </div>
                <div className="login-right-icon">
                  <div className="web-address-input-container">
                    <TextField
                      required
                      fullWidth
                      id="confirmPassword"
                      label="Confirm Password"
                      name="confirmPassword"
                      value={confirmPassword}
                      autoComplete="confirmPassword"
                      type="password"
                      onChange={this.handleChange}
                      autoFocus
                      // error={error && true}
                    />
                  </div>
                </div>
              </div>
            </section>
            {/* ..........login/login-button.............. */}
            <section className="login-button">
              <button
                type="submit"
                className="mai-login-btn-compare"
                disabled={loading}
              >
                {!loading ? 'CHANGE' : 'Processing...'}
                <div className="right-icon-container">
                  {
                    this.state.loading ?
                      <CircularProgress className="spinner" size={18} /> :
                      <KeyboardArrowRightIcon />
                  }
                </div>
              </button>
            </section>
          </form> ||
            <section className="success-content">
              <p> Your password updated succesfully! </p>
              <button className="nav-btn" onClick={() => { window.location.replace('/home') }} > Home</button><br />
              <button className="nav-btn" onClick={() => { window.location.replace('/login') }}> Login </button>
            </section>
          }<section className="swipe-back">
          </section>
          <Footer />
        </div>
        </div>
        <div className="mob-view">
        <div className="mai-login-main-back">
          <section className="mai-heading">
          <LogoIcon width="176px" type="img" />
          </section>
          {!success && <form onSubmit={this.handleSubmit}>
            <section className="mai-login-input">
              {this.state.error ?
                <div className="error-text">
                  <FormHelperText error id="component-error-text">{this.state.errorMsg}</FormHelperText>
                </div>
                :
                ''
              }
              <div className="mai-login-competitors-container">
                <div className="login-left-icon">
                  <img src={passwordImg} />
                </div>
                <div className="login-right-icon">
                  <div className="web-address-input-container">
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label="New Password"
                      type="password"
                      value={password}
                      id="password"
                      autoComplete="current-password"
                      onChange={this.handleChange}
                      // error={error && true}
                    />
                  </div>
                </div>
              </div>
              <div className="mai-login-competitors-container pass-border">
                <div className="login-left-icon">
                  <img src={passwordImg} />
                </div>
                <div className="login-right-icon">
                  <div className="web-address-input-container">
                    <TextField
                      required
                      fullWidth
                      id="confirmPassword"
                      label="Confirm Password"
                      name="confirmPassword"
                      value={confirmPassword}
                      autoComplete="confirmPassword"
                      type="password"
                      onChange={this.handleChange}
                      autoFocus
                      // error={error && true}
                    />
                  </div>
                </div>
              </div>
            </section>
            {/* ..........login/login-button.............. */}
            <section className="login-button">
              <button
                type="submit"
                className="mai-login-btn-compare"
                disabled={loading}
              >
                {!loading ? 'CHANGE' : 'Processing...'}
                <div className="right-icon-container">
                  {
                    this.state.loading ?
                      <CircularProgress className="spinner" size={18} /> :
                      <KeyboardArrowRightIcon />
                  }
                </div>
              </button>
            </section>
          </form> ||
            <section className="success-content">
              <p> Your password updated succesfully! </p>
              <button className="nav-btn" onClick={() => { window.location.replace('/home') }} > Home</button><br />
              <button className="nav-btn" onClick={() => { window.location.replace('/login') }}> Login </button>
            </section>
          }<section className="swipe-back">
          </section>
          <Footer />
        </div>

        </div>
      </div>
    );
  }
}

export default ResetPassword;
