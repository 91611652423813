import React, { Component } from "react";
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import '../../App.css'

import DesktopImg from './../image/desktop.png';
import intersectionImg from '../image/Intersection-1@2x.png';
import iconfinderCheckImg from '../image/iconfinder_check_alt_118699@2x.png';
import iconfinderAnalyticsImg from '../image/iconfinder_f-analytics2_128_308020.png';
import iconfinderAdwordsImg from '../image/iconfinder_9_Adwords_4518967@2x.png';
import menuIcon from '../image/menu-(2).svg';
import settingsIcon from '../image/settings.svg';
import arrowWhite from '../image/arrow-white.svg';
import circleImg from '../image/circle.svg';
import backArrow from './../image/back-arrow.svg';
import worldWide from '../image/worldwide.svg';
import arrow from '../image/Arrow.svg';
import minus from '../image/minus.svg';

import '../TaskDetails/taskDetail.css';
import '../Tasks/style.css';
import './Dashboard.css';
import ConnecedAppsDesktop from "./ConnectedAppsDesktop";
import TaskListSection from "./TaskListSection";


class Dashboard extends Component {

    render() {
        return (
            <div>
                <div className="desktop-view">
                    <Header />
                    <div className="body">
                        <div className="dash-main">
                            <div className="container">
                                <div className="row">
                                    <section className="col-md-6">
                                        <div className="dash-leftWrapper">
                                            <div className="frame-back">
                                                {/* <img src={FrameInsideImg} className="image-frame-inside" /> */}
                                                <img src={DesktopImg} className="image-frame" />
                                            </div>
                                        </div>
                                    </section>
                                    {/* ......aside/ right side....... */}
                                    <aside className="col-md-6">
                                        <div className="dash-rightWrapper">

                                            {/* ......aside/ right side-header....... */}
                                            <div className="dash-rightHeader">
                                                <div className="code-image">
                                                    <img src={intersectionImg} />
                                                </div>
                                                <div className="code-text">
                                                    <span>Smarter.codes</span>
                                                </div>
                                            </div>

                                            {/* ......aside/ right side-Bar....... */}
                                            <div className="dash-rightBar">
                                                <div>
                                                    <span className="crawl-percent">1 crawled (100%)</span>
                                                    <span className="crawl-time">Last crawl 30 minutes ago</span>
                                                </div>
                                                <div className="progress" style={{ height: "20px" }}>
                                                    <div className="progress-bar" style={{ width: "50%", height: "20px" }}></div>
                                                </div>
                                                <div>
                                                    <span className="crawl-percent">1 page found</span>
                                                </div>
                                            </div>

                                            {/* ......aside/ right side-score....... */}
                                            <div className="dash-rightScore">
                                                <div className="seo-score">
                                                    <div className="score-detail">
                                                        <span className="score-label">SEO SCORE</span>
                                                        <div className="score-count">36<small>/100</small></div>
                                                        <div className="score-message">27 checkes passed, 78 failed</div>
                                                    </div>
                                                </div>
                                                <div className="content-score">
                                                    <div className="score-detail">
                                                        <span className="score-label">CONTENT SCORE</span>
                                                        <div className="score-count">22<small>/100</small></div>
                                                        <div className="score-message">9 pages of 156 analyzed</div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* ......aside/ right side-connect-app....... */}
                                            <ConnecedAppsDesktop />

                                            {/* ......aside/ right side-code....... */}
                                            <TaskListSection />

                                            {/* ......aside/ right side-COMPETITIORS....... */}
                                            <div className="dash-rightCompetitior">
                                                <div className="dash-competitors-heading">
                                                    COMPARE AGAINST COMPETITIORS
                                </div>
                                                <div className="compare-competitors-container">
                                                    <div className="left-icon-container">
                                                        <img src={worldWide} alt={'worldwide'} />

                                                    </div>
                                                    <div className="right-icon-container">
                                                        <div className="web-address-label">
                                                            enter website address
                                        </div>
                                                        <div className="web-address-input-container">
                                                            <input className="web-address-input" type="text" placeholder="your competitor domain.com" style={{ border: "none", width: "100%", fontSize: "15px", left: "1%", marginLeft: "1%" }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* ......aside/ right side-button....... */}
                                            <div className="dash-rightbutton">
                                                <button className="dash-btn-compare">
                                                    add competitor
                                    <div className="right-icon-container">
                                                        <img src={arrow} />
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </aside>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
                {/* .........Mobile View............ */}
                <div className="mob-view">
                    {/* .........Mobile View/Top............  */}
                    <div className="mob-dash-top-wrap">
                        <div className="mob-dash-head">
                            <div className="mob-dash-heading-container">
                                <div className="back-button-div">
                                    <a href={''}>
                                        <img src={backArrow} alt="" />
                                    </a>
                                </div>
                                <div className="mob-page-heading">
                                    Website.com
                    </div>
                                <div className="setting-icon">
                                    <img src={settingsIcon} width="18" alt={'settings icon'} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="mob-dash-progress">
                                    <div className="mob-linear-progress" >
                                        <div className="mob-linear-progress-bar">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="circle-bar">
                            <div className="row">
                                <div className="col-sm-4 col-4-custom">
                                    <div className="page-analyzed">
                                        <label className="label-custom">Pages Analyzed</label>
                                        <div className="span-custom">09</div>
                                    </div>
                                    <div className="total-progress">
                                        <label className="label-custom">Total Progress</label>
                                        <div className="total-span-custom">100%</div>
                                    </div>
                                </div>
                                <div className="col-sm-8 col-8-custom">
                                    <div className="mob-circle-progress mx-auto" data-value='80'>
                                        <span className="mob-circle-progress-left">
                                            <span className="mob-circle-progress-bar border-primary"></span>
                                        </span>
                                        <span className="mob-circle-progress-right">
                                            <span className="mob-circle-progress-bar border-primary"></span>
                                        </span>
                                        <div className="mob-circle-progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                                            <div className="h2 font-weight-bold">80</div><br />
                                            <div style={{ fontSize: "7px", color: "#A3A9AF" }}>PERFORMANCE SCORE</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* .........Mobile View/SEO............  */}
                    <section className="mob-dash-seo-wrap">
                        <div className="score-detail">
                            <div>
                                <span className="score-label">SEO SCORE</span>
                                <a href="#" className="score-label">
                                    <img src={arrowWhite} alt={'down-arrow'} width="12px" />
                                </a>
                            </div>
                            <div className="score-count">36<small>/100</small></div>
                            <div className="score-message">27 checkes passed, 78 failed</div>
                        </div>
                    </section>
                    {/* .........Mobile View/Content............  */}
                    <section className="mob-dash-content-wrap">
                        <div className="score-detail">
                            <div>
                                <span className="score-label">CONTENT SCORE</span>
                                <a href="#" className="score-label">
                                    <img src={arrowWhite} alt={'down-arrow'} width="12px" />
                                </a>
                            </div>
                            <div className="score-count">22<small>/100</small></div>
                            <div className="score-message">9 pages of 156 analyzed</div>
                        </div>
                    </section>
                    {/* .........Mobile View/Tasks............  */}
                    <section className="mob-dash-task-wrap">
                        <div className="mob-dash-task-head">
                            Tasks
            </div>
                        <button type="button" className="btn opportunitie">
                            99+ Opportunities
            </button>

                        <div className="mob-dash-task">
                            <div className="tasks-white-box">
                                <div className="right-sign">
                                    <img src={iconfinderCheckImg} alt='icon-finder-check' width="23px" />
                                </div>

                                <div className="white-box-text">
                                    <span className="white-box-redTag">HIGH</span><br />
                                    <span>Site Map is missing </span><br />
                                    <span className="sub-tags">21 Pages <img src={circleImg} alt={'circle'} width="3px" /> 3k Traffic</span>
                                </div>

                                <div className="white-box-redTag">
                                    <div>UNASSIGNED</div>
                                    <div className="unassigned-sub">ID #25721</div>
                                </div>

                            </div>

                            <div className="tasks-white-box">
                                <div className="right-sign">
                                    <img src={iconfinderCheckImg} alt='icon-finder-check' width="23px" />
                                </div>

                                <div className="white-box-text">
                                    <span className="white-box-redTag">HIGH</span><br />
                                    <span>Site Map is missing </span><br />
                                    <span className="sub-tags">21 Pages <img src={circleImg} alt={'circle'} width="3px" /> 3k Traffic</span>
                                </div>

                                <div className="white-box-redTag">
                                    <div>UNASSIGNED</div>
                                    <div className="unassigned-sub">ID #25721</div>
                                </div>

                            </div>

                            <div className="tasks-white-box">
                                <div className="right-sign">
                                    <img src={iconfinderCheckImg} alt='icon-finder-check' width="23px" />
                                </div>

                                <div className="white-box-text">
                                    <span className="white-box-redTag">HIGH</span><br />
                                    <span>Site Map is missing</span><br />
                                    <span className="sub-tags">21 Pages <img src={circleImg} alt={'circle'} width="3px" /> 3k Traffic</span>
                                </div>

                                <div className="white-box-redTag">
                                    <div>UNASSIGNED</div>
                                    <div className="unassigned-sub">ID #25721</div>
                                </div>

                            </div>
                        </div>
                    </section>
                    {/* .........Mobile View/connect App............  */}
                    <section className="mob-dash-connect-wrap">
                        <div className="mob-dash-task-head">
                            Connected apps
                </div>
                        <div className="mob-dash-view-all">
                            <a href="#">View all apps</a>
                        </div>

                        <div className="apps-container">
                            <div className="app-box-connected">
                                <div className="app-black-base">
                                    <img src={iconfinderAnalyticsImg} alt={'analytics-icon'} width="45px" />
                                </div>
                                <div className="mob-dash-app-base-text">
                                    <div>Google Analytics</div>
                                    <div className="app-black-base-text-status">Connected</div>
                                </div>


                            </div>
                            <div className="app-box">
                                <div className="app-black-base">
                                    <img src={iconfinderAdwordsImg} alt={'analytics-icon'} width="45px" />
                                </div>
                                <div className="app-black-base-text">
                                    <span>Google Adwords</span>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* .........Mobile View/competitors............  */}
                    <section className="mob-dash-competitor-wrap">
                        <div className="heading-box">
                            COMPARE AGAINST COMPETITIORS
            </div>
                        <div className="wesite-container">
                            <div className="left-icon-container">
                                <img src={worldWide} alt={'worldwide'} width="19px" />
                            </div>

                            <div className="right-icon-container">
                                <div className="web-address-label">
                                    enter website address
                    </div>
                                <div className="web-address-input-container">
                                    <input className="web-address-input" type="text" placeholder="your competitor domain.com" />
                                </div>
                            </div>
                        </div>

                        <button className="add-competitor-button">
                            add competitor
                <div className="appButton-right-icon">
                                <img src={arrow} alt={'aroow'} />
                            </div>
                        </button>

                        <div className="your-competitor-container">

                            <div className="your-comp-left-icon">
                                <img src={worldWide} alt={'worldwide'} width="19px" />
                            </div>

                            <div className="web-address-entered-container">
                                <div className="web-address-entered">
                                    your competitor domain.com
                    </div>
                            </div>

                            <div className="your-comp-right-icon">
                                <img src={minus} alt={'minus'} width="19px" />
                            </div>
                        </div>

                    </section>
                </div>
            </div>
        );
    }
}
export default Dashboard;
