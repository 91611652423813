import React, { Component } from "react";
import { userService } from "../../Services";
import TextField from "@material-ui/core/TextField";
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Header from "../Header/Header.js";
import Footer from "../Footer/Footer";
import axios from "axios";

import "../../App.css";
import "./SignupPage.css";

import arrowImg from "../image/Arrow.svg";
import envelopeImg from "../image/envelope.svg";
import passwordImg from "../image/password.svg";

import { GoogleLogin } from "react-google-login";
import { LinkedIn } from "react-linkedin-login-oauth2";

import LogoIcon from "../LogoIcon";

var config = require("../../Config/config");

class SignupPage extends Component {
  constructor(props) {
    super(props);

    // userService.logout();

    this.state = {
      email: "",
      loading: false,
      g_loading: false,
      l_loading: false,
      errorEmail: "",
      error: false,
      errorPass: "",
      loggedOut: false,
      passwordError:"",
      DisplayEmail:true,
      duplicate:false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    const auth = config.middleware.url;
    const url = auth + "/tokencheck";
    axios
      .get(url, { headers: { Authorization: "Bearer " + localStorage.getItem("token") } })
      .then(res => {
        if (res.data.success &&
          res.data.message.search("Middleware")) {
          window.location.replace('/home')
        } else {
          this.setState({ loggedOut: true })
        }
      })
      .catch(err => { console.log(err.message); });
  }

  handleChange(e) {
    const { name, value } = e.target;
    var pattern = new RegExp(/^(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d][A-Za-z\d!@#$%^&*()_+]{7,19}$/).test(value);
    if((name==='password'||name==='confirmPassword')&&!pattern){
      this.setState({[name]: value, errorPass:'Password must be of 8 to 20 characters. It must have a number, special character and a capital alphabet'})
    }
    else{
      this.setState({[name]: value, errorPass:''})
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    const { email } = this.state;
    // set state is async and state of error immediately becomes unreliable
    let abort = false
    // stop here if form is invalid
    if (!(email)) {
      return;
    }

    this.setState({ error: false, errorEmail: ""})

    if (!/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
      abort = true
      this.setState({ errorEmail: email + " is not a valid email address", error: true })
    }
    if (abort) {
      /*
      setTimeout(() => {
        this.setState({
          error: false,
          errorEmail: "",
          errorPass: ""
        })
      }, 4000)
      */
      return;
    }

    this.setState({ loading: true });
    userService.EmailSignup(email,"signUp").then( data => {
      if (data.data.status !=="failed"){
      this.setState({
        DisplayEmail :false,
        loading: false
      })
    }
    else{
      this.setState({
        duplicate:true,
        DisplayEmail :false,
        loading: false
      })
    }
    // error => this.setState({ error, loading: false })
  }).catch(error => {
      console.log(error)
      let errors = {}
      try {
        errors = error.response.data.error
      } catch {
        errors.email = "Error connecting to authentication server"
      }
      this.setState({
        error: true,
        errorEmail: errors.email,
        errorPass: errors.pass,
        loading: false
      })
      /*
      setTimeout(() => {
        this.setState({
          error: false,
          errorEmail: "",
          errorPass: ""
        })
      }, 4000)
      */
    });
  }

  googleLogin = response => {
    this.setState({ g_loading: true });
    const tokenId = response.tokenId;
    userService.loginGoogle(tokenId).then(
      message => {
        const { from } = window.history.state || {
          from: { pathname: "/" }
        };
        from.pathname = from.pathname ? from.pathname : "/"
        window.history.replaceState({ message }, '', from.pathname)
        window.history.go()
      },
      error => this.setState({ error, g_loading: false })
    );
  };

  linkedinLogin = data => {
    this.setState({ l_loading: true })
    const code = data.code;
    userService.loginLinkedin(code).then(
      message => {
        const { from } = window.history.state || {
          from: { pathname: "/" }
        };
        from.pathname = from.pathname ? from.pathname : "/"
        window.history.replaceState({ message }, '', from.pathname)
        window.history.go()
      },
      error => this.setState({ error, l_loading: false })
    );
  };

  handleFailure = error => {
    console.log(error);
  };

  render() {
    const { email, password, loading, errorPass, errorEmail, confirmPassword } = this.state;

    return this.state.loggedOut && (
      <>
      <div className ="desktop-view">
      <div className="login-wrap" style={{background:"white"}}>
        {/* ..........login/header.............. */}
        {/* <header className="login-header">
          <a href="/home">
            <div className="mai-logo">
              <LogoIcon height="auto" width="150px" type="img" />
            </div>
          </a>
        </header> */}
        <Header hideUserMenu={true}/>
        {/* ..........login/main heading........ */}
        <div className="login-main-back">
          <section className="mai-heading">
          <LogoIcon width="176px" type="img" />
          </section>
          {/* ..........login/forgot............. */}
          <section className="mai-login-forgot-n">
            {/* <div className="signin-link">
              <a
                onClick={() => {
                  window.location.replace("/signup");
                }}
              >
                Sign Up
              </a>
            </div> */}
            <div className="mai-login-link-n">
              <a
                style={{ color: "#E7EAF0" }}
                onClick={() => {
                  window.location.replace("/login");
                }}
              >
                Log In
              </a>
            </div>
            <div className="mai-forgot-link-n">
              <a
                style={{ color: "#E7EAF0" }}
                onClick={() => {
                  window.location.replace("/forgot");
                }}
              >
                Forgot Password
              </a>
            </div>
          </section>
          {/* ..........login/input............... */}
          {(this.state.DisplayEmail)?
          <form onSubmit={this.handleSubmit}>
            <section className="mai-login-input">
              {errorEmail ?
                <div className="error-text">
                  <FormHelperText error id="component-error-text">{errorEmail}</FormHelperText>
                </div>
                :
                ''
              }
              <div className="mai-login-competitors-container">
                <div className="login-left-icon">
                  <img src={envelopeImg} />
                </div>
                <div className="login-right-icon">
                  <div className="web-address-input-container">
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="E-mail"
                      name="email"
                      value={email}
                      autoComplete="email"
                      onChange={this.handleChange}
                      autoFocus
                      // error={errorEmail && true}
                    />
                  </div>
                </div>
              </div>
            </section>
            {/* ..........login/login-button.............. */}
            <section className="login-button">
              <button
                type="submit"
                className="mai-login-btn-compare"
                disabled={loading}
              >
                {!loading ? 'SIGN UP' : 'Creating...'}
                <div className="right-icon-container">
                  {
                    this.state.loading ?
                      <CircularProgress className="spinner" size={18} /> :
                      <KeyboardArrowRightIcon />
                  }
                </div>
              </button>
            </section>
          </form>
          :<div><div className="EmailValidation">
            {(this.state.duplicate)?
            <span>Email already in use</span>
          :          
            <span>Email verification is initiated for {this.state.email}, Please check the inbox.</span>
            }
            <br></br>
            <button onClick={()=>this.setState({duplicate:false,DisplayEmail:true})} className="retry">retry or change email address</button>
            </div>
            
            </div>
            }
          <div className="mai-login-below-text">or use</div>
          {/* ..........login/goole-button.............. */}
          <section className="social-button-back">
            <GoogleLogin
              clientId={config.auth.googleClientId}
              render={renderProps => (
                <button
                  onClick={renderProps.onClick}
                  disabled={loading?loading:renderProps.disabled}
                  className="mai-google-button"
                >
                  {
                    this.state.g_loading ?
                      <CircularProgress className="spinner" size={18} /> :
                      'CONTINUE WITH GOOGLE'
                  }
                </button>
              )}
              buttonText="Login"
              onSuccess={this.googleLogin}
              onFailure={this.handleFailure}
              cookiePolicy={"single_host_origin"}
            />
          </section>
          {/* ..........login/facebook-button.............. */}
          <section className="social-button-back">
            <LinkedIn
              clientId={config.auth.linkedinClientId}
              onFailure={this.handleFailure}
              onSuccess={this.linkedinLogin}
              redirectUri={config.auth.linkedinRedirectUri}
              scope="r_liteprofile r_emailaddress"
              renderElement={({ onClick, disabled }) => (
                <button
                  onClick={onClick}
                  disabled={loading?loading:disabled}
                  className="mai-li-button"
                >
                  {
                    this.state.l_loading ?
                      <CircularProgress className="spinner" size={18} /> :
                      'CONTINUE WITH LinkedIn'
                  }
                </button>
              )}
            />
          </section>
          {/* ..........login/swipe option..............  */}
          <section className="swipe-back">
            <div className="swipe">
              By creating an account, you agree to our
              <br />
              <a href="#" className="create-acc">
                Terms of Service
              </a>{" "}
              and{" "}
              <a href="#" className="create-acc">
                Privacy Policy
              </a>
            </div>
          </section>
          <Footer />
          <div>
            {/* ..........login/Pre footer.......... */}
          </div>
        </div>
      </div>
      </div>
      <div className="mob-view">
        <div className="login-wrap" style={{background:"white"}}>
        {/* ..........login/header.............. */}
        {/* <header className="login-header">
          <a href="/home">
            <div className="mai-logo">
              <LogoIcon height="auto" width="150px" type="img" />
            </div>
          </a>
        </header> */}
        <Header hideUserMenu={true}/>
        {/* ..........login/main heading........ */}
        <div className="mai-login-main-back">
          <section className="mai-heading">
          <LogoIcon width="176px" type="img" />
          </section>
          {/* ..........login/forgot............. */}
          <section className="mai-login-forgot-n">
            {/* <div className="signin-link">
              <a
                onClick={() => {
                  window.location.replace("/signup");
                }}
              >
                Sign Up
              </a>
            </div> */}
            <div className="mai-login-link-n">
              <a
                style={{ color: "#E7EAF0" }}
                onClick={() => {
                  window.location.replace("/login");
                }}
              >
                Log In
              </a>
            </div>
            <div className="mai-forgot-link-n">
              <a
                style={{ color: "#E7EAF0" }}
                onClick={() => {
                  window.location.replace("/forgot");
                }}
              >
                Forgot Password
              </a>
            </div>
          </section>
          {/* ..........login/input............... */}
          {(this.state.DisplayEmail)?
          <form onSubmit={this.handleSubmit}>
            <section className="mai-login-input">
              {errorEmail ?
                <div className="error-text">
                  <FormHelperText error id="component-error-text">{errorEmail}</FormHelperText>
                </div>
                :
                ''
              }
              <div className="mai-login-competitors-container">
                <div className="login-left-icon">
                  <img src={envelopeImg} />
                </div>
                <div className="login-right-icon">
                  <div className="web-address-input-container">
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="E-mail"
                      name="email"
                      value={email}
                      autoComplete="email"
                      onChange={this.handleChange}
                      autoFocus
                      // error={errorEmail && true}
                    />
                  </div>
                </div>
              </div>
            </section>
            {/* ..........login/login-button.............. */}
            <section className="login-button">
              <button
                type="submit"
                className="mai-login-btn-compare"
                disabled={loading}
              >
                {!loading ? 'SIGN UP' : 'Creating...'}
                <div className="right-icon-container">
                  {
                    this.state.loading ?
                      <CircularProgress className="spinner" size={18} /> :
                      <KeyboardArrowRightIcon />
                  }
                </div>
              </button>
            </section>
          </form>
          :<div><div className="EmailValidation">
            {(this.state.duplicate)?
            <span>Email already in use</span>
          :          
            <span>Email verification is initiated for {this.state.email}, Please check the inbox.</span>
            }
            <br></br>
            <button onClick={()=>this.setState({duplicate:false,DisplayEmail:true})} className="retry">retry or change email address</button>
            </div>
            
            </div>
            }
          <div className="mai-login-below-text">or use</div>
          {/* ..........login/goole-button.............. */}
          <section className="social-button-back">
            <GoogleLogin
              clientId={config.auth.googleClientId}
              render={renderProps => (
                <button
                  onClick={renderProps.onClick}
                  disabled={loading?loading:renderProps.disabled}
                  className="mai-google-button"
                >
                  {
                    this.state.g_loading ?
                      <CircularProgress className="spinner" size={18} /> :
                      'CONTINUE WITH GOOGLE'
                  }
                </button>
              )}
              buttonText="Login"
              onSuccess={this.googleLogin}
              onFailure={this.handleFailure}
              cookiePolicy={"single_host_origin"}
            />
          </section>
          {/* ..........login/facebook-button.............. */}
          <section className="social-button-back">
            <LinkedIn
              clientId={config.auth.linkedinClientId}
              onFailure={this.handleFailure}
              onSuccess={this.linkedinLogin}
              redirectUri={config.auth.linkedinRedirectUri}
              scope="r_liteprofile r_emailaddress"
              renderElement={({ onClick, disabled }) => (
                <button
                  onClick={onClick}
                  disabled={loading?loading:disabled}
                  className="mai-li-button"
                >
                  {
                    this.state.l_loading ?
                      <CircularProgress className="spinner" size={18} /> :
                      'CONTINUE WITH LinkedIn'
                  }
                </button>
              )}
            />
          </section>
          {/* ..........login/swipe option..............  */}
          <section className="swipe-back">
            <div className="swipe">
              By creating an account, you agree to our
              <br />
              <a href="#" className="create-acc">
                Terms of Service
              </a>{" "}
              and{" "}
              <a href="#" className="create-acc">
                Privacy Policy
              </a>
            </div>
          </section>
          <Footer />
          <div>
            {/* ..........login/Pre footer.......... */}
          </div>
        </div>
      </div>
      </div>
      </>
    );
  }
}

export default SignupPage;
