import React, { Component } from "react";
//import { Redirect, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import axios from "axios";
import Moment from 'react-moment';

import "./main.css";
import "./Accounts.css";

var config = require("../../Config/config");

const Styles =  theme =>({
    head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
    table: {
      minWidth: 900,
    },
  });

  

class ProjectDetailTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
        Remount:props.Remount,
        projectdetails:props.data,
        projectdata:"",
        plan:'',
        planDetails:'',
        prevplan:'',
        crawls:''
    };
  }

  componentDidMount() {
    this.setData()
  }
  componentDidUpdate(prevProps, prevState) {
      
    //  console.log(prevProps,prevState.plan,this.state.plan)
    if (prevProps.plan !== this.props.plan) {
        
        this.setData()
    
    }
  }

  formatNumber(n) {
      if(n >= 10000000) n = (n/10000000).toFixed(1)+' Cr';
      else if(n >= 100000) n = (n/100000).toFixed(1) + ' Lac';
      else if(n >= 1000) n = (n/1000).toFixed(1) + ' K';
      return n;
  }

setData(){
    
    //console.log("called set data",this.state.projectdetails)
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      };
    
      let REACT_APP_SERVER_HOST = config.middleware.url;
      // eslint-disable-next-line prefer-destructuring
      if (!REACT_APP_SERVER_HOST) REACT_APP_SERVER_HOST = process.env.REACT_APP_MAI_SERVER_HOST;

    
    
    axios.get(`${REACT_APP_SERVER_HOST}/getSubscriptionById?subscriptionId=${this.state.projectdetails.subs_id}`, { headers })
    .then((response) => {
        if (response.data.success === true){
            this.setState({
                projectdata:response.data   ,
                plan:response.data.subscription.plan_id
            })      
            
        }
      
    },(error)=>{
      console.log("Bad Response")
    });
}

  render() {
    const { classes } = this.props;
    return (
        <div>
            
            {(this.state.projectdata)?
            <>
            <div className="desktop-view">
                {console.log(this.state.projectdata,"--")}
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow className={classes.head}>
                                    <TableCell className={classes.head}>Subscription Table</TableCell>
                                    <TableCell className={classes.head}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            <TableRow key="">
                                    <TableCell component="th" scope="row">
                                        Subscription id
                                    </TableCell>
                                    <TableCell align="right">
                                        {this.state.projectdata.subscription.id}
                                    </TableCell>
                                </TableRow>
                                <TableRow key="">
                                    <TableCell component="th" scope="row">
                                        Customer
                                </TableCell>
                                    <TableCell align="right">
                                        {this.state.projectdata.subscription.customer_id}
                                    </TableCell>
                                </TableRow>
                                <TableRow key="">
                                    <TableCell component="th" scope="row">
                                        Plan
                                    </TableCell>
                                    <TableCell align="right" style={{textTransform:"uppercase"}}>
                                        {this.state.projectdata.subscription.plan_id}
                                    </TableCell>
                                </TableRow>
                                <TableRow key="">
                                    <TableCell component="th" scope="row">
                                        Plan Cost
                                     </TableCell>
                                    <TableCell align="right">
                                        {this.state.projectdata.subscription.plan_amount/100}&nbsp;{this.state.projectdata.subscription.currency_code}
                                    </TableCell>
                                </TableRow> <TableRow key="">
                                    <TableCell component="th" scope="row">
                                        Allowed crawls
                                    </TableCell>
                                    <TableCell align="right">
                                    {this.formatNumber(this.state.projectdata.subscription.crawl_limit)}
                                    </TableCell>
                                </TableRow>
                                <TableRow key="">
                                    <TableCell component="th" scope="row">
                                        Status
                                    </TableCell>
                                    <TableCell align="right" style={{textTransform:"uppercase"}}>
                                        {this.state.projectdata.subscription.status}
                                    </TableCell>
                                    </TableRow><TableRow key="">
                                    <TableCell component="th" scope="row">
                                        Term Duration
                                    </TableCell>
                                    <TableCell align="right">
                                        <Moment format="YYYY/MM/DD">{this.state.projectdata.subscription.current_term_start*1000}</Moment>
                                        &nbsp; to &nbsp;
                                        <Moment format="YYYY/MM/DD">{this.state.projectdata.subscription.current_term_end*1000}</Moment>
                                    </TableCell>
                                    
                                </TableRow>
                                <TableRow key="">
                                    <TableCell component="th" scope="row">
                                        Next Billing Date
                                    </TableCell>
                                    <TableCell align="right">
                                        <Moment format="YYYY/MM/DD">{this.state.projectdata.subscription.next_billing_at*1000}</Moment>
                                    </TableCell>
                                </TableRow>
                                
                            </TableBody>
                        </Table>
                    </TableContainer>
            
            </div>
            <div className="mob-view">
            {console.log(this.state.projectdata,"--")}
                    <TableContainer component={Paper} style={{marginLeft: "-30px",width: "108%", maxWidth:"130%", overflow:"hidden", display:"block" }}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow className={classes.head}>
                                    <TableCell className={classes.head}>Subscription Table</TableCell>
                                    <TableCell className={classes.head}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            <TableRow key="">
                                    <TableCell component="th" scope="row" style={{padding: "6px 2px 6px 2px", fontSize:"0.775rem"}}>
                                        Subscription id
                                    </TableCell>
                                    <TableCell align="left" style={{padding: "6px 2px 6px 2px", fontSize:"0.775rem"}}>
                                        {this.state.projectdata.subscription.id}
                                    </TableCell>
                                </TableRow>
                                <TableRow key="" >
                                    <TableCell component="th" scope="row" style={{padding: "6px 2px 6px 2px", fontSize:"0.775rem"}}>
                                        Customer
                                </TableCell>
                                    <TableCell align="left" style={{padding: "6px 2px 6px 2px", fontSize:"0.775rem"}}>
                                        {this.state.projectdata.subscription.customer_id}
                                    </TableCell>
                                </TableRow>
                                <TableRow key="">
                                    <TableCell component="th" scope="row" style={{padding: "6px 2px 6px 2px", fontSize:"0.775rem"}}>
                                        Plan
                                    </TableCell>
                                    <TableCell align="left" style={{padding: "6px 2px 6px 2px", fontSize:"0.775rem", textTransform:"uppercase"}}>
                                        {this.state.projectdata.subscription.plan_id}
                                    </TableCell>
                                </TableRow>
                                <TableRow key="">
                                    <TableCell component="th" scope="row" style={{padding: "6px 2px 6px 2px", fontSize:"0.775rem"}}>
                                        Plan Cost
                                     </TableCell>
                                    <TableCell align="left" style={{padding: "6px 2px 6px 2px", fontSize:"0.775rem"}}>
                                        {this.state.projectdata.subscription.plan_amount/100}&nbsp;{this.state.projectdata.subscription.currency_code}
                                    </TableCell>
                                </TableRow> <TableRow key="">
                                    <TableCell component="th" scope="row" style={{padding: "6px 2px 6px 2px", fontSize:"0.775rem"}}>
                                        Allowed crawls
                                    </TableCell>
                                    <TableCell align="left" style={{padding: "6px 2px 6px 2px", fontSize:"0.775rem"}}>
                                        {this.formatNumber(this.state.projectdata.subscription.crawl_limit)}
                                    </TableCell>
                                </TableRow>
                                <TableRow key="">
                                    <TableCell component="th" scope="row" style={{padding: "6px 2px 6px 2px", fontSize:"0.775rem"}}>
                                        Status
                                    </TableCell>
                                    <TableCell align="left" style={{padding: "6px 2px 6px 2px", fontSize:"0.775rem", textTransform:"uppercase"}}>
                                        {this.state.projectdata.subscription.status}
                                    </TableCell>
                                    </TableRow><TableRow key="">
                                    <TableCell component="th" scope="row" style={{padding: "6px 2px 6px 2px", fontSize:"0.775rem"}}>
                                        Term Duration
                                    </TableCell>
                                    <TableCell align="left" style={{padding: "6px 2px 6px 2px", fontSize:"0.775rem"}}>
                                        <Moment format="YYYY/MM/DD">{this.state.projectdata.subscription.current_term_start*1000}</Moment>
                                        &nbsp; to &nbsp;
                                        <Moment format="YYYY/MM/DD">{this.state.projectdata.subscription.current_term_end*1000}</Moment>
                                    </TableCell>
                                    
                                </TableRow>
                                <TableRow key="">
                                    <TableCell component="th" scope="row" style={{padding: "6px 2px 6px 2px", fontSize:"0.775rem"}}>
                                        Next Billing Date
                                    </TableCell>
                                    <TableCell align="left" style={{padding: "6px 2px 6px 2px", fontSize:"0.775rem"}}style={{padding: "6px 2px 6px 2px", fontSize:"0.775rem"}}>
                                        <Moment format="YYYY/MM/DD">{this.state.projectdata.subscription.next_billing_at*1000}</Moment>
                                    </TableCell>
                                </TableRow>
                                
                            </TableBody>
                        </Table>
                    </TableContainer>
            </div>
            </>
            
            :
            <div>No Data Available</div>}
        </div>
      
    );
  }
}

//export default withRouter(ProjectDetailTable);
export default withStyles(Styles, { withTheme: true })(ProjectDetailTable);