import React from "react";
import PropTypes from 'prop-types';
import './Header.css';
//import '../Tasks/style.css'
function TasksHeader(props) {
    return (
        <header className="mob-all-tasks" style={{ paddingLeft: 0 }}>
            <div className="heading-container">
                <button className="plain-button" onClick={() => props.history.goBack()}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24"
                        height="24" viewBox="0 0 24 24"
                    >
                        <defs>
                            <clipPath id="clip-path">
                                <rect width="24" height="24" fill="none" />
                            </clipPath>
                        </defs>
                        <g id="icons_back" data-name="icons/back" clipPath="url(#clip-path)">
                            <rect id="bg" width="24" height="24" fill="none" />
                            <g id="ico" transform="translate(-178 -48)">
                                <path
                                    id="union"
                                    d="M1597.541-4.4l-5.879-5.88a.749.749,0,0,1-.362-.642.748.748,0,0,1,.1-.378.744.744,0,0,1,.128-.171.751.751,0,0,1,.078-.068l5.932-5.932a.751.751,0,0,1,1.061,0,.751.751,0,0,1,0,1.061l-4.739,4.738h12.688a.75.75,0,0,1,.75.751.75.75,0,0,1-.75.75h-12.66l4.711,4.711a.751.751,0,0,1,0,1.061.749.749,0,0,1-.531.219A.749.749,0,0,1,1597.541-4.4Z"
                                    transform="translate(-1408.3 70.92)" fill="#241332"
                                />
                            </g>
                        </g>
                    </svg>
                </button>
                {props.hideTitle ? (
                    null
                ) : (
                    <div className="mob-page-heading">
                        Tasks
                    </div>
                )}

                {props.changeTitleName != undefined ? (
                    <div className="mob-page-heading">
                    {props.changeTitleName}
                    </div>

                ) : (
                null
                )}
                
            </div>
            {props.hideFilters ? (
                null
            ) : (
                <div className="hamburger-container" data-toggle="modal" data-target="#mainModal">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                    <defs>
                        {/* <style>
                            .a {
                                fill: #1d0d37;
                            }
                        </style> */}
                    </defs>
                    <path className="a"
                        d="M10,2A3,3,0,0,0,7.184,4H2V6H7.184A3,3,0,1,0,10,2Zm5,2V6h7V4Zm0,5a3,3,0,0,0-2.816,2H2v2H12.184A3,3,0,1,0,15,9Zm5,2v2h2V11ZM7,16a3,3,0,0,0-2.816,2H2v2H4.184A3,3,0,1,0,7,16Zm5,2v2H22V18Z"
                        transform="translate(-2 -2)" />
                </svg>
                </div>
            )}
            
        </header>
    );
}

TasksHeader.propTypes = {
    history: PropTypes.object.isRequired,
};

export default TasksHeader;
