import React from "react";
import "./App.css";
import Dashboard from "./Components/Dashboard/Dashboard";
import Home from "./Components/Home/Home";
import HomeIFrame from "./Components/HomeIFrame/HomeIFrame";
import Accounts from "./Components/Accounts/Accounts";
import LoginPage from "./Components/LoginPage/LoginPage";
import SignupPage from "./Components/SignupPage/SignupPage";
import ForgotPassword from "./Components/ForgotPassword/ForgotPassword";
import ResetPassword from "./Components/ForgotPassword/ResetPassword";
import Payment from "./Components/Payment";
import Crawler from "./Components/Crawler/crawl";
import taskDetail from "./Components/TaskDetails/taskDetails";
import pageDetails from "./Components/PageDetails/pageDetails";
import Tasks from "./Components/Tasks/Tasks";
import Guest from "./Components/Guest";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import { PrivateRoute } from "./Components/PrivateRoute";
import { BrowserRouter, Route } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import EmailSignup from "./Components/EmailSignup/EmailSignup";

var config = require("./Config/config");

class App extends React.Component {
  constructor(props) {
    super(props);
    window.Chargebee.init({
      site: config.chargebee.site,
      publishableKey: config.chargebee.publishableKey
    });
    window.LogRocket.getSessionURL(sessionURL => {
      Sentry.configureScope(scope => {
        scope.setExtra("sessionURL", sessionURL);
      });
    });
  }
  render() {
    return (
      <BrowserRouter>
        <PrivateRoute exact path="/" component={Home} />
        <PrivateRoute exact path="/Home" component={Home} />
        <PrivateRoute exact path="/Accounts" component={Accounts} />
        <PrivateRoute exact path="/crawler" component={Crawler} />

        <PrivateRoute path="/taskDetail/" component={taskDetail} />
        <PrivateRoute path="/pageDetail/" component={pageDetails} />
        <PrivateRoute path="/Tasks/" component={Tasks} />

        <PrivateRoute path="/Dashboard" component={Dashboard} />
        <PrivateRoute path="/payment" component={Payment} />

        <Route path="/login" component={LoginPage} />
        <Route path="/signup" component={SignupPage} />
        <Route path="/EmailSignup/:token" component={EmailSignup} />

        <Route path="/forgot" component={ForgotPassword} />
        <Route path="/reset" component={ResetPassword} />
        
        <Route exact path="/HomeIFrame" component={HomeIFrame} />
        <Route exact path="/guest" component={Guest} />
        <Route exact path="/linkedin" component={LinkedInPopUp} />
      </BrowserRouter>
    );
  }
}
export default App;
