import React, { Component } from 'react';
import close from '../../image/close.svg';
import arrowBlack from '../../image/Arrow-black.svg';
import circle from '../../image/circle.svg';
import iconfinder_check_alt_118699 from "../../image/iconfinder_check_alt_118699@2x.png";

export default class ApplyModal extends Component {
    render() {
        return (
            <div className="container">
                {/* The Modal */}
                <div className="modal fade modal-des" id="applyModal">
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content">
                            {/* Filters */}
                            <div className="filters">
                                {/* Filters/header */}
                                <header className="mob-all-tasks">
                                    <div className="close-button">
                                        <div className="hamburger-container">
                                            <div className="modal-button">
                                                <button type="button" className="btn" data-dismiss="modal">
                                                    <img src={close} width="15px" alt='' />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </header>
                                <div className="main-wrapper">
                                    <section className="mainFiltered-container">
                                        <div className="mainFiltered-inner">
                                            <div className="mainFiltered-all-tag">
                                                All
                                        </div>
                                            <div className="mainFiltered-other-tag">
                                                seo
                                        </div>
                                            <div className="mainFiltered-other-tag">
                                                PPC
                                        </div>
                                            <div className="mainFiltered-other-tag">
                                                Content
                                        </div>
                                            <div className="mainFiltered-other-tag">
                                                Social
                                        </div>
                                        </div>
                                    </section>
                                    <section className="mainFiltered-section-container">
                                        {/* mainFiltered/sub-heading */}
                                        <section className="mainFiltered-head">
                                            1,025 Tasks for godaddy.com
                                   			</section>
                                        {/* mainFiltered/filters details */}
                                        <section className="mainFiltered-div">
                                            <div className="mainFiltered-white-box">
                                                <div className="mainFiltered-right-sign">
                                                    <img src={iconfinder_check_alt_118699} width="23px" alt='' />
                                                </div>
                                                <div className="mainFiltered-white-box-text">
                                                    <span>Multiple H1 tags found</span><br />
                                                    <span className="mainfiltered-sub-tags">21 Pages <img src={circle} width="3px" alt='' /> 3k
                                                    Traffic</span>
                                                </div>
                                                <div className="mainFiltered-white-box-redTag">
                                                    HIGH
                                            </div>
                                                <div className="mainfiltered-white-box-arrow">
                                                    <img src={arrowBlack} alt='' />
                                                </div>
                                            </div>
                                            <div className="mainFiltered-white-box">
                                                <div className="mainFiltered-right-sign">
                                                    <img src={iconfinder_check_alt_118699} width="23px" alt='' />
                                                </div>
                                                <div className="mainFiltered-white-box-text">
                                                    <span>Robot.txt may need some fix</span><br />
                                                    <span className="mainfiltered-sub-tags">21 Pages <img src={circle} width="3px" alt='' /> 3k
                                                		Traffic</span>
                                                </div>
                                                <div className="mainFiltered-white-box-greenTag">
                                                    Medium
                                            		</div>
                                                <div className="mainfiltered-white-box-arrow">
                                                    <img src={arrowBlack} alt='' />
                                                </div>
                                            </div>
                                            <div className="mainFiltered-white-box">
                                                <div className="mainFiltered-right-sign">
                                                    <img src="/img/iconfinder_check_alt_118699@2x.png" width="23px" alt='' />
                                                </div>
                                                <div className="mainFiltered-white-box-text">
                                                    <span>Multiple H1 tags found</span><br />
                                                    <span className="mainfiltered-sub-tags">21 Pages <img src={circle} width="3px" alt='' /> 3k
                                                		Traffic</span>
                                                </div>
                                                <div className="mainFiltered-white-box-redTag">
                                                    HIGH
                                            		</div>
                                                <div className="mainfiltered-white-box-arrow">
                                                    <img src={arrowBlack} alt='' />
                                                </div>
                                            </div>
                                            <div className="mainFiltered-white-box">
                                                <div className="mainFiltered-right-sign">
                                                    <img src={iconfinder_check_alt_118699} width="23px" alt='' />
                                                </div>
                                                <div className="mainFiltered-white-box-text">
                                                    <span>Multiple H1 tags found</span><br />
                                                    <span className="mainfiltered-sub-tags">21 Pages <img src={circle} width="3px" alt='' /> 3k
                                                		Traffic</span>
                                                </div>
                                                <div className="mainFiltered-white-box-redTag">
                                                    HIGH
                                            		</div>
                                                <div className="mainfiltered-white-box-arrow">
                                                    <img src={arrowBlack} alt='' />
                                                </div>
                                            </div>
                                            <div className="mainFiltered-white-box">
                                                <div className="mainFiltered-right-sign">
                                                    <img src={iconfinder_check_alt_118699} width="23px" alt='' />
                                                </div>
                                                <div className="mainFiltered-white-box-text">
                                                    <span>Robot.txt may need some fix</span><br />
                                                    <span className="mainfiltered-sub-tags">21 Pages <img src={circle} width="3px" alt='' /> 3k
                                                		Traffic</span>
                                                </div>
                                                <div className="mainFiltered-white-box-greenTag">
                                                    Medium
                                            		</div>
                                                <div className="mainfiltered-white-box-arrow">
                                                    <img src={arrowBlack} alt='' />
                                                </div>
                                            </div>
                                            <div className="mainFiltered-white-box">
                                                <div className="mainFiltered-right-sign">
                                                    <img src={iconfinder_check_alt_118699} width="23px" alt='' />
                                                </div>
                                                <div className="mainFiltered-white-box-text">
                                                    <span>Multiple H1 tags found</span><br />
                                                    <span className="mainfiltered-sub-tags">21 Pages <img src={circle} width="3px" alt='' /> 3k
                                                		Traffic</span>
                                                </div>
                                                <div className="mainFiltered-white-box-redTag">
                                                    HIGH
                                            		</div>
                                                <div className="mainfiltered-white-box-arrow">
                                                    <img src={arrowBlack} alt='' />
                                                </div>
                                            </div>
                                            <div className="mainFiltered-white-box">
                                                <div className="mainFiltered-right-sign">
                                                    <img src={iconfinder_check_alt_118699} width="23px" alt='' />
                                                </div>
                                                <div className="mainFiltered-white-box-text">
                                                    <span>Robot.txt may need some fix</span><br />
                                                    <span className="mainfiltered-sub-tags">21 Pages <img src={circle} width="3px" alt='' /> 3k
                                            		Traffic</span>
                                                </div>
                                                <div className="mainFiltered-white-box-greenTag">
                                                    Medium
                                            		</div>
                                                <div className="mainfiltered-white-box-arrow">
                                                    <img src={arrowBlack} alt='' />
                                                </div>
                                            </div>
                                            <div className="mainFiltered-white-box">
                                                <div className="mainFiltered-right-sign">
                                                    <img src={iconfinder_check_alt_118699} width="23px" alt='' />
                                                </div>
                                                <div className="mainFiltered-white-box-text">
                                                    <span>Multiple H1 tags found</span><br />
                                                    <span className="mainfiltered-sub-tags">21 Pages <img src={circle} width="3px" alt='' /> 3k
                                                		Traffic</span>
                                                </div>
                                                <div className="mainFiltered-white-box-redTag">
                                                    HIGH
                                            		</div>
                                                <div className="mainfiltered-white-box-arrow">
                                                    <img src={arrowBlack} alt='' />
                                                </div>
                                            </div>
                                        </section>
                                    </section>
                                </div>
                                <button className="mainFiltered-button-container">
                                    <a href="#">2 Filters added</a>
                                </button>
                                <div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
