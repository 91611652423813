import React from 'react';
// import './styles.css'

export default class AppBox extends React.Component {

    render() {
        return (
            <React.Fragment>
                <section className="connected-apps-container">
                    <div className="heading-box">CONNECTED APP</div>
                    <div className="apps-container">
                        {/* {this.props.app.map((item, index) => (
                            <div key={index} className="app-box">
                                <div className="app-image-div">
                                    <img src={item.image} className="app-img" alt=''/>
                                </div>
                                <div className="app-name-div">
                                    <span className="app-name">{item.name}</span>
                                </div>
                            </div>
                        ))
                        } */}
                    </div>
                </section>
            </React.Fragment>
        )
    }
}
