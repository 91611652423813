import axios from "axios";
var config = require("../Config/config");

export const userService = {
  login,
  logout,
  register,
  guest,
  forgot,
  reset,
  loginGoogle,
  loginLinkedin,
  payment,
  EmailSignup,
  UserStatus,
  ValidateMail,
  assignedMail,
};

const URI = config.middleware.url;
var message = undefined;

async function updateGuestProjects(token) {
  const requestOptions = {
    method: "PUT",
    headers: { Authorization: "Bearer " + token },
  };
  var mai_server_host =
    localStorage.getItem("server") !== null
      ? process.env.REACT_APP_MAI_SERVER_HOST.split(".")[0] +
        "." +
        localStorage.getItem("server")
      : process.env.REACT_APP_MAI_SERVER_HOST;

  const url = `${mai_server_host}/api/v1/projects`;
  return fetch(url, requestOptions);
  // return axios.put(url, {}, requestOptions);
}

async function updateGuestTasks() {
  const requestOptions = {
    method: "PUT",
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    body: JSON.stringify({ email: localStorage.getItem("user") }),
  };
  var mai_task_manager_host =
    localStorage.getItem("server") !== null
      ? process.env.REACT_APP_MAI_TASK_MANAGER_HOST.split(".")[0] +
        "." +
        localStorage.getItem("server")
      : process.env.REACT_APP_MAI_TASK_MANAGER_HOST;

  const url = `${mai_task_manager_host}/api/v1/tasks`;
  return fetch(url, requestOptions);
  // axios.put(url, { email: localStorage.getItem("user") }, requestOptions);
}

function forgot(email) {
  var creds = {
    email: email,
  };

  const requestOptions = {
    headers: { "Content-Type": "application/json" },
  };

  return axios
    .post(URI + "/forgot", creds, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function reset(changePasswordId, password) {
  var creds = {
    changePasswordId: changePasswordId,
    password: password,
  };

  const requestOptions = {
    headers: { "Content-Type": "application/json" },
  };

  return axios
    .post(URI + "/reset", creds, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function guest() {
  // const requestOptions = {
  //   method: "GET"
  // };

  // return fetch(URI + "/guest", requestOptions)
  return axios
    .get(URI + "/guest")
    .then(handleResponse)
    .then((data) => {
      // login successful if there's a user in the response
      if (data.token) {
        localStorage.setItem("token", data.token);
        localStorage.setItem("user", "Guest");
        localStorage.setItem("plan", "Free");
        window.LogRocket.startNewSession();
        window.LogRocket.identify(data.email, { plan: "Free" });
      }
      return data.token;
    })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function register(email, password) {
  const user = localStorage.getItem("user");
  if (user === "Guest") {
    var guestToken = localStorage.getItem("token");
  } else {
    var guestToken = null;
  }
  var creds = {
    email: email,
    password: password,
    guestToken: guestToken,
  };

  const requestOptions = {
    // method: "POST",
    headers: { "Content-Type": "application/json" },
    // body: JSON.stringify(creds)
  };

  // return fetch(URI + "/register", requestOptions)
  return axios
    .post(URI + "/register", creds, requestOptions)
    .then(handleResponse)
    .then((data) => {
      // login successful if there's a user in the response
      if (data.token) {
        localStorage.setItem("token", data.token);
        localStorage.setItem("user", email);
        localStorage.setItem("plan", data.plan);
        window.LogRocket.startNewSession();
        window.LogRocket.identify(email, { email, plan: data.plan });
        message = data.message;
      }
      return data.token;
    })
    .then(updateGuestProjects)
    .then(updateGuestTasks)
    .then(() => {
      return Promise.resolve(message);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function payment(email, plan, token) {
  var creds = {
    email: email,
    plan: plan,
    token: token,
    JWT: localStorage.getItem("token"),
  };

  const requestOptions = {
    // method: "POST",
    headers: { "Content-Type": "application/json" },
    // body: JSON.stringify(creds)
  };

  // return fetch(URI + "/payment", requestOptions)
  return axios
    .post(URI + "/payment", creds, requestOptions)
    .then(handleResponse)
    .then((data) => {
      // console.log(data);
      // login successful if there's a user in the response
      if (data.token) {
        localStorage.setItem("token", data.token);
        localStorage.setItem("user", email);
        localStorage.setItem("plan", data.plan);
        window.LogRocket.startNewSession();
        window.LogRocket.identify(email, { email, plan: data.plan });
      }
      return data.token;
    });
}

function login(email, password) {
  const user = localStorage.getItem("user");
  if (user === "Guest") {
    var guestToken = localStorage.getItem("token");
  } else {
    var guestToken = null;
  }
  var creds = {
    email: email,
    password: password,
    guestToken: guestToken,
  };

  const requestOptions = {
    // method: "POST",
    headers: { "Content-Type": "application/json" },
    // body: JSON.stringify(creds)
  };

  // return fetch(URI + "/login", requestOptions)
  return axios
    .post(URI + "/login", creds, requestOptions)
    .then(handleResponse)
    .then((data) => {
      // login successful if there's a user in the response
      if (data.token) {
        localStorage.setItem("token", data.token);
        localStorage.setItem("user", email);
        localStorage.setItem("plan", data.plan);
        window.LogRocket.startNewSession();
        window.LogRocket.identify(email, { email, plan: data.plan });
        message = data.message;
      }
      return data.token;
    })
    .then(updateGuestProjects)
    .then(updateGuestTasks)
    .then(() => {
      return Promise.resolve(message);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function loginGoogle(tokenId) {
  const user = localStorage.getItem("user");
  if (user === "Guest") {
    var guestToken = localStorage.getItem("token");
  } else {
    var guestToken = null;
  }
  var creds = {
    tokenId: tokenId,
    guestToken: guestToken,
  };

  const requestOptions = {
    // method: "POST",
    headers: { "Content-Type": "application/json" },
    // body: JSON.stringify(creds)
  };

  // return fetch(URI + "/google", requestOptions)
  return axios
    .post(URI + "/google", creds, requestOptions)
    .then(handleResponse)
    .then((data) => {
      // login successful if there's a user in the response
      if (data.token) {
        localStorage.setItem("token", data.token);
        localStorage.setItem("plan", data.plan);
        localStorage.setItem("user", data.email);
        window.LogRocket.startNewSession();
        window.LogRocket.identify(data.email, {
          email: data.email,
          plan: data.plan,
        });
        message = data.message;
      }
      return data.token;
    })
    .then(updateGuestProjects)
    .then(updateGuestTasks)
    .then(() => {
      return Promise.resolve(message);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function loginLinkedin(code) {
  const user = localStorage.getItem("user");
  if (user === "Guest") {
    var guestToken = localStorage.getItem("token");
  } else {
    var guestToken = null;
  }
  var creds = {
    code: code,
    guestToken: guestToken,
  };

  const requestOptions = {
    // method: "POST",
    headers: { "Content-Type": "application/json" },
    // body: JSON.stringify(creds)
  };

  // return fetch(URI + "/linkedin", requestOptions)
  return axios
    .post(URI + "/linkedin", creds, requestOptions)
    .then(handleResponse)
    .then((data) => {
      // login successful if there's a user in the response
      if (data.token) {
        localStorage.setItem("token", data.token);
        localStorage.setItem("plan", data.plan);
        localStorage.setItem("user", data.email);
        window.LogRocket.startNewSession();
        window.LogRocket.identify(data.email, {
          email: data.email,
          plan: data.plan,
        });
        message = data.message;
      }
      return data.token;
    })
    .then(updateGuestProjects)
    .then(updateGuestTasks)
    .then(() => {
      return Promise.resolve(message);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("plan");
  localStorage.removeItem("pricing_plan");
}

function handleResponse(response) {
  const data = response.data;
  if (!data.success) {
    const error = data.message || response.statusText || "token not found";
    return Promise.reject(error);
  }
  return data;
}

function EmailSignup(email, type) {
  const requestOptions = {
    headers: { "Content-Type": "application/json" },
  };
  var payload = {
    Email: email,
    Type: type,
  };

  return axios
    .post(
      process.env.REACT_APP_MAI_NOTIFICATION_HOST + "/api/v1/mailVerification",
      payload,
      requestOptions
    )
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function UserStatus(id) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  return axios
    .get(
      process.env.REACT_APP_MAI_NOTIFICATION_HOST + "/api/v1/userInfo/" + id,
      requestOptions
    )
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function ValidateMail(Token) {
  const requestOptions = {
    headers: { "Content-Type": "application/json" },
  };

  var payload = {
    token: Token,
  };

  return axios
    .post(
      process.env.REACT_APP_MAI_NOTIFICATION_HOST +
        "/api/v1/mailVerificationCallBack",
      payload,
      requestOptions
    )
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function assignedMail(payload) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };
  return axios
    .post(
      process.env.REACT_APP_MAI_NOTIFICATION_HOST + "/api/v1/templateMail",
      payload,
      requestOptions
    )
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.resolve(error);
    });
}
