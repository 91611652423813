import React from "react";
import { List, Progress } from "semantic-ui-react";
import axios from "axios";
import _ from "lodash";
import "../assets/css/crawl-progress.css";
import Arrow from "../Components/image/arrow-white.svg";

class CrawlProgress extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rotation: 180,
      open: false,
      toggleButtonText: "View Details",
      discovered: 0,
      crawled: 0,
      fetched: 0,
      analyzed: 0,
      bugs: 0,
      tasks: 0,
      events: [],
      projectId: "",
      isDomainError: props.isDomainError
    };
  }

  intervalId = "";

  componentWillReceiveProps(nextProps) {
    if (nextProps.isDomainError) {
      if (this.intervalId) clearInterval(this.intervalId);
    }
    else {
      if (nextProps.projectId !== this.state.projectId) {
        this.setState({
          rotation: 180,
          open: false,
          toggleButtonText: "View Details",
          discovered: 0,
          crawled: 0,
          fetched: 0,
          analyzed: 0,
          bugs: 0,
          tasks: 0,
          events: [],
          projectId: nextProps.projectId,
          isDomainError: nextProps.isDomainError
        });

        if (this.intervalId) clearInterval(this.intervalId);
        nextProps.chartDataHandler({
          crawledCount: undefined,
          analysedCount: undefined,
          fetchedCount: undefined,
          discoveredCount: undefined
        });

        // console.log(nextProps)
        var mai_server_host = localStorage.getItem("server") !== null ?
          process.env.REACT_APP_MAI_SERVER_HOST.split('.')[0] + '.' + localStorage.getItem("server") :
          process.env.REACT_APP_MAI_SERVER_HOST

        axios
          .get(
            `${mai_server_host}/api/v1/projects/${nextProps.projectId}`,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
              }
            }
          )
          .then(res => {
            // console.log(res.data);
            this.getAllEvents(nextProps.projectId, res.data.domain);
            this.getNewEvents(nextProps.projectId, res.data.domain);
          })
          .catch(err => {
            console.log(err.message);
            const a = err.message.search("401");
            if (a > 0) {
              // alert("Session expired!! Click OK to reload!!");
              localStorage.removeItem("token");
              window.location.replace("/");
            }
          }); //token expired);
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const changedProps = _.reduce(
      this.props,
      (result, value, key) =>
        _.isEqual(value, nextProps[key]) ? result : result.concat(key),
      []
    );
    const changedState = _.reduce(
      this.state,
      (result, value, key) =>
        _.isEqual(value, nextState[key]) ? result : result.concat(key),
      []
    );
    return changedProps.length > 0 || changedState.length > 0;
  }

  getNewEvents = (projectId, hostname) => {
    if (this.intervalId) clearInterval(this.intervalId);
    this.intervalId = setInterval(() => {
      this.getAllEvents(projectId, hostname)
    }, 3000);
  };

  getAllEvents = (projectId, hostname) => {
    const { events } = this.state;

    var mai_server_host = localStorage.getItem("server") !== null ?
      process.env.REACT_APP_MAI_SERVER_HOST.split('.')[0] + '.' + localStorage.getItem("server") :
      process.env.REACT_APP_MAI_SERVER_HOST

    axios
      .get(
        `${mai_server_host}/api/v1/projects/pages/${hostname}/all`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          }
        }
      )
      .then(res => {
        // console.log(res.data);
        // this.setState(res.data);

        // if (res.data.discovered - this.state.discovered > 0)
        //   events.unshift(
        //     `${res.data.discovered -
        //       this.state.discovered} new URLs discovered`
        //   );
        // if (res.data.crawled - this.state.crawled > 0)
        //   events.unshift(
        //     `${res.data.crawled - this.state.crawled} new URLs crawled`
        //   );
        // if (res.data.fetched - this.state.fetched > 0)
        //   events.unshift(
        //     `${res.data.fetched - this.state.fetched} new URLs fetched`
        //   );
        // if (res.data.analyzed - this.state.analyzed > 0)
        //   events.unshift(
        //     `${res.data.analyzed - this.state.analyzed} new URLs analyzed`
        //   );

        this.props.chartDataHandler({
          crawledCount: res.data.crawled,
          fetchedCount: res.data.fetched,
          analysedCount: res.data.analyzed,
          discoveredCount: res.data.discovered
        });
      })
      .catch(err => {
        console.log(err.message);
        const a = err.message.search("401");
        if (a > 0) {
          // alert("Session expired!! Click OK to reload!!");
          localStorage.removeItem("token");
          window.location.replace("/");
        }
      });

    // var mai_seo_server_host = localStorage.getItem("server") !== null ?
    //   process.env.REACT_APP_MAI_SEO_SERVER_HOST.split('.')[0] + '.' + localStorage.getItem("server") :
    //   process.env.REACT_APP_MAI_SEO_SERVER_HOST

    // axios
    //   .get(`${mai_seo_server_host}/api/v1/seo/${projectId}`)
    //   .then(res => {
    //     // console.log(`Bugs - ${res.data.length}`);
    //     if (res.data.length - this.state.bugs > 0)
    //       events.unshift(
    //         `${res.data.length - this.state.bugs} new bugs found`
    //       );
    //     this.setState({ bugs: res.data.length });
    //   })
    //   .catch(err => console.log(err));

    // if (this.props.uniqueCount - this.state.tasks > 0)
    //   events.unshift(
    //     `${this.props.uniqueCount - this.state.tasks} new tasks created`
    //   );

    // this.setState({ events, tasks: this.props.uniqueCount });
  }

  // toggleProgress = () => {
  //   const { open } = this.state;
  //   if (open) {
  //     this.setState({
  //       rotation: 180,
  //       open: false,
  //       toggleButtonText: "View Details"
  //     });
  //     document.getElementsByClassName("crawl-progress-root")[0].style.bottom =
  //       "-400px";
  //     document.getElementsByClassName(
  //       "crawl-progress-container"
  //     )[0].style.height = "75px";
  //     const crawlProgressBars = document.getElementsByClassName(
  //       "crawl-progress-bars"
  //     )[0];
  //     crawlProgressBars.style.display = "flex";
  //     crawlProgressBars.getElementsByClassName(
  //       "ui progress"
  //     )[0].style.position = "absolute";
  //     crawlProgressBars.getElementsByClassName(
  //       "ui progress"
  //     )[0].style.marginBottom = "0px";
  //     crawlProgressBars
  //       .getElementsByClassName("ui progress")[0]
  //       .getElementsByClassName("label")[0].style.display = "none";
  //     crawlProgressBars.getElementsByClassName(
  //       "ui progress"
  //     )[1].style.position = "absolute";
  //     crawlProgressBars.getElementsByClassName(
  //       "ui progress"
  //     )[1].style.marginBottom = "0px";
  //     crawlProgressBars
  //       .getElementsByClassName("ui progress")[1]
  //       .getElementsByClassName("label")[0].style.display = "none";
  //     crawlProgressBars.getElementsByClassName(
  //       "ui progress"
  //     )[2].style.position = "absolute";
  //     crawlProgressBars
  //       .getElementsByClassName("ui progress")[2]
  //       .getElementsByClassName("label")[0].style.display = "none";
  //     // crawlProgressBars.getElementsByClassName(
  //     //   "ui progress"
  //     // )[3].style.position = "absolute";
  //     // crawlProgressBars
  //     //   .getElementsByClassName("ui progress")[3]
  //     //   .getElementsByClassName("label")[0].style.display = "none";
  //   } else {
  //     this.setState({ rotation: 0, open: true, toggleButtonText: "Close" });
  //     document.getElementsByClassName("crawl-progress-root")[0].style.bottom =
  //       "0px";
  //     document.getElementsByClassName(
  //       "crawl-progress-container"
  //     )[0].style.height = "120px";
  //     const crawlProgressBars = document.getElementsByClassName(
  //       "crawl-progress-bars"
  //     )[0];
  //     crawlProgressBars.style.display = "block";
  //     crawlProgressBars.getElementsByClassName(
  //       "ui progress"
  //     )[0].style.position = "relative";
  //     crawlProgressBars.getElementsByClassName(
  //       "ui progress"
  //     )[0].style.marginBottom = "3px";
  //     crawlProgressBars
  //       .getElementsByClassName("ui progress")[0]
  //       .getElementsByClassName("label")[0].style.display = "block";
  //     crawlProgressBars.getElementsByClassName(
  //       "ui progress"
  //     )[1].style.position = "relative";
  //     crawlProgressBars.getElementsByClassName(
  //       "ui progress"
  //     )[1].style.marginBottom = "3px";
  //     crawlProgressBars
  //       .getElementsByClassName("ui progress")[1]
  //       .getElementsByClassName("label")[0].style.display = "block";
  //     crawlProgressBars.getElementsByClassName(
  //       "ui progress"
  //     )[2].style.position = "relative";
  //     crawlProgressBars
  //       .getElementsByClassName("ui progress")[2]
  //       .getElementsByClassName("label")[0].style.display = "block";
  //     // crawlProgressBars.getElementsByClassName(
  //     //   "ui progress"
  //     // )[3].style.position = "relative";
  //     // crawlProgressBars
  //     //   .getElementsByClassName("ui progress")[3]
  //     //   .getElementsByClassName("label")[0].style.display = "block";
  //   }
  // };

  render() {
    // const {
    //   rotation,
    //   toggleButtonText,
    //   // discovered,
    //   crawled,
    //   fetched,
    //   analyzed,
    //   events
    // } = this.state;
    return (
      <React.Fragment>
        {/*<div className="crawl-progress-root">
        <div className="crawl-progress-container">
          <div className="crawl-progress-header">
            <div className="crawl-progress-header-progress">Progress</div>
            <div
              className="crawl-progress-header-toggle"
              onClick={this.toggleProgress}
            >
              <img
                style={{
                  transform: `rotate(${rotation}deg)`,
                  transition: "200ms linear all",
                  marginRight: 5
                }}
                src={Arrow}
                alt="toggle"
                width="15"
                height="15"
              />
              {toggleButtonText}
            </div>
          </div>
          <div className="crawl-progress-bars">
            {/* <Progress
              percent={100}
              style={{
                margin: 0,
                borderRadius: 0,
                width: "100%",
                position: "absolute"
              }}
            >
              {discovered} Discovered
            </Progress> //
            <Progress
              percent={100}
              style={{
                margin: 0,
                borderRadius: 0,
                width: "100%",
                position: "absolute"
              }}
            >
              {crawled} Crawled
            </Progress>
            <Progress
              value={fetched}
              total={crawled}
              color="red"
              style={{
                margin: 0,
                borderRadius: 0,
                width: "100%",
                position: "absolute"
              }}
            >
              {fetched} Fetched
            </Progress>
            <Progress
              value={analyzed}
              total={crawled}
              color="green"
              style={{
                margin: 0,
                borderRadius: 0,
                width: "100%",
                position: "absolute"
              }}
            >
              {analyzed} Analyzed
            </Progress>
          </div>
        </div>
        <div className="crawl-progress-body">
          <List divided relaxed>
            {events.map((e, i) => (
              <List.Item key={i}>
                <List.Content>{e}</List.Content>
              </List.Item>
            ))}
          </List>
        </div>
      </div>*/}
      </React.Fragment>
    );
  }
}

export default CrawlProgress;
