import React, { Component } from "react";
import { userService } from "../../Services";
import TextField from "@material-ui/core/TextField";
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Header from "../Header/Header.js";
import Footer from "../Footer/Footer";
import LogoIcon from "../LogoIcon";
import "../../App.css";
import "./PasswordStyle.css";
import envelopeImg from "../image/envelope.svg";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      loading: false,
      error: false,
      errorMsg: "",
      success: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    const { email } = this.state;
    if (!/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
      this.setState({
        error: true,
        errorMsg: email + " is not a valid email address",
        loading: false
      })
      setTimeout(() => {
        this.setState({
          error: false,
          errorMsg: ""
        })
      }, 5000)
      return
    };

    this.setState({ loading: true });
    userService
      .forgot(email)
      .then(() => { this.setState({ success: true }) })
      .catch(error => {
        let message = ""
        try {
          message = error.response.data.message
        } catch {
          message = "Error connecting to authentication server"
        }
        this.setState({
          error: true,
          errorMsg: message,
          loading: false
        })
        setTimeout(() => {
          this.setState({
            error: false,
            errorMsg: ""
          })
        }, 5000)
      });
  }

  handleFailure = error => {
    console.log(error);
  };

  render() {
    const { email, loading, error, success } = this.state;

    return (
      
      <div className="login-wrap" style={{background:"white"}}>
        {/* <header className="login-header">
          <a href="/home">
            <div className="mai-logo">
              <LogoIcon height="auto" width="150px" type="img" />
            </div>
          </a>
        </header> */}
        
        <Header hideUserMenu={true} />
        <div className="desktop-view">
        <div className="login-main-back">
          <section className="mai-heading">
            <LogoIcon width="176px" type="img" />
          </section>
          {!success && <div><section className="mai-login-forgot-n">
          <div className="mai-login-link-n">
              <a
                onClick={() => {
                  window.location.replace("/login");
                }}
              >
                Log In
              </a>
            </div>&nbsp;
            <div className="mai-forgot-link-n">
              <a
                style={{ color: "#E7EAF0" }}
                onClick={() => {
                  window.location.replace("/forgot");
                }}
              >
                Forgot Password</a>
            </div>
          </section>
            <form onSubmit={this.handleSubmit}>
              <section className="mai-login-input">
                {this.state.error ?
                  <div className="error-text">
                    <FormHelperText error id="component-error-text">{this.state.errorMsg}</FormHelperText>
                  </div>
                  :
                  ''
                }
                <div className="mai-login-competitors-container">
                  <div className="login-left-icon">
                    <img src={envelopeImg} />
                  </div>
                  <div className="login-right-icon">
                    <div className="web-address-input-container">
                      <TextField
                        required
                        fullWidth
                        id="email"
                        label="E-mail"
                        name="email"
                        value={email}
                        autoComplete="email"
                        onChange={this.handleChange}
                        autoFocus
                        // error={error && true}
                      />
                    </div>
                  </div>
                </div>
              </section>
              {/* ..........login/login-button.............. */}
              <section className="login-button">
                <button
                  type="submit"
                  className="mai-login-btn-compare"
                  disabled={loading}
                >
                  {!loading ? 'RESET' : 'Processing...'}
                  <div className="right-icon-container">
                    {
                      this.state.loading ?
                        <CircularProgress className="spinner" size={18} /> :
                        <KeyboardArrowRightIcon />
                    }
                  </div>
                </button>
              </section>
            </form>
          </div> ||
            <section className="success-content">
              <p style={{ marginBottom: 50 }}> Password reset link sent succesfully! </p>
              <button className="nav-btn" onClick={() => { window.location.replace('/home') }} > Home</button><br />
              <button className="nav-btn" onClick={() => { window.location.replace('/login') }}> Login </button>
            </section>
          }<section className="swipe-back">
          </section>
          <Footer />
        </div>
        </div>
        <div className="mob-view">
        <div className="mai-login-main-back">
          <section className="mai-heading">
            <LogoIcon width="176px" type="img" />
          </section>
          {!success && <div><section className="mai-login-forgot-n">
          <div className="mai-login-link-n">
              <a
                onClick={() => {
                  window.location.replace("/login");
                }}
              >
                Log In
              </a>
            </div>&nbsp;
            <div className="mai-forgot-link-n">
              <a
                style={{ color: "#E7EAF0" }}
                onClick={() => {
                  window.location.replace("/forgot");
                }}
              >
                Forgot Password</a>
            </div>
          </section>
            <form onSubmit={this.handleSubmit}>
              <section className="mai-login-input">
                {this.state.error ?
                  <div className="error-text">
                    <FormHelperText error id="component-error-text">{this.state.errorMsg}</FormHelperText>
                  </div>
                  :
                  ''
                }
                <div className="mai-login-competitors-container">
                  <div className="login-left-icon">
                    <img src={envelopeImg} />
                  </div>
                  <div className="login-right-icon">
                    <div className="web-address-input-container">
                      <TextField
                        required
                        fullWidth
                        id="email"
                        label="E-mail"
                        name="email"
                        value={email}
                        autoComplete="email"
                        onChange={this.handleChange}
                        autoFocus
                        // error={error && true}
                      />
                    </div>
                  </div>
                </div>
              </section>
              {/* ..........login/login-button.............. */}
              <section className="login-button">
                <button
                  type="submit"
                  className="mai-login-btn-compare"
                  disabled={loading}
                >
                  {!loading ? 'RESET' : 'Processing...'}
                  <div className="right-icon-container">
                    {
                      this.state.loading ?
                        <CircularProgress className="spinner" size={18} /> :
                        <KeyboardArrowRightIcon />
                    }
                  </div>
                </button>
              </section>
            </form>
          </div> ||
            <section className="success-content">
              <p style={{ marginBottom: 50 }}> Password reset link sent succesfully! </p>
              <button className="nav-btn" onClick={() => { window.location.replace('/home') }} > Home</button><br />
              <button className="nav-btn" onClick={() => { window.location.replace('/login') }}> Login </button>
            </section>
          }<section className="swipe-back">
          </section>
          <Footer />
        </div>

        </div>
      </div>
    );
  }
}

export default ForgotPassword;
