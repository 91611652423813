import React from "react";
// import CircleChecked from "@material-ui/icons/CheckCircleOutline";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
// import axios from "axios";
import "./Tasks/style.css";
// import iconfinder_check_alt_118699 from "./image/iconfinder_check_alt_118699@2x.png";
// import menu from "./image/menu-(2).svg";
import Avatar from "react-avatar";
// import arrow from "./image/Arrow-black.svg";
import { Checkbox } from "@material-ui/core";
import { Link } from "react-router-dom";
//import checkIcon from "./image/iconfinder_check_alt_118699@2x.png";
// import circle from "./image/circle.svg";
// import ContextMenu from "../Components/ContextMenu";
// import Button from "@material-ui/core/Button";
// import Menu from "@material-ui/core/Menu";
// import MenuItem from "@material-ui/core/MenuItem";
// import Dialog from "@material-ui/core/Dialog";
// import DialogTitle from "@material-ui/core/DialogTitle";
// import AssignContainer from "./TaskDetails/AssignContainer";

function TaskComp(props) {
  let taskElementClassName = `task-significance-${props.taskSignificance}`;

  let pad = (d) => {
    if (d) return d < 10 ? "0" + d.toString() : d.toString();
  };

  function handleMarkTaskButton() {
    if (!props.taskAssignees) return;
    return (
      <div style={{marginLeft: 42}}>
        {props.taskAssignees.slice(0, 2).map((email, index) => (
          <Avatar
            name={email}
            round={true}
            size={35}
            key={index}
            style={{ margin: "0 20px 0 -42px", zIndex: -index }}
          />
        ))}
        {props.taskAssignees.length > 2 ? (
          <Avatar
            name={`+ ${props.taskAssignees.length - 2}`}
            round={true}
            size={35}
            key="4"
            color="#737373"
            style={{ margin: "0 20px 0 -42px", zIndex: 10 }}
          />
        ) : (
          ""
        )}
      </div>
    );
  }

  const { checked, error_code, user, projectId } = props;

  const handleChange = (e) => {
    const { onCheckBoxChange } = props;
    onCheckBoxChange(e, error_code);
  };

  let toLink = `/taskdetail?projectId=${projectId}&task=${error_code}`;

  if (user === "Guest") {
    toLink = "/login";
  }

  return (
    <div style={{ width: "100%" }}>
      <div className="desktop-view">
        {/* <AssignContainer
          assigned={(assignees) => {
            setAssignees(assignees);
          }}
        /> */}
        <div className="task-container">
          {user !== "Guest" && (
            <div className="checkbox-bulk">
              <Checkbox
                icon={<CircleUnchecked />}
                checkedIcon={<CircleCheckedFilled />}
                checked={checked}
                onChange={(e) => handleChange(e, error_code)}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </div>
          )}
          <div style={{ flex: "auto", width: "100%" }}>
            <Link to={toLink}>
              <div className="task-container2">
                <div className="task-name">
                  <span>{props.taskName}</span>
                </div>

                <div className={taskElementClassName}>
                  {props.taskSignificance}
                </div>

                <div className="pages-affected">
                  <span>
                    <b>{props.pageAffected} </b>PAGES
                  </span>
                </div>

                <div className={`task-status-${props.taskStatus}`}>
                  <span>
                    <b>
                      {props.taskStatus === "Completed"
                        ? "Done"
                        : props.taskStatus}{" "}
                    </b>
                  </span>
                </div>

                <div className="traffic-affected">
                  {/* <span><b>{props.trafficAffected} </b>TRAFFIC AFFECTED</span> */}
                </div>

                {/* <div className="message-counter-box">
                  <div className="message">
                    <svg xmlns="http://www.w3.org/2000/svg" width="33.58" height="26.576"
                        viewBox="0 0 33.58 26.576">
                        <g id="Group_2121" data-name="Group 2121" transform="translate(-12434.65 1285.35)">
                            <path id="Path_245" data-name="Path 245"
                                d="M20.55,32.452l.03.04,3.15,4.35a.4.4,0,0,0,.54-.02l3.17-4.32.04-.05h11.8a1.161,1.161,0,0,0,1.16-1.16V12.222a1.161,1.161,0,0,0-1.16-1.16H8.72a1.161,1.161,0,0,0-1.16,1.16v19.07a1.161,1.161,0,0,0,1.16,1.16Z"
                                transform="translate(12427.44 -1296.062)" fill="#fff" />
                            <text id="_1" data-name="1" transform="translate(12455 -1271)" fill="#303030"
                                fontSize="12" fontFamily="OpenSans-Semibold, Open Sans" fontWeight="600">
                                <tspan x="0" y="0">{'#'}</tspan>
                            </text>
                            <path id="Path_246" data-name="Path 246"
                                d="M22.688,25.1a4.8,4.8,0,1,1,1.537-1.449l.2,1.729L22.688,25.1"
                                transform="translate(12427.44 -1296.062)" fill="#ff6969" />
                            <path id="Path_247" data-name="Path 247"
                                d="M16.908,20.213a3.1,3.1,0,0,1,3.063-2.788"
                                transform="translate(12427.44 -1296.062)" fill="none" stroke="#303030"
                                strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10"
                                strokeWidth="0.7" />
                            <path id="Path_248" data-name="Path 248"
                                d="M22.688,25.1a4.8,4.8,0,1,1,1.537-1.449l.2,1.729L22.688,25.1"
                                transform="translate(12427.44 -1296.062)" fill="none" stroke="#303030"
                                strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10"
                                strokeWidth="0.7" />
                            <path id="Path_250" data-name="Path 250"
                                d="M20.55,32.452l.03.04,3.15,4.35a.4.4,0,0,0,.54-.02l3.17-4.32.04-.05h11.8a1.161,1.161,0,0,0,1.16-1.16V12.222a1.161,1.161,0,0,0-1.16-1.16H8.72a1.161,1.161,0,0,0-1.16,1.16v19.07a1.161,1.161,0,0,0,1.16,1.16Z"
                                transform="translate(12427.44 -1296.062)" fill="none" stroke="#303030"
                                strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10"
                                strokeWidth="0.7" />
                        </g>
                    </svg>
                  </div>
                </div> */}

                <div className="assigned-container">
                  {/* <div className="assignedperson-one">
                </div>

                <div className="assignedperson-rest">
                </div> */}
                  {handleMarkTaskButton()}
                </div>
              </div>
            </Link>
          </div>
          <div style={{ flex: "10%" }}>
            <div className="taskCompContextNId">
              <div className="task-more">
                {/* <ContextMenu
                  style={{ width: "20px" }}
                  status={status}
                  error_code={props.error_code}
                  projectId={props.projectId}
                /> */}
              </div>
              <div className="task-id">
                {/* #{pad(props.taskId)} */}
                <span>
                  {"#"}
                  {pad(props.taskId)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mob-view">
        <div className="tasks-white-box">
          {/*<div class="checkbox">
            {user !== "Guest" && (
              <Checkbox
                icon={<CircleUnchecked />}
                checkedIcon={<CircleCheckedFilled />}
                checked={checked}
                onChange={e => handleChange(e, error_code)}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            )}
            </div>*/}
          <div style={{ flex: "auto" }}>
            <Link to={toLink}>
              <div style={{ color: "black" }}>
                <b
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    fontFamily: "IBM Plex Sans",
                  }}
                >
                  {props.taskName}
                </b>
                {/*<img src={menu} alt="" style={{ width: "24px", float:"right", marginRight:"8px", marginTop:"8px"}} />*/}
              </div>

              <div className="sub-tags">
                <span className="mob-pages-affected">{props.pageAffected}</span>
                <span
                  className={`task-status-${props.taskStatus}`}
                  style={{
                    paddingRight: "7px",
                    marginLeft: "10px",
                    paddingLeft: "7px",
                    borderRadius: "5pt",
                    fontSize: "15px",
                  }}
                >
                  <b>
                    {props.taskStatus === "Completed"
                      ? "Done"
                      : props.taskStatus}{" "}
                  </b>
                </span>

                <span
                  className={taskElementClassName}
                  style={{
                    paddingRight: "7px",
                    marginLeft: "10px",
                    paddingLeft: "7px",
                    borderRadius: "5pt",
                    fontSize: "15px",
                  }}
                >
                  {props.taskSignificance}
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TaskComp;
