import React from 'react';
import './SideDrawer.css';
import _ from "lodash";
import { userService } from "../../Services";
import Avatar from "react-avatar";
import LogoIcon from "../LogoIcon";
import brandLogo from "../../img/brand.png";
import '../SideDrawer/SideDrawer.css';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Paper from '@material-ui/core/Paper';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import Header from '../Header/Header.css';
import Footer from '../Footer/Footer';
import { positions } from '@material-ui/system';
class sideDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          projects: props.projects,
          setHeaderSelection: props.setHeaderSelection,
          projectId: props.projectId,
          user: localStorage.getItem("user"),
          anchorEl: null,
          anchorDomainEl: null
        };
      }
    
      componentWillReceiveProps(props) {
        this.setState({
          projectId: props.projectId,
          projects: props.projects,
          setHeaderSelection: props.setHeaderSelection
        });
        if (props.projectId && props.projects) {
          const selectedProject = props.projects.find(project => {
            return project._id === props.projectId;
          });
          if (selectedProject)
            this.setState({ selectedDomain: selectedProject.domain });
        }
      }
    
      selectDropDown = index => {
        this.setState({ selectedDomain: this.state.projects[index].domain });
        if (this.state.projects[index].server) {
          localStorage.setItem('server', this.state.projects[index].server)
        } else {
          localStorage.removeItem('server')
        }
        window.history.pushState(
          "Project",
          "Tasks",
          "/tasks?projectId=" + this.state.projects[index]._id
        );
        this.state.setHeaderSelection(this.state.projects[index]._id);
        window.history.go()
      };
    
      handleMenu = () => { this.setState({ anchorEl: null }) }
      handleDomainMenu = () => { this.setState({ anchorDomainEl: null }) }


    render() {
        const ITEM_HEIGHT = 48;
        const projects = this.state.projects;
        const selectedDomain = this.state.selectedDomain;
        let domainList = [];
        if (projects) {
          domainList = projects.map(project => {
            return (
              project.domain
            );
          });
        }
        return (
        <nav className="side-drawer">
          
            <div class="moblogo"><a href="/"><img className="mob-logo-sidebar" src={brandLogo} alt="logo" /></a></div>
            <div></div>
            
            <div className="smart-codes-dropdown" style={{marginLeft:"38px"}}>
                  {domainList.length > 0 ? (
                    <div>
                      <li class="mob-logout">Select Project</li>

                      <ButtonGroup variant="contained" aria-label="split button">
                        <Button
                          aria-controls="long-menu"
                          style={{ textTransform: 'none' }}
                          variant="outlined"
                          disableElevation
                          disabled>
                          {window.location.pathname === '/' || window.location.pathname === '/home'
                            ? 'Select a project'
                            : selectedDomain}
                        </Button>
                        <Button
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          variant="outlined"
                          size="small"
                          disableElevation
                          onClick={event => {
                            this.setState({ anchorDomainEl: event.currentTarget })
                          }}>
                          <ArrowDropDownIcon />
                        </Button>
                      </ButtonGroup>
                      <Menu
                        id="long-menu"
                        anchorEl={this.state.anchorDomainEl}
                        keepMounted
                        open={Boolean(this.state.anchorDomainEl)}
                        onClose={this.handleDomainMenu}
                        PaperProps={{
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: 200,
                          },
                        }}
                      >
                        {projects.map((project, index) => (
                          <MenuItem key={index} selected={project.domain === selectedDomain}
                            onClick={() => {
                              this.selectDropDown(index)
                              this.handleDomainMenu()
                            }}>
                            {/* <ListItemIcon>
                            </ListItemIcon> */}
                            <FolderOpenIcon fontSize="small" /> &nbsp;
                            <Typography variant="inherit" noWrap> {project.domain}</Typography>
                          </MenuItem>
                        ))}
                      </Menu>
                    </div>
                  ) : (
                      ""
                    )}
                  {/*{domainList.length > 0 ? (
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle top-dropdown"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {selectedDomain}
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <ul>{domainList}</ul>
                    </div>
                  </div>
                  ) : (
                    ""
                  )}*/}
                </div>
                <li>
                    <div className="login-btn" style={{float:"left"}}>
                    {localStorage.getItem("user") === "Guest" && (
                    <button style={{marginLeft:"20px"}}
                      onClick={() => {
                        window.history.pushState(
                          {
                            from: { pathname: window.location.pathname }
                          },
                          "",
                          "/login"
                        );
                        window.history.go();
                      }}
                    >
                      Login
                    </button>
                    )}
                    
                    
                    {localStorage.getItem("user") != "Guest" && (
                    <div>
                       <ul>
                        <li></li>
                        
                        
                        <li class="mob-logout" selected onClick={() => {
                          window.history.pushState("","",`/tasks`);
                          window.history.go();
                        }}> Dashboard
                        </li>
                        

                        <li class="mob-logout" selected onClick={() => {
                          window.history.pushState("","",`/Accounts`);
                          window.history.go();
                        }}>
                          Account
                        </li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li class="mob-logout" style={{position:"bottom"}} selected onClick={() => {
                          window.location.replace("https://sitehike.smarter.codes/support");
                        }}>
                        Get Support
                        </li>

                        <li class="mob-logout" selected onClick={() => {
                          userService.logout();
                          window.location.replace("/");
                        }}>
                         Logout
                        </li>
                        </ul> 
                        
                        
                      
                    </div>
                  )}
                  
                </div>
                </li>
                <li>
                    <div className="register-btn" style={{float:"left"}}>
                    {localStorage.getItem("user") === "Guest" ?
                        <button
                        onClick={() => {
                            window.history.pushState(
                            {
                                from: { pathname: window.location.pathname }
                            },
                            "",
                            "/signup"
                            );
                            window.history.go();
                        }}
                        >
                        Sign up
                    </button> : ''
                    }
                    </div>
                </li>
                <div className="mai-pre-foot-copy" style={{marginTop:"150px"}}>
                    &copy; 2020 <a href="https://epochaltechnologies.com/" rel="nofollow noopener noreferrer" target="_blank">Epochal Technologies</a>.<br/> All rights reserved. | <a href="https://sitehike.smarter.codes/terms" rel="nofollow">T&amp;C</a> &amp; <a href="https://sitehike.smarter.codes/privacy-policy" rel="nofollow">Privacy</a>
                </div>
            
        </nav>  
        );
    } 
}

export default sideDrawer;

