import React from 'react';
import PropTypes from 'prop-types';
import { AreaChart, Area } from 'recharts';
import MiddleEllipsis from "react-middle-ellipsis";
import _ from 'lodash';
import path257 from '../image/Path 257.svg';
import menu from "../image/menu-(2).svg";
import magnifyingGlass from '../image/magnifying-glass.svg';
import Content from './Content';
import Testimonial from './Testimonial';

export default class TaskDetailList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showSearchPageInput: false,
      taskList: [],
      fullTaskList: [],
      isTaskListEmpty:false,
      showMore:false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.taskList) {
      if((!this.state.showMore && nextProps.taskList.length <= 5) || this.state.showMore){
        this.setState({ taskList: nextProps.taskList });
      }
      else{
        this.setState({taskList: nextProps.taskList.slice(0,5)})
      }
    } else {
      this.setState({taskList: []})
    }
  }

  togglePageSearch = () => {
    const { showSearchPageInput } = this.state;
    this.setState({ showSearchPageInput: !showSearchPageInput });
  }

  searchPages = (e) => {
    const { taskList } = this.props;
    const results = _.filter(taskList, (item) => {
      return item.url.indexOf(e.target.value) > -1;
    });
    if(results.length==0)
    this.setState({ fullTaskList: taskList, taskList: results, isTaskListEmpty: true });
    else
    this.setState({ fullTaskList: taskList, taskList: results, isTaskListEmpty: false });
  }

  getTasks = () => {
    const { fullTaskList } = this.state;
    const { getTaskDetailsWrapper, pageCount } = this.props;
    if (fullTaskList.length === pageCount) {
      this.setState({
        showSearchPageInput: false,
        taskList: fullTaskList,
        fullTaskList: [],
      });
    }
    else {
      getTaskDetailsWrapper();
      this.setState({ showSearchPageInput: false,showMore:true });
    }
  }

  showLessTasks = () => {
    const {taskList}=this.state;
    this.setState({ taskList:taskList.slice(0, 5) , showMore:false});
  }

  render() {
    const { showSearchPageInput, taskList, isTaskListEmpty } = this.state;
    const { errorData, pageCount, projectId, history, domain, expert } = this.props;

    const taskListSorted = _.sortBy(taskList, t => t.url.replace(/\/$/, '').substr(t.url.replace(/\/$/, '').lastIndexOf('/') + 1).length);

    return (
      <div>
      <div className="desktop-view">
      <React.Fragment>
        <Content errorData={errorData} />
        {expert ? <Testimonial expert={expert} /> : null }
        <section className="affectedPage">
          <div className="affectedPage-head">
            <span>AFFECTED PAGES</span>
            <button className="plain-button" onClick={this.togglePageSearch}><img src={magnifyingGlass} alt="search" /></button>
          </div>
          {showSearchPageInput ?
            <section className="searchPages">
              <input autoFocus type="text" placeholder="Search for pages" className="write-comments" onChange={this.searchPages} />
            </section>
            :
            null
          }
          <div className="affectedPage-bar"></div>
          {taskListSorted.map((item, index) => (
            <div className="task-details-container" key={index}>
              <div className="task-item">
                <a href={item.url} target="_blank" rel="noopener noreferrer"><img src={path257} width="12px" alt='' /></a>
              </div>
              <div
                className="task-detail-name"
                title={item.url.replace(/\/$/, '')}
                onClick={() => history.push(`/pageDetail?projectId=${projectId}&page=${encodeURIComponent(item.url)}`)}
              >
                <MiddleEllipsis>
                  <span>{new URL(item.url).origin.split("//")[1] == domain || new URL(item.url).origin.split("//")[1].split(".")[0] == "www" ? item.url.replace(new URL(item.url).origin, '') : item.url.split("//")[1]}</span>   
                </MiddleEllipsis>
              </div>
              <div className="task-detail-significance">
                {/* <AreaChart
                  width={90}
                  height={35}
                  data={item.graphData}
                  margin={{
                    top: 10, right: 10, left: 0, bottom: 0,
                  }}
                >
                  <Area type="monotone" dataKey="uv" stroke="none" fill="#FF6969" />
                </AreaChart> */}
              </div>
              <div className="pages-affected">
                {/* <span><b>{item.visitCount} </b>VISITS</span> */}
              </div>
              <div className="detail-task-count">
                <span><b>{item.error_codes.length} </b>TASKS</span>
              </div>
              <div className="message-counter-box">
                <div className="message">
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="33.58" height="26.576" viewBox="0 0 33.58 26.576">
                    <g id="Group_2121" data-name="Group 2121" transform="translate(-12434.65 1285.35)">
                      <path id="Path_245" data-name="Path 245" d="M20.55,32.452l.03.04,3.15,4.35a.4.4,0,0,0,.54-.02l3.17-4.32.04-.05h11.8a1.161,1.161,0,0,0,1.16-1.16V12.222a1.161,1.161,0,0,0-1.16-1.16H8.72a1.161,1.161,0,0,0-1.16,1.16v19.07a1.161,1.161,0,0,0,1.16,1.16Z" transform="translate(12427.44 -1296.062)" fill="#fff" />
                      <text id="_1" data-name={1} transform="translate(12455 -1271)" fill="#303030" fontSize={12} fontFamily="OpenSans-Semibold, Open Sans" fontWeight={600}>
                        <tspan x={0} y={0}>{item.messageCount}</tspan>
                      </text>
                      <path id="Path_246" data-name="Path 246" d="M22.688,25.1a4.8,4.8,0,1,1,1.537-1.449l.2,1.729L22.688,25.1" transform="translate(12427.44 -1296.062)" fill="#ff6969" />
                      <path id="Path_247" data-name="Path 247" d="M16.908,20.213a3.1,3.1,0,0,1,3.063-2.788" transform="translate(12427.44 -1296.062)" fill="none" stroke="#303030" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="0.7">
                      </path>
                      <path id="Path_248" data-name="Path 248" d="M22.688,25.1a4.8,4.8,0,1,1,1.537-1.449l.2,1.729L22.688,25.1" transform="translate(12427.44 -1296.062)" fill="none" stroke="#303030" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="0.7">
                      </path>
                      <path id="Path_250" data-name="Path 250" d="M20.55,32.452l.03.04,3.15,4.35a.4.4,0,0,0,.54-.02l3.17-4.32.04-.05h11.8a1.161,1.161,0,0,0,1.16-1.16V12.222a1.161,1.161,0,0,0-1.16-1.16H8.72a1.161,1.161,0,0,0-1.16,1.16v19.07a1.161,1.161,0,0,0,1.16,1.16Z" transform="translate(12427.44 -1296.062)" fill="none" stroke="#303030" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth="0.7">
                      </path>
                    </g>
                  </svg> */}
                </div>
              </div>
              <div className="assigned-container">
                {/* <div className="assignedperson-one">
                </div>
                <div className="assignedperson-rest">
                </div> */}
              </div>
              
            </div>
          ))
          }
          {isTaskListEmpty?<div className="see-all-task">No Records Found</div>
          :
          pageCount !== taskList.length ? <div className="see-all-task" onClick={this.getTasks}>CLICK TO SEE ALL TASKS</div> : 
          taskList.length<6?null:<div className="see-all-task" onClick={this.showLessTasks}>Show Less</div>}
        </section>
      </React.Fragment >
      </div>
      <div className="mob-view">
      <React.Fragment>
        {/*{expert ? <Testimonial expert={expert} /> : null }*/}
        <section className="affectedPage">
          <div className="affectedPage-head">
            <span>Affected pages</span>
            <button className="plain-button" onClick={this.togglePageSearch}><img src={magnifyingGlass} alt="search" /></button>
          </div>
          {showSearchPageInput ?
            <section className="searchPages">
              <input autoFocus type="text" placeholder="Search for pages" className="write-comments" onChange={this.searchPages} />
            </section>
            :
            null
          }
          <div className="affectedPage-bar"></div>
          {taskListSorted.map((item, index) => (
            <div className="task-details-container" key={index}>
              <div className="task-item">
                <a href={item.url} target="_blank" rel="noopener noreferrer"><img src={path257} width="12px" alt='' /></a>
              </div>
              <div
                className="task-detail-name"
                title={item.url.replace(/\/$/, '')}
                onClick={() => history.push(`/pageDetail?projectId=${projectId}&page=${encodeURIComponent(item.url)}`)}
              >
                <MiddleEllipsis>
                  <span>{new URL(item.url).origin.split("//")[1] == domain || new URL(item.url).origin.split("//")[1].split(".")[0] == "www" ? item.url.replace(new URL(item.url).origin, '') : item.url.split("//")[1]}</span>   
                </MiddleEllipsis>
                
              </div>
              
              {/*<div className="assigned-container">
                 <div className="assignedperson-one">
                </div>
                <div className="assignedperson-rest">
                </div> 
              </div>
              <div className="task-more"></div>*/}
            </div>
          ))
          }
          {isTaskListEmpty?<div className="see-all-task">No Records Found</div>
          :
          pageCount !== taskList.length ? <div className="see-all-task" onClick={this.getTasks}>CLICK TO SEE ALL TASKS</div> : 
          taskList.length<6?null:<div className="see-all-task" onClick={this.showLessTasks}>Show Less</div>}
        </section>
      </React.Fragment >
      </div>
      </div>
    )
  }
}

TaskDetailList.propTypes = {
  history: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired,
  taskList: PropTypes.array.isRequired,
  errorData: PropTypes.object.isRequired,
  pageCount: PropTypes.number.isRequired,
  getTaskDetailsWrapper: PropTypes.func.isRequired,
};
