var config = {};

config.chargebee = {};
config.chargebee.publishableKey = "test_M1cu7wCCbZvxeFWOyJ261R773cdiosByxc";
config.chargebee.site = "marketingai-test";

config.middleware = {};
config.middleware.url = "https://auth.sitehike.smarter.codes";

config.auth = {};
config.auth.googleClientId =
  "208077006267-jmb55mceeio3js85vdrihigdtk12c3co.apps.googleusercontent.com";
config.auth.linkedinClientId = "81hair87coh8gr";
config.auth.linkedinRedirectUri = "https://sitehike.smarter.codes/linkedin";

module.exports = config;
