import React,{ useState } from 'react';
import { Button, Chip } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axios from "axios";
import ProjectDetailTable from "./ProjectDetailTable"

var config = require("../../Config/config");

/*const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: '55%',
        minWidth: 800
    },
    heading: {
        flexBasis: '20%',
        flexShrink: 0,
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    chip: {
      flexBasis: '65%',
      flexShrink: 0,
  },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
      },
}));*/

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));


export default function ProjectAccordian(props) {
    const classes = useStyles();
    const [plans, setPlans] = useState({})
    const {
        projects
    } = props;

    var openChargebeeSelfServePortal=(subInfo) =>{
       
        const cbInstance = window.Chargebee.getInstance();
        console.log(subInfo)
        const postData = {
          email: subInfo.email
        };
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        };
      
        let REACT_APP_SERVER_HOST = config.middleware.url;
        // eslint-disable-next-line prefer-destructuring
        if (!REACT_APP_SERVER_HOST) REACT_APP_SERVER_HOST = process.env.REACT_APP_MAI_SERVER_HOST;
      
        const subscriptionId = subInfo.subs_id
      
        let forwardOptions = null;
        if (subscriptionId) {
          forwardOptions = {
            sectionType: window.Chargebee.getPortalSections().SUBSCRIPTION_DETAILS,
            params: {
              subscriptionId
            }
          };
        }
        cbInstance.setPortalSession(() =>
          axios.post(`${REACT_APP_SERVER_HOST}/createPortalSession`, postData, { headers })
          .then((response) => response.data.portal_session)
        );
        const cbPortalInstance = cbInstance.createChargebeePortal();
        cbPortalInstance.open({
          loaded: () => {
            console.log('loaded');
            // Optional
            // called when chargebee portal is loaded
          },
          close: () => {
            console.log('closed');
          },
          visit: (sectionName) => {
            // Optional
            // called whenever the customer navigates across different sections in portal
            console.log(sectionName);
          },
          paymentSourceAdd: () => {
            // Optional
            // called whenever a new payment source is added in portal
          },
          paymentSourceUpdate: () => {
            // Optional
            // called whenever a payment source is updated in portal
          },
          paymentSourceRemove: () => {
            // Optional
            // called whenever a payment source is removed in portal.
          },
          subscriptionChanged: (data) => {
            // Optional
            // called whenever a subscription is changed
            // data.subscription.id will give you the subscription id
            // Make sure you whitelist your domain in the checkout settings page
            console.log(data);
            axios
              .get(`${config.middleware.url}/getSubscriptionById?subscriptionId=${data.subscription.id}`)
              .then((res)=> {
                plans[data.subscription.id] = res.data.subscription.plan_id
                setPlans({...plans})
              })
              .catch((err)=>{console.log(err)})
              
          },
          subscriptionCustomFieldsChanged: (data) => {
            // Optional
            // called whenever a subscription custom fields are changed
            // data.subscription.id will give you the subscription id
            // Make sure you whitelist your domain in the checkout settings page
            console.log(data);
          },
          subscriptionCancelled: (data) => {
            // Optional
            // called when a subscription is cancelled
            // data.subscription.id will give you the subscription id
            // Make sure you whitelist your domain in the checkout settings page
            console.log(data);
          },
          subscriptionReactivated: (data) => {
            console.log(data);
            // Optional
            // called when a cancelled subscription is reactivated
            // data.subscription.id will give you the subscription id
            // Make sure you whitelist your domain in the checkout settings page
          }
        }, forwardOptions);
      } 

    return (
        <div className={classes.root}>
            {projects.map(item => {
                plans[item.subs_id] === undefined && 
                  axios
                  .get(`${config.middleware.url}/getSubscriptionById?subscriptionId=${item.subs_id}`)
                  .then((res)=> {
                    plans[item.subs_id] = res.data.subscription.plan_id
                    setPlans({...plans})
                  })
                  .catch((err)=>{console.log(err)})

                return (
                  <>
                  <div className="desktop-view">
                  <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading} inline><h3>{item.domain}</h3></Typography>
                            <Typography className={classes.chip} inline style={{marginLeft:"20%", position:"absolute"}}>
                              <Chip label={plans[item.subs_id]} />
                            </Typography>
                            <Typography className={classes.secondaryHeading} style={{marginLeft:"80%", position:"absolute"}}>
                                <Button variant="contained" color="secondary" onClick={()=>openChargebeeSelfServePortal(item)}>
                                    Manage
                                </Button>
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                              <ProjectDetailTable data={item} plan={plans[item.subs_id]}/>
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </div>
                  <div className = "mob-view">
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading} inline><h4>{item.domain}</h4></Typography>
                            <Typography className={classes.chip} inline style={{marginLeft:"32%", position:"absolute"}}>
                              <Chip label={plans[item.subs_id]} />
                            </Typography>
                            <Typography className={classes.secondaryHeading} style={{marginLeft:"50%", position:"absolute"}}>
                                <Button variant="contained" color="secondary" onClick={()=>openChargebeeSelfServePortal(item)}>
                                    Manage
                                </Button>
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                              <ProjectDetailTable data={item} plan={plans[item.subs_id]}/>
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    
                    </div>
                    </>
                )
            })}
        </div>
    );
}

