import React, { Component } from "react";

import iconfinderAnalyticsImg from '../image/iconfinder_f-analytics2_128_308020.png';
import iconfinderAdwordsImg from '../image/iconfinder_9_Adwords_4518967@2x.png';

import ConnectedApp from "./ConnectedApp";


class ConnecedAppsDesktop extends Component {

    render() {
        return (
            < div className="dash-rightConnect" >
                <div className="heading-box">
                    CONNECTED APP
                </div>
                <div className="apps-container">

                    <ConnectedApp img={iconfinderAnalyticsImg} name="Google Analytics" />

                    <ConnectedApp img={iconfinderAdwordsImg} name="Google Adwords" />

                    <ConnectedApp img={iconfinderAdwordsImg} name="Google Adwords" />
                </div>
            </div >
        );
    }
}

export default ConnecedAppsDesktop;