import React, { Component } from "react";
import "./style.css";
import axios from "axios";
import _ from "lodash";

import PropTypes from "prop-types";
import imageiconfinder_9_Adwords_4518967 from "../image/iconfinder_9_Adwords_4518967@2x.png";
import icon308020 from "../image/iconfinder_f-analytics2_128_308020.png";
import TasksMobile from "./Mobile/TasksMobile";
import TaskDesktop from "./Desktop/TaskDesktop";
import CrawlProgress from "../crawlProgress";
// import io from "socket.io-client";

class Tasks extends Component {
  constructor(props) {
    super(props);
    let projectId = new URL(document.location).searchParams.get("projectId");
    this.state = {
      projectId: projectId ? projectId : "",
      projects: [],
      filtersApplied: {},
      queryString: projectId ? `project_id=${projectId}&status=Open&` : "", //Default calls for open status
      uniqueCount: 0,
      totalCount: 0,
      crawledCount: undefined,
      analysedCount: undefined,
      fetchedCount: undefined,
      discoveredCount: undefined,
      tasksCount: undefined,
      tasks: [],
      depth: 2,
      // socket: null,
      isDomainError: false,
      errorMessage: "",
      filters: {
        tag: [
          { name: "All", selected: true },
          { name: "SEO", selected: false, count: 0 },
          { name: "PPC", selected: false, count: 0 },
          { name: "Social", selected: false, count: 0 },
          { name: "Crawl", selected: false, count: 0 },
        ],
        priority: [
          { name: "All", selected: true },
          { name: "High", selected: false, count: 0 },
          { name: "Medium", selected: false, count: 0 },
          { name: "Low", selected: false, count: 0 },
        ],
        status: [
          { name: "All", selected: false },
          { name: "Open", selected: true, count: 0 },
          { name: "Completed", selected: false, count: 0 },
        ],
        assigned: [
          { name: "All", selected: true },
          { name: "Me", selected: false, count: 0 },
          { name: "Unassigned", selected: false, count: 0 },
        ],
        url: "",
        taskCount: 0,
      },
    };
  }

  intervalId = "";
  // emailIntervalId = "";
  tasksIntervalId = null;

  componentDidMount() {
    // this.emailNotification();
    this.getProjects();
    this.getTasksList(this.state.queryString);
    this.getTaskFilters(this.state.queryString);
    this.subscribeToTasks();
    if (this.state.projectId) this.getProjectStatus(this.state.projectId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isDomainError !== prevState.isDomainError) {
      if (this.state.isDomainError) {
        if (this.tasksIntervalId) clearInterval(this.tasksIntervalId);
        this.getProjectStatus(this.state.projectId);
      } else {
        if (this.tasksIntervalId) clearInterval(this.tasksIntervalId);
        this.getTasksList(this.state.queryString);
        this.getTaskFilters(this.state.queryString);
        this.subscribeToTasks();
        this.getProjectStatus(this.state.projectId);
      }
    }

    if (this.state.queryString !== prevState.queryString) {
      if (this.tasksIntervalId) clearInterval(this.tasksIntervalId);
      let filters = this.state.filters;
      Object.keys(filters).forEach((filterKey) => {
        if (
          filterKey !== "tag" &&
          filters[filterKey] &&
          Array.isArray(filters[filterKey])
        ) {
          filters[filterKey].forEach((filter) => {
            if (filter.count) {
              filter.count = 0;
            }
          });
        }
      });
      this.setState({ filters: filters, tasks: [], totalCount: undefined });
      this.getTasksList(this.state.queryString);
      this.getTaskFilters(this.state.queryString);
      this.subscribeToTasks();
    }
    if (this.state.projectId !== prevState.projectId) {
      if (this.tasksIntervalId) clearInterval(this.tasksIntervalId);
      let filters = this.state.filters;
      Object.keys(filters).forEach((filterKey) => {
        if (filters[filterKey] && Array.isArray(filters[filterKey])) {
          filters[filterKey].forEach((filter) => {
            if (filter.count) {
              filter.count = 0;
            }
          });
        }
      });
      this.setState({ filters: filters, tasks: [] });
      this.getProjectStatus(this.state.projectId);
      this.getTasksList(this.state.queryString);
      this.getTaskFilters(this.state.queryString);
      this.subscribeToTasks();
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
    // clearInterval(this.emailIntervalId);
    clearInterval(this.tasksIntervalId);
  }

  // emailNotification = () => {
  //   let step = 10;
  //   let time = 10;
  //   let send = 30;
  //   if (this.emailIntervalId) clearInterval(this.emailIntervalId);
  //   this.emailIntervalId = setInterval(() => {
  //     const { hostname, protocol } = window.location;
  //     const { tasks, taskCount, projectId, projects } = this.state;
  //     let stepRate = (tasks.length - taskCount) / step;
  //     if (((stepRate == 0) & (time % send == 0)) | (send == 30)) {
  //       send = 120;
  //       let a = tasks.map(task => {
  //         if (!task.emailSent) {
  //           let data = {
  //             name: task.title,
  //             link:
  //               protocol +
  //               "//" +
  //               hostname +
  //               "/taskdetail?projectId=" +
  //               projectId +
  //               "&task=" +
  //               task.error_code
  //           };
  //           return data;
  //         }
  //       });
  //       a = a.filter(function(el) {
  //         return el != null;
  //       });
  //       if (a.length) {
  //         let tasks = a.slice(0, 10);
  //         let email = localStorage.getItem("user");
  //         let subject = "New Task Created";
  //         let projectUrl =
  //           protocol + "//" + hostname + "/tasks?projectId=" + projectId;
  //         let projectsUrl = protocol + "//" + hostname + "/tasks";
  //         let project = projects.filter(project => {
  //           return project._id === projectId;
  //         });
  //         let domain = project[0].domain;

  //         let body = {
  //           toList: email,
  //           templateId: "createTask",
  //           templateData: {
  //             tasks,
  //             subject,
  //             domain,
  //             projectId,
  //             projectUrl,
  //             projectsUrl
  //           }
  //         };
  //         axios
  //           .post(
  //             `${process.env.REACT_APP_MAI_NOTIFICATION}/api/v1/templateMail`,
  //             body,
  //             {
  //               headers: {
  //                 Authorization: "Bearer " + localStorage.getItem("token")
  //               }
  //             }
  //           )
  //           .then(success => {
  //             console.log(success.data);
  //             let error_codes = tasks.map(task => {
  //               return task.link.slice(task.link.lastIndexOf("=") + 1);
  //             });
  //             let tasksNotified = this.state.tasks.map(task => {
  //               if (error_codes.includes(task.error_code)) {
  //                 task.emailSent = true;
  //               }
  //               return task;
  //             });
  //             this.setState({ tasks: tasksNotified });
  //             axios
  //               .put(
  //                 `${process.env.REACT_APP_MAI_TASK_MANAGER_HOST}/api/v1/tasks?project_id=${projectId}`,
  //                 { error_codes, emailSent: true },
  //                 {
  //                   headers: {
  //                     Authorization: "Bearer " + localStorage.getItem("token")
  //                   }
  //                 }
  //               )
  //               .then(res => {
  //                 console.log(res.data);
  //               })
  //               .catch(err => {
  //                 console.log(err.message);
  //               });
  //           })
  //           .catch(err => {
  //             // console.log(err.message);
  //             // const a = err.message.search("401");
  //             // if (a > 0) {
  //             //   // alert("Session expired!! Click OK to reload!!");
  //             //   localStorage.removeItem("token");
  //             //   window.location.replace("/");
  //             // } //token expired
  //             console.log(err);
  //           });
  //       }
  //     }
  //     this.setState({ taskCount: tasks.length });
  //     time = time + step;
  //   }, 10000);
  // };

  getProjectStatus = (projectId) => {
    const projectStatus = () => {
      var mai_server_host =
        localStorage.getItem("server") !== null
          ? process.env.REACT_APP_MAI_SERVER_HOST.split(".")[0] +
            "." +
            localStorage.getItem("server")
          : process.env.REACT_APP_MAI_SERVER_HOST;

      axios
        .get(`${mai_server_host}/api/v1/projects/${projectId}/status`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          if (res.data.status === "error") {
            console.log(res.data.message);
          }
          // const isDomainError = res.data.status === "error";
          // let errorMessage = "";
          // if (res.data.message === "robots.txt")
          //   errorMessage = "Crawler was blocked by robots.txt";
          // else if (res.data.message === "crawler_blocked")
          //   errorMessage = "Crawler was blocked by the website";
          // else if (res.data.message === "generic_error")
          //   errorMessage = "Some unexpected error occurred";
          // this.setState({ isDomainError, errorMessage });
        })
        .catch((err) => {
          if (err.response.status == 401) {
            console.log("session expired");
            this.redirectHome("Session expired, please login again!");
          } else {
            console.log(err);
          }
        }); //token expired);
    };
    projectStatus();
    if (this.intervalId) clearInterval(this.intervalId);
    this.intervalId = setInterval(projectStatus, 5000);
  };

  setFiltersApplied = (filtersApplied, filters) => {
    //todo refactor setFiltersApplied
    const { status } = this.state.filters;
    let queryString = "";
    if (!("status" in filtersApplied) && status[1].selected === true) {
      queryString = `status=Open&`;
    }
    Object.keys(filtersApplied).forEach((key) => {
      queryString += `${key}=${filtersApplied[key]}&`;
    });
    queryString += `project_id=${this.state.projectId}`;
    this.setState({ filtersApplied });
    this.setState({ queryString });
    this.setState({ filters });
    // alert(JSON.stringify( filters))
  };

  setHeaderSelection = (projectId) => {
    //todo refactor setHeaderSelection
    let queryString = "";
    Object.keys(this.state.filtersApplied).forEach((key) => {
      queryString += `${key}=${this.state.filtersApplied[key]}&`;
    });
    queryString += `project_id=${projectId}`;
    this.setState({
      projectId,
      queryString,
      isDomainError: false,
      errorMessage: "",
    });
    this.getProjectStatus(projectId);
  };

  getTasksList = async (queryString) => {
    // this.setState({
    //   tasks: [],
    //   totalCount: undefined,
    //   uniqueCount: 0
    // });
    const { projectId } = this.state;
    var mai_task_manager_host =
      localStorage.getItem("server") !== null
        ? process.env.REACT_APP_MAI_TASK_MANAGER_HOST.split(".")[0] +
          "." +
          localStorage.getItem("server")
        : process.env.REACT_APP_MAI_TASK_MANAGER_HOST;

    if (projectId) {
      try {
        let tasks = await axios.get(
          `${mai_task_manager_host}/api/v1/tasks?${queryString}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        this.setState({
          tasks: tasks.data.data.result,
          totalCount: tasks.data.data.totalCount,
          uniqueCount: tasks.data.data.uniqueCount,
        });
        if (tasks.data.data.uniqueCount > 0) {
          clearInterval(this.intervalId);
          this.setState({ isDomainError: false, errorMessage: "" });
        }
      } catch (err) {
        if (err.response.status == 401) {
          console.log("session expired");
          this.redirectHome("Session expired, please login again!");
        } else {
          console.log(err);
        }
      }
    }
  };

  getProjects = async () => {
    try {
      let projects = await axios.get(
        `${process.env.REACT_APP_MAI_SERVER_HOST}/api/v1/projects`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );
      if (projects.data.length) {
        this.setState({
          projects: projects.data,
        });
      }
      if (!this.state.projectId) {
        const projectId = projects.data[projects.data.length - 1]._id;
        const queryString = this.state.queryString
          ? this.state.queryString
          : `project_id=${projectId}`;
        window.history.pushState("Project", "Tasks", "/tasks?" + queryString);
        this.setState({ projectId, queryString });
        this.getTasksList(queryString);
        this.getTaskFilters(queryString);
        this.getProjectStatus(projectId);
      }
    } catch (err) {
      if (err.response.status == 401) {
        console.log("session expired");
        this.redirectHome("Session expired, please login again!");
      } else {
        console.log(err);
      }
    } //token expired);
  };

  getTaskFilters = async (queryString) => {
    var filters = this.state.filters;
    var mai_task_manager_host =
      localStorage.getItem("server") !== null
        ? process.env.REACT_APP_MAI_TASK_MANAGER_HOST.split(".")[0] +
          "." +
          localStorage.getItem("server")
        : process.env.REACT_APP_MAI_TASK_MANAGER_HOST;

    let url = `${mai_task_manager_host}/api/v1/tasks/filters?${queryString}`;

    const { projectId } = this.state;
    if (projectId) {
      try {
        let axiosFilters = await axios.get(url, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        axiosFilters = axiosFilters.data.data.result;
        Object.keys(filters).forEach((filterKey) => {
          if (filters[filterKey] && Array.isArray(filters[filterKey])) {
            filters[filterKey].forEach((filter) => {
              if (filter.name !== "All") filter.count = 0;
              if (axiosFilters[filterKey]) {
                if (filterKey == "assigned") {
                  let email = localStorage.getItem("user");
                  if (email !== "Guest") {
                    Array.prototype.contains = function (element) {
                      return this.indexOf(element) > -1;
                    };
                    axiosFilters["assigned"].forEach((axiosElement) => {
                      if (axiosElement._id.length > 0) {
                        filters["assigned"].find((filter, index) => {
                          if (filter.name === "Me") {
                            let count = 0;
                            axiosFilters["assigned"].forEach((axiosElement) => {
                              if (axiosElement._id.contains(email)) {
                                count += axiosElement.count;
                              }
                            });
                            filters["assigned"][index].count = count;
                          } else if (filter.name === email) {
                            filters["assigned"].splice(index, 1);
                          }
                        });
                        if (email) {
                          for (let e of axiosElement._id) {
                            let count = 0;
                            let inFilters = filters["assigned"].find(
                              (filter) => filter.name == e
                            );
                            if (!inFilters && e != email) {
                              axiosFilters["assigned"].forEach(
                                (axiosElement) => {
                                  if (axiosElement._id.contains(e)) {
                                    count += axiosElement.count;
                                  }
                                }
                              );
                              filters["assigned"].push({
                                name: e,
                                selected: false,
                                count: count,
                              });
                              continue;
                            }
                            axiosFilters["assigned"].forEach((axiosElement) => {
                              if (axiosElement._id.contains(e)) {
                                count += axiosElement.count;
                              }
                            });
                            filters["assigned"].find((filter) => {
                              if (filter.name == e) filter.count = count;
                            });
                          }
                        }
                      } else if (axiosElement._id.length == 0) {
                        filters["assigned"].find((filter, index) => {
                          if (filter.name === "Unassigned") {
                            filters["assigned"][index].count =
                              axiosElement.count;
                            return true;
                          }
                        });
                      }
                    });
                  }
                } else {
                  axiosFilters[filterKey].forEach((axiosElement) => {
                    // alert(JSON.stringify(axiosElement))
                    // alert(JSON.stringify(filter))
                    if (axiosElement._id) {
                      if (
                        axiosElement._id.toLowerCase() ===
                        filter.name.toLowerCase()
                      ) {
                        filter.count = axiosElement.count;
                      }
                    }
                  });
                }
              }
            });
          }
        });
        this.setState({
          filters: filters,
          tasksCount: axiosFilters.tasksCount,
        });
      } catch (err) {
        // alert(err);
        if (err.response.status == 401) {
          console.log("session expired");
          this.redirectHome("Session expired, please login again!");
        } else {
          console.log(err);
        }
      }
    }
  };

  chartDataHandler = (data) => {
    this.setState({
      crawledCount: data.crawledCount,
      analysedCount: data.analysedCount,
      fetchedCount: data.fetchedCount,
      discoveredCount: data.discoveredCount,
    });
    // console.log(data);
  };

  // repetative code to handle session expiry

  redirectHome = (errorMessage) => {
    //localStorage.removeItem("token")
    window.history.pushState(
      {
        message: errorMessage,
      },
      "",
      "/home"
    );
    window.history.go();
  };

  render() {
    const { history } = this.props;
    const app = [
      { name: "Google Analytics", image: icon308020 },
      { name: "Google Adwards", image: imageiconfinder_9_Adwords_4518967 },
      { name: "Google Adwards", image: imageiconfinder_9_Adwords_4518967 },
      { name: "Google Analytics", image: icon308020 },
    ];
    return (
      <React.Fragment>
        {/* -----------------------Desktop View------------------------ */}
        <TaskDesktop
          history={history}
          projectId={this.state.projectId}
          projects={this.state.projects}
          uniqueCount={this.state.uniqueCount}
          totalCount={this.state.totalCount}
          tasks={this.state.tasks}
          setHeaderSelection={this.setHeaderSelection}
          setFiltersApplied={this.setFiltersApplied}
          filters={this.state.filters}
          isDomainError={this.state.isDomainError}
          errorMessage={this.state.errorMessage}
          crawledCount={this.state.crawledCount}
          fetchedCount={this.state.fetchedCount}
          analysedCount={this.state.analysedCount}
          discoveredCount={this.state.discoveredCount}
          tasksCount={this.state.tasksCount}
        />
        {/* -------------------Mobile View-------------------------- */}
        <TasksMobile
          app={app}
          history={history}
          projectId={this.state.projectId}
          projects={this.state.projects}
          uniqueCount={this.state.uniqueCount}
          totalCount={this.state.totalCount}
          tasks={this.state.tasks}
          setHeaderSelection={this.setHeaderSelection}
          setFiltersApplied={this.setFiltersApplied}
          filters={this.state.filters}
          isDomainError={this.state.isDomainError}
          errorMessage={this.state.errorMessage}
          crawledCount={this.state.crawledCount}
          fetchedCount={this.state.fetchedCount}
          analysedCount={this.state.analysedCount}
          discoveredCount={this.state.discoveredCount}
          tasksCount={this.state.tasksCount}
        />
        {this.state.projectId ? (
          <CrawlProgress
            isDomainError={this.state.isDomainError}
            projectId={this.state.projectId}
            chartDataHandler={this.chartDataHandler}
            uniqueCount={this.state.uniqueCount}
          />
        ) : null}
      </React.Fragment>
    );
  }

  subscribeToTasks = () => {
    if (this.tasksIntervalId) clearInterval(this.tasksIntervalId);
    this.tasksIntervalId = setInterval(() => {
      const queryString = this.state.queryString;
      this.getTaskFilters(queryString);
      this.getTasksList(queryString);
    }, 2000);
  };
  // subscribeToTasks = domain => {
  //   var descending = true;
  //   if (!this.state.socket) {
  //     let socket = io.connect(process.env.REACT_APP_MAI_TASK_MANAGER_HOST, {
  //       rememberTransport: false,
  //       transports: ["websocket"]
  //     });
  //     socket.on("connect", data => {
  //       socket.emit("join", "Client connected");
  //     });
  //     socket.on("tasks", newTask => {
  //       const tasks = this.state.tasks;
  //       // console.log(newTask, Date())

  //       if (newTask.project_id === this.state.projectId) {
  //         let taskIndex = tasks.findIndex(
  //           atask => atask.title === newTask.title
  //         );
  //         // console.log(taskIndex);
  //         let stateFilters = this.state.filters;
  //         Object.keys(newTask).forEach(property => {
  //           if (stateFilters[property]) {
  //             if (!(this.state.filtersApplied.tag) ||
  //               (this.state.filtersApplied.tag &&
  //                 (this.state.filtersApplied.tag.toLowerCase() ===
  //                   newTask.tag.toLowerCase() || property === "tag"))) {
  //               stateFilters[property].map(filterElement => {
  //                 if (
  //                   newTask[property] &&
  //                   filterElement.name.toLowerCase() ===
  //                   newTask[property].toLowerCase()
  //                 ) {
  //                   filterElement.count = filterElement.count + 1;
  //                 }
  //               });
  //             }
  //           }
  //         });
  //         // console.log(this.state.filtersApplied, 'filtersapplied');
  //         let addTask = true;
  //         Object.keys(this.state.filtersApplied).forEach(filterKey => {
  //           // console.log(newTask[filterKey].toLowerCase(), this.state.filtersApplied[filterKey].toLowerCase())
  //           if (
  //             newTask[filterKey] &&
  //             newTask[filterKey].toLowerCase() !==
  //             this.state.filtersApplied[filterKey].toLowerCase()
  //           ) {
  //             addTask = false;
  //           }
  //         });
  //         if (addTask) {
  //           if (taskIndex !== -1) {
  //             tasks[taskIndex] = newTask;
  //           } else {
  //             // console.log('New task')
  //             tasks.push(newTask);
  //             this.setState({ totalCount: this.state.totalCount + 1 });
  //           }
  //           this.setState({ tasks });
  //           this.setState({ uniqueCount: this.state.uniqueCount + 1 });
  //         }
  //         this.setState({ filters: stateFilters });
  //       }
  //     });
  //     this.setState({ socket });
  //   }
  // };
}

Tasks.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Tasks;
