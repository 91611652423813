import React, { PureComponent } from "react";
import _ from "lodash";

import ReactMinimalPieChart from 'react-minimal-pie-chart';
// import Chip from '@material-ui/core/Chip';

export default class MinimalPieChart extends PureComponent {
  render() {
    const priority = _.cloneDeep(this.props.chartData);
    const all = _.cloneDeep(this.props.all)
    // const parentWidth = this.props.width;
    
    const colors = {
      High: '#515c6f',
      Medium: '#ff6969',
      Low: '#bf8f8f'
    }
    const shades = {
      SEO: '#515c6f',
      Social: '#ff6969',
      Crawl: '#bf8f8f',
      PPC: '#bf9980'
    }
    const data = Object.keys(priority)
    .filter(key => priority[key] !== 0)
    .map(key => ({
      title: key,
      value: priority[key],
      color: colors[key]
    })
    );
    const totalDataValue = data.reduce((a, b) => a + b.value, 0)
    
    // const data2 = Object.keys(priority)
    //   .filter(tag => (priority[tag].name !== "All" && priority[tag].count !== 0))
    //   .map(tag => ({
    //     title: priority[tag].name,
    //     value: priority[tag].count,
    //     color: colors[priority[tag].name]
    //   }));
    const data2 = Object.keys(priority).map(typed => {
      return {
        title: typed,
        value: Object.values(priority[typed]).reduce((a, b) => a + b, 0),
        color: shades[typed]
      }
    }).filter(entry => entry.value !== 0);
    const totalData2Value = data2.reduce((a, b) => a + b.value, 0)
    
    const pieType = {
      filled: {
        radius: 27,
        lineWidth: 90,
        labelPosition: 110
      },
      donut: {
        radius: 40,
        lineWidth: 20,
        labelPosition: 70
      }
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <ReactMinimalPieChart
          data={all ? data2 : data}
          animate={true}
          animationDuration={1500}
          animationEasing="ease-out"
          cx={50}
          cy={50}
          label={({ data, dataIndex }) =>
            `${Math.round(data[dataIndex].percentage)}% (${all ? data[dataIndex].title : data[dataIndex].title.slice(0, 1)})`
          }
          labelPosition={pieType[this.props.type].labelPosition}
          labelStyle={{
            fontFamily: 'sans-serif',
            fontSize: '4px',
            fill: '#3e3e3e'
          }}
          lengthAngle={360}
          lineWidth={pieType[this.props.type].lineWidth}
          onClick={undefined}
          onMouseOut={undefined}
          onMouseOver={undefined}
          paddingAngle={1}
          radius={pieType[this.props.type].radius}
          rounded={false}
          startAngle={0}
          viewBoxSize={[
            90,
            80
          ]}
          style={{ height: 300 }}
        />
          <React.Fragment>
            <span style={{ fontSize: '14px', fontWeight: 'bold' }}>{`${this.props.all ? totalData2Value : totalDataValue} ${this.props.title}`}</span>
            <span style={{ fontSize: '11px' }}>Broken by {this.props.all ? 'type' : 'priority'}</span>
          </React.Fragment>
      </div>
    );
  }
}
