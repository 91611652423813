import React from "react";
import { Grid, Loader } from "semantic-ui-react";
import "./style.css";
// import CustomActiveShapePieChart from "./CustomActiveShapePieChart";
// import SimplePieChart from "./SimplePieChart";
import MinimalPieChart from "./MinimalPieChart";
import _ from "lodash";
import NumericLabel from "react-pretty-numbers";

class Visualizations extends React.Component {
  render() {
    const filters = _.cloneDeep(this.props.filters);
    const chartData = _.cloneDeep(this.props.chartData);
    const { crawledCount, analysedCount, fetchedCount, tasksCount, discoveredCount } = this.props.textData;
   
    const checkEmptyChart = data => {
      return _.sum(_.toArray(data)) !== 0;
    };

    const checkEmptyCustomChart = data => {
      return _.sum(
        _.toArray(
          _.forEach(
            _.cloneDeep(data),
            function (v, i, d) {
              d[i] = _.sum(_.toArray(v))
            }
          )
        )
      ) !== 0;
    };

    const numberOption = {
      shortFormat: true,
      shortFormatMinValue: 10000,
      shortFormatPrecision: 1,
      justification: "C"
    };

    return (
      <>
      <div className="desktop-view">
      <Grid divided="vertically">
        <Grid.Row columns={4} className="dashboard-text">
          {/* <Grid.Column>
            <div className="single-stat">
              <p className="single-stat-value" style={{ color: "#ff6969" }}>
                {crawledCount != undefined ? (
                  <NumericLabel params={numberOption}>
                    {crawledCount}
                  </NumericLabel>
                ) : (
                    <Loader active inline />
                  )}
              </p>
              <p className="single-stat-label">Pages Fetch Limit</p>
            </div>
          </Grid.Column> */}
          <Grid.Column>
            <div className="single-stat">
              <p className="single-stat-value" style={{ color: "#ff6969" }}>
                {discoveredCount != undefined ? (
                  <NumericLabel params={numberOption}>
                    {discoveredCount}
                  </NumericLabel>
                ) : (
                    <Loader active inline />
                  )}
              </p>
              <p className="single-stat-label">Pages Discovered</p>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className="single-stat">
              <p className="single-stat-value" style={{ color: "#ff6969" }}>
                {fetchedCount != undefined ? (
                  <NumericLabel params={numberOption}>
                    {fetchedCount}
                  </NumericLabel>
                ) : (
                    <Loader active inline />
                  )}
              </p>
              <p className="single-stat-label">Pages Fetched</p>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className="single-stat">
              <p className="single-stat-value" style={{ color: "#ff6969" }}>
                {analysedCount != undefined ? (
                  <NumericLabel params={numberOption}>
                    {analysedCount}
                  </NumericLabel>
                ) : (
                    <Loader active inline />
                  )}
              </p>
              <p className="single-stat-label">Pages Analyzed</p>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className="single-stat">
              <p className="single-stat-value" style={{ color: "#ff6969" }}>
                {tasksCount != undefined ? (
                  <NumericLabel params={numberOption}>
                    {tasksCount}
                  </NumericLabel>
                ) : (
                    <Loader active inline />
                  )}
              </p>
              <p className="single-stat-label">Tasks created</p>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={4} className="dashboard-chart">
          <div className="dashboard-chart-slider">
            {checkEmptyCustomChart(chartData) && (
              <Grid.Column className="">
                <div ref="allTasksChart">
                  {this.refs.allTasksChart && (
                    // <CustomActiveShapePieChart
                    //   priority={filters.priority}
                    //   width={this.refs.allTasksChart.parentNode.clientWidth}
                    // />
                    <MinimalPieChart title="Total tasks" type="donut" chartData={chartData} all={true} />
                  )}
                  {/* <p className="all-tasks-chart-label">Priority - All</p> */}
                </div>
              </Grid.Column>
            )}
            {checkEmptyChart(chartData.SEO) && (
              <Grid.Column className="tag-tasks-chart">
                <div ref="seoTasksChart">
                  {this.refs.seoTasksChart && (
                    // <SimplePieChart
                    //   chartData={chartData.SEO}
                    //   width={this.refs.seoTasksChart.parentNode.clientWidth}
                    // />
                    <MinimalPieChart title="SEO errors" type="filled" chartData={chartData.SEO} />
                  )}
                  {/* <p className="all-tasks-chart-label">Priority - SEO</p> */}
                </div>
              </Grid.Column>
            )}
            {checkEmptyChart(chartData.PPC) && (
              <Grid.Column className="tag-tasks-chart">
                <div ref="ppcTasksChart">
                  {this.refs.ppcTasksChart && (
                    // <SimplePieChart
                    //   chartData={chartData.PPC}
                    //   width={this.refs.ppcTasksChart.parentNode.clientWidth}
                    // />
                    <MinimalPieChart title="PPC errors" type="filled" chartData={chartData.PPC} all={false} />
                  )}
                  {/* <p className="all-tasks-chart-label">Priority - PPC</p> */}
                </div>
              </Grid.Column>
            )}
            {checkEmptyChart(chartData.Social) && (
              <Grid.Column className="tag-tasks-chart">
                <div ref="socialTasksChart">
                  {this.refs.socialTasksChart && (
                    // <SimplePieChart
                    //   chartData={chartData.Social}
                    //   width={this.refs.socialTasksChart.parentNode.clientWidth}
                    // />
                    <MinimalPieChart title="Social errors" type="filled" chartData={chartData.Social} />
                  )}
                  {/* <p className="all-tasks-chart-label">Priority - Social</p> */}
                </div>
              </Grid.Column>
            )}
            {checkEmptyChart(chartData.Crawl) && (
              <Grid.Column className="tag-tasks-chart">
                <div ref="crawlTasksChart">
                  {this.refs.crawlTasksChart && (
                    // <SimplePieChart
                    //   chartData={chartData.Crawl}
                    //   width={this.refs.crawlTasksChart.parentNode.clientWidth}
                    // />
                    <MinimalPieChart title="Crawl errors" type="filled" chartData={chartData.Crawl} />
                  )}
                  {/* <p className="all-tasks-chart-label">Priority - Crawl</p> */}
                </div>
              </Grid.Column>
            )}
          </div>
        </Grid.Row>
      </Grid>
      </div>
      <div class= "mob-view"> 
      <Grid divided="vertically">
        <Grid.Row columns={2} className="dashboard-text">
          <Grid.Column>
            <div className="single-stat">
              <p className="single-stat-value" style={{ color: "#ff6969", fontSize:"30px"}}>
                {discoveredCount != undefined ? (
                  <NumericLabel params={numberOption}>
                    {discoveredCount}
                  </NumericLabel>
                ) : (
                    <Loader active inline />
                  )}
              </p>
              <p className="single-stat-label">Pages Discovered</p>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className="single-stat">
              <p className="single-stat-value" style={{ color: "#ff6969", fontSize:"30px" }}>
                {fetchedCount != undefined ? (
                  <NumericLabel params={numberOption} >
                    {fetchedCount}
                  </NumericLabel>
                ) : (
                    <Loader active inline />
                  )}
              </p>
              <p className="single-stat-label">Pages Fetched</p>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className="single-stat">
              <p className="single-stat-value" style={{ color: "#ff6969", fontSize:"30px" }}>
                {analysedCount != undefined ? (
                  <NumericLabel params={numberOption}>
                    {analysedCount}
                  </NumericLabel>
                ) : (
                    <Loader active inline />
                  )}
              </p>
              <p className="single-stat-label">Pages Analyzed</p>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className="single-stat">
              <p className="single-stat-value" style={{color: "#ff6969", fontSize:"30px"}}>
                {tasksCount != undefined ? (
                  <NumericLabel params={numberOption}>
                    {tasksCount}
                  </NumericLabel>
                ) : (
                    <Loader active inline />
                  )}
              </p>
              <p className="single-stat-label">Tasks created</p>
            </div>
          </Grid.Column>
        </Grid.Row>

        </Grid>


      </div>

      </>
    );
  }
}

export default Visualizations;
