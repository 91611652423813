import React from 'react';
// import LeftFilters from './Filters/LeftFilters'
// import LeftFilterWithCheckbox from './Filters/LeftFilterWithCheckbox';
// import { isTemplateElement } from '@babel/types';
// import './styles.css';

export default class LeftList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchUrl: '',
            selectedFilters: {},
            filtersCount: {},
            setFiltersApplied: this.props.setFiltersApplied,
            filters: props.filters
        }
    }

    onUrlFilterChange = (e) => {
        this.setState({ searchUrl: e.target.value });
    }

    setUrlFilter = () => {
        let filters = this.state.filters;
        filters.url = this.state.searchUrl;
        this.setSelectedFilter('url', filters.url)
        this.setState({ filters });
    }

    setFilter = (type, index) => {
        let filters = this.state.filters;
        filters[type].map(item => {
            item.selected = false;
        })
        filters[type][index].selected = !filters[type][index].selected
        this.setSelectedFilter(type, filters[type][index].name)
        this.setState({ filters: filters });
    }

    setSelectedFilter = (type, value) => {
        let selectedFilters = this.state.selectedFilters;
        delete selectedFilters[type];
        if (value !== 'All')
            selectedFilters[type] = value;
        //alert(JSON.stringify(selectedFilters));
        this.setState({ selectedFilters });
        this.state.setFiltersApplied(selectedFilters, this.state.filters);
    }

    render() {
        const filterLists = (type) => {
            return this.state.filters[type].map((item, index) => {
                if (item.selected) {
                    return <li onClick={() => this.setFilter(type, index)} className="just-show-topHead"><b>{item.name}</b><small>{item.count}</small></li>
                }
                return <li onClick={() => this.setFilter(type, index)}><b>{item.name}</b><small>{item.count}</small></li>
            });
        }
        return (
            <aside className="right-container">
                <div className="sticked">
                    <div className="just-show">
                        Just show
                            <ul>
                            {filterLists('tag')}
                        </ul>
                    </div>

                    <div className="priority">
                        Priority
                            <ul>
                            {filterLists('priority')}
                        </ul>
                    </div>

                    <div className="status">
                        Status
                            <ul>
                            {filterLists('status')}
                        </ul>
                    </div>

                    <div className="assigned">
                        Assigned to
                            <ul>
                            {filterLists('assigned')}
                        </ul>
                    </div>
                </div>

                {/* <div className="urls">
                    URL
                        <form>
                        <div className="web-address-input-container">
                            <input
                                className="web-address-input"
                                type="text"
                                value={this.state.searchUrl}
                                placeholder="/home"
                                onChange={this.onUrlFilterChange}
                            />
                        </div>
                        <button onClick={this.setUrlFilter}>
                            Search
                                <div className="right-icon-container">
                            </div>
                        </button>
                    </form>
                </div> */}
            </aside>
        );
    }
}
















