import React, { Component } from "react";
import { userService } from "../../Services";
import TextField from "@material-ui/core/TextField";
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Footer from "../Footer/Footer";
import axios from "axios";

import "../../App.css";
import "./EmailSignup.css";

import arrowImg from "../image/Arrow.svg";
import envelopeImg from "../image/envelope.svg";
import passwordImg from "../image/password.svg";

import { GoogleLogin } from "react-google-login";
import { LinkedIn } from "react-linkedin-login-oauth2";

import LogoIcon from "../LogoIcon";

var config = require("../../Config/config");

class EmailSignup extends Component {
  constructor(props) {
    super(props);

    // userService.logout();

    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      loading: false,
      g_loading: false,
      l_loading: false,
      errorEmail: "",
      error: false,
      errorPass: "",
      loggedOut: false,
      passwordError:"",
      token:this.props.location.pathname.split("/")[2] || null
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }


decode = (token) =>{
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}


  componentWillMount() {
    const auth = config.middleware.url;
    const url = auth + "/tokencheck";

    axios
      .get(url, { headers: { Authorization: "Bearer " + localStorage.getItem("token") } })
      .then(res => {
        if (res.data.success &&
          res.data.message.search("Middleware")) {
          window.location.replace('/home')
        } else {
          this.setState({ loggedOut: true })
        }
      })
      .catch(err => { console.log(err.message); });

      try{
        this.setState({email:this.decode(this.state.token).email})
        userService.ValidateMail(this.state.token)
      }
      catch(error){
        this.setState({badToken:true})
        setTimeout(() => {
          window.history.pushState(
            {
              from: { pathname: window.location.pathname }
            },
            "",
            "/signup"
          );
          window.history.go()
        }, 1000);
      }
        
      

  }

  handleChange(e) {
    const { name, value } = e.target;
    var pattern = new RegExp(/^(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d][A-Za-z\d!@#$%^&*()_+]{7,19}$/).test(value);
    if((name==='password'||name==='confirmPassword')&&!pattern){
      this.setState({[name]: value, errorPass:'Password must be of 8 to 20 characters. It must have a number, special character and a capital alphabet'})
    }
    else{
      this.setState({[name]: value, errorPass:''})
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    const { email, password, confirmPassword,errorPass } = this.state;
    // set state is async and state of error immediately becomes unreliable
    let abort = false
    // stop here if form is invalid
    if (!(email && password)) {
      return;
    }

    this.setState({ error: false, errorEmail: ""})

    if (password != confirmPassword) {
      abort = true
      this.setState({ errorPass: "Passwords did not match", error: true })
    }

    if (!/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
      abort = true
      this.setState({ errorEmail: email + " is not a valid email address", error: true })
    }
    if (errorPass) {
      abort = true
    }

    if (abort) {
      /*
      setTimeout(() => {
        this.setState({
          error: false,
          errorEmail: "",
          errorPass: ""
        })
      }, 4000)
      */
      return;
    }

    this.setState({ loading: true });
    userService.register(email, password).then(
      message => {
        const { from } = window.history.state || {
          from: { pathname: "/" }
        };
        from.pathname = from.pathname ? from.pathname : "/"
        window.history.replaceState({ message }, '', from.pathname)
        window.history.go()
      },
      // error => this.setState({ error, loading: false })
    ).catch(error => {
      let errors = {}
      try {
        errors = error.response.data.error
      } catch {
        errors.email = "Error connecting to authentication server"
      }
      this.setState({
        error: true,
        errorEmail: errors.email,
        errorPass: errors.pass,
        loading: false
      })
      /*
      setTimeout(() => {
        this.setState({
          error: false,
          errorEmail: "",
          errorPass: ""
        })
      }, 4000)
      */
    });
  }



  handleFailure = error => {
    console.log(error);
  };

  render() {
    const { email, password, loading, errorPass, errorEmail, confirmPassword } = this.state;

    return this.state.loggedOut && (
      <div className="login-wrap">

        {/* ..........login/main heading........ */}
        <div className="login-main-back">
          <section className="mai-heading">
          <LogoIcon type="img" width="200px" />
          </section>
          {/* ..........login/forgot............. */}
          {(this.state.badToken)
          ?
          <div>
                <h2 className="Invalid">Invalid User token</h2>
          </div>
          :
          <div>
          <section className="login-forgot-n">
            {/* <div className="signin-link">
              <a
                onClick={() => {
                  window.location.replace("/signup");
                }}
              >
                Sign Up
              </a>
            </div> */}
            <div className="login-link-n">
              <a
                style={{ color: "#E7EAF0" }}
                onClick={() => {
                  window.location.replace("/login");
                }}
              >
                Log In
              </a>
            </div>
            <div className="forgot-link-n">
              <a
                style={{ color: "#E7EAF0" }}
                onClick={() => {
                  window.location.replace("/forgot");
                }}
              >
                Forgot Password
              </a>
            </div>
          </section>
          {/* ..........login/input............... */}
          <form onSubmit={this.handleSubmit}>
            <section className="login-input">
              {errorEmail ?
                <div className="error-text">
                  <FormHelperText error id="component-error-text">{errorEmail}</FormHelperText>
                </div>
                :
                ''
              }
              <div className="login-competitors-container">
                <div className="login-left-icon">
                  <img src={envelopeImg} />
                </div>
                <div className="login-right-icon">
                  <div className="web-address-input-container">
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="E-mail"
                      name="email"
                      value={email}
                      autoComplete="email"
                      onChange={this.handleChange}
                      autoFocus
                      disabled="true"
                      // error={errorEmail && true}
                    />
                  </div>
                </div>
              </div>
              {errorPass ?
                <div className="error-text">
                  <FormHelperText error id="component-error-text" style={{width:'80%',textAlign:'center'}}>{errorPass}</FormHelperText>
                </div>
                :
                ''
              }
              <div className="login-competitors-container pass-border">
                <div className="login-left-icon">
                  <img src={passwordImg} />
                </div>
                <div className="login-right-icon">
                  <div className="web-address-input-container">
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      value={password}
                      id="password"
                      autoComplete="current-password"
                      onChange={this.handleChange}
                      // error={errorPass && true}
                    />
                  </div>
                </div>
              </div>
              <div className="login-competitors-container pass-border">
                <div className="login-left-icon">
                  <img src={passwordImg} />
                </div>
                <div className="login-right-icon">
                  <div className="web-address-input-container">
                    <TextField
                      required
                      fullWidth
                      name="confirmPassword"
                      label="Confirm Password"
                      type="password"
                      value={confirmPassword}
                      id="conformPassword"
                      autoComplete="confirm-password"
                      onChange={this.handleChange}
                      // error={errorPass && true}
                    />
                  </div>
                </div>
              </div>
            </section>
            {/* ..........login/login-button.............. */}
            <section className="login-button">
              <button
                type="submit"
                className="login-btn-compare"
                disabled={loading}
              >
                {!loading ? 'SIGN UP' : 'Creating...'}
                <div className="right-icon-container">
                  {
                    this.state.loading ?
                      <CircularProgress className="spinner" size={18} /> :
                      <KeyboardArrowRightIcon />
                  }
                </div>
              </button>
            </section>
          </form>
          
          {/* ..........login/swipe option..............  */}
          <section className="swipe-back">
            <div className="swipe">
              By creating an account, you agree to our
              <br />
              <a href="#" className="create-acc">
                Terms of Service
              </a>{" "}
              and{" "}
              <a href="#" className="create-acc">
                Privacy Policy
              </a>
            </div>
          </section>
          </div>
          }
          <Footer />
          <div>
            {/* ..........login/Pre footer.......... */}
          </div>
        </div>
      </div>
    );
  }
}

export default EmailSignup;
