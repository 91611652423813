import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Avatar from '@material-ui/core/Avatar';

export default class Testimonial extends Component {
  render() {
    const { expert } = this.props;

    return (
        <section className="testimonialBack">
            <div className="testmonialImage">
                <Avatar alt={expert.name} src={expert.image} style={{ width: '87px', height: '87px' }} />
                {/* <img src={expert.image} alt={expert.name} /> */}
            </div>
            <div className="testmonialMsg">
                <div className="msg-content">
                    {expert.statement}
                </div>
                <div className="msg-profile">
                    <div className="profile-name">{expert.name}</div>
                    <div className="profile-designation">{expert.position}</div>
                </div>
            </div>
        </section>
    );
  }
}

Testimonial.propTypes = {
    expert: PropTypes.object.isRequired,
};
