import React from "react";
import { userService } from "../../Services/";

import styles from "./Guest.style";
import { withStyles } from "@material-ui/core/styles";

class Guest extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: ""
    };

    this.guestLogin = this.guestLogin.bind(this);
  }

  guestLogin() {
    this.setState({ loading: false });
    userService.guest().then(
      user => window.location.replace('/home'),
      error => this.setState({ error, loading: true })
    );
  }

  render() {
    return (
      <React.Fragment>{this.state.loading && this.guestLogin()}</React.Fragment>
    );
  }
}

export default withStyles(styles)(Guest);
