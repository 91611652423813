import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";

import CircularProgress from '@material-ui/core/CircularProgress';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import FormHelperText from '@material-ui/core/FormHelperText';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Pricing from "../Pricing";
// import "../../App.css";

// import "../TaskDetails/taskDetail.css";
// import "../Tasks/style.css";
import "./main.css";
import "./Home.css";
import worldwideImage from "../image/worldwide.svg";

import { userService } from "../../Services/UserService"

import LogoIcon from "../LogoIcon";

import Sidebar from '../Sidebar/Sidebar';
import SideDrawer from '../SideDrawer/SideDrawer';
import Backdrop from '../Backdrop/Backdrop';
var config = require("../../Config/config");

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: localStorage.getItem("user"),
      pricingOpen: false,
      domain: "",
      redirect: "",
      loading: false,
      buttonActive: true,
      snackbarOpen: false,
      snackbarMessage: undefined,
      projects: [],
      projectId: "",
      errorMsg: "",
    };
  }

  componentDidMount() {
    const auth = config.middleware.url;
    const url = auth + "/tokencheck";
    axios
      .get(url, { headers: { Authorization: "Bearer " + localStorage.getItem("token") } })
      .then(async (res) => {
        if (!res.data.success) { return userService.guest() }
        else {
          let projects = await axios.get(
            `${process.env.REACT_APP_MAI_SERVER_HOST}/api/v1/projects`,
            { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
          );
          if (projects.data.length) {
            this.setState({
              projects: projects.data,
              projectId: projects.data.length > 0 ? projects.data[projects.data.length - 1]._id : ""
            });
          }
        }
      })
      .catch(err => { console.log(err.message); });

    let { message } = window.history.state || { message: "" }
    if (message) {
      this.setState({ snackbarMessage: message, snackbarOpen: true })
      window.history.replaceState(null, '')
    }
  }

  handleSnackbarClose = () => {
    this.setState({ snackbarOpen: false })
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    // const urlRegex = /^[\w.\-:/]+(?:\.[\w\.-]+)+$/
    // const urlRegex = /^[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
    // const urlRegex = /^(http:\/\/www\.|HTTP:\/\/WWW\.|https:\/\/www\.|HTTPS:\/\/WWW\.|http:\/\/|HTTP:\/\/|https:\/\/|HTTPS:\/\/)?[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
    // if (value.match(urlRegex)) {
    //   this.setState({ buttonActive: true })
    // } else {
    //   this.setState({ buttonActive: false })
    // }
  };

  handleEnterKeyPress = e => {
    if (e.key && e.key === "Enter") this.handleSubmit();
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.state.domain) {
      this.setState({ loading: true })
      axios
        .post(
          `${process.env.REACT_APP_MAI_SERVER_HOST}/api/v1/projects`,
          {
            domain: this.state.domain,
            depth: 2
          },
          {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") }
          }
        )
        .then(project => {
          let { success, message } = project.data
          if (success === false) {
            this.setState({
              loading: false,
              errorMsg: this.state.domain + " : " + message,
            })
            localStorage.removeItem('server')
          } else {
            let projectId = project.data._id;
            this.setState({ redirect: "tasks", projectId: projectId, errorMsg: "" });
            if (project.data.server) {
              localStorage.setItem('server', project.data.server)
            } else {
              localStorage.removeItem('server')
            }

          }
        })
        .catch(err => {
          let message = ""
          let success = undefined
          try {
            success = err.response.data.success
            message = this.state.domain + " : " + err.response.data.message
          } catch {
            message = "Error connecting to application server"
          }
          if (success === false) {
            this.setState({ errorMsg: message })
          }
          console.log(err.message);
          const a = err.message.search("401");
          this.setState({ loading: false })
          if (a > 0) {
            userService.guest()
            this.handleSubmit()
            // alert("Session expired!! Click OK to reload!!");
            // localStorage.removeItem("token");
            // window.location.replace("/");
          } //token expired
        });
    }
  };

  renderRedirect = () => {
    let to = `/tasks?projectId=${this.state.projectId}`;
    if (this.state.redirect === "tasks") {
      // localStorage.getItem("user") != "Guest" && this.handleNotification(to);
      this.props.history.push(to)
    }
  };

  // handleNotification = to => {
  //   let { domain, projectId } = this.state;
  //   let hostname = window.location.hostname;
  //   let projectUrl = window.location.protocol + "//" + hostname + to;
  //   let email = localStorage.getItem("user");
  //   let subject = "New Project Created";
  //   let projectsUrl = window.location.protocol + "//" + hostname + "/tasks";

  //   axios
  //     .post(
  //       `${process.env.REACT_APP_MAI_NOTIFICATION}/api/v1/templateMail`,
  //       {
  //         toList: email,
  //         templateId: "createProject",
  //         templateData: {
  //           subject,
  //           domain,
  //           projectId,
  //           projectUrl,
  //           projectsUrl
  //         }
  //       },
  //       {
  //         headers: { Authorization: "Bearer " + localStorage.getItem("token") }
  //       }
  //     )
  //     .then(success => {
  //       console.log(success.data);
  //     })
  //     .catch(err => {
  //       // console.log(err.message);
  //       // const a = err.message.search("401");
  //       // if (a > 0) {
  //       //   // alert("Session expired!! Click OK to reload!!");
  //       //   localStorage.removeItem("token");
  //       //   window.location.replace("/");
  //       // } //token expired
  //       console.log(err);
  //     });
  // };

  Snackbarcolor(){
    if(this.state.snackbarMessage === "Session expired, please login again!"){
      return '#f26363'
    }
    else{
      return '#47d147'
    }
  }

  state = {
    sideDrawerOpen:false
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return {sideDrawerOpen: !prevState.sideDrawerOpen};
    });
  };

  backdropClickHandler = ()=> {
    this.setState({sideDrawerOpen:false});
  };

  render() {
    let sideDrawer;
    let backdrop;
    if (this.state.sideDrawerOpen) {
      sideDrawer = <SideDrawer 
      projectId={this.state.projectId}
      projects={this.state.projects}
      setHeaderSelection={() => { }}
      />;
      backdrop = <Backdrop click={this.backdropClickHandler} />
    }

    const handleClosePricing = value => {
      this.setState({ pricingOpen: false });
      const { user } = this.state;
      if (value === "Free") {
        if (user === "Guest") {
          window.history.pushState(
            {
              from: { pathname: window.location.pathname }
            },
            "",
            "/signup"
          );
          window.history.go();
        }
      } else if (value !== "None") {
        if (user === "Guest") {
          window.history.pushState(
            {
              from: { pathname: window.location.pathname }
            },
            "",
            "/login"
          );
          window.history.go();
        } else {
          localStorage.setItem("pricing_plan", value);
          window.location.href = "/payment";
        }
      }
    };

    const { domain } = this.state;
    return (
      <div>
        {this.state.snackbarMessage !== undefined && <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
          open={this.state.snackbarOpen} autoHideDuration={10000}
          onClose={this.handleSnackbarClose}

        >
          <SnackbarContent
            style={{ backgroundColor:this.Snackbarcolor(), fontWeight: "bold" }}
            message={this.state.snackbarMessage}
            action={
              <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit"
                  onClick={this.handleSnackbarClose}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        </Snackbar>}

        {this.renderRedirect()}
        <Pricing open={this.state.pricingOpen} onClose={handleClosePricing} />
        <Header
          projectId={this.state.projectId}
          projects={this.state.projects}
          setHeaderSelection={() => { }}
        />
        {/* <Tasks domain="www.google.com" /> */}
        <div className="desktop-view">
          <div className="home-wrap">
            {/* ..........Home/main heading........ */}
            <div className="home-main-back">
              <section className="mai-heaing">
                <LogoIcon type="img" width="250px" height="auto" alt="BrandName" title="BrandName"/>
              </section>
              {/* ..........Home/content............. */}
              <section className="home-content">
                <p>
                  Hire the best marketing teams, databases and bots that promote
                  your business website, get more leads, calls and sales.
                </p>
              </section>
              {/* ..........Home/input............... */}
              <form onSubmit={this.handleSubmit}>
                <section className="home-input">
                  {this.state.errorMsg ?
                    <div className="error-text">
                      <FormHelperText error id="component-error-text">{this.state.errorMsg}</FormHelperText>
                    </div>
                    :
                    ''
                  }
                  <div className="home-competitors-container">
                    <div className="home-left-icon">
                      <img src={worldwideImage} alt="website" />
                    </div>
                    <div className="home-right-icon">
                      <div className="web-address-label">
                        Enter website address
                    </div>
                      <div className="web-address-input-container">
                        <input
                          required={true}
                          id="domain"
                          name="domain"
                          value={domain}
                          autoFocus
                          className="web-address-input"
                          type="text"
                          // placeholder="your competitor domain.com"
                          style={{
                            border: "none",
                            width: "100%",
                            fontSize: "15px",
                            left: "1%",
                            marginLeft: "1%"
                          }}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </section>
                {/* ..........Home/button.............. */}
                <section className="home-button">
                  <button
                    type="submit"
                    className="home-btn-compare"
                    disabled={this.state.loading || !this.state.buttonActive}
                  >
                    {!this.state.loading ? 'GET STARTED' : 'Processing...'}
                    <div className="right-icon-container">
                      {
                        this.state.loading ?
                          <CircularProgress className="spinner" size={18} /> :
                          <KeyboardArrowRightIcon />
                      }
                    </div>
                  </button>
                </section>
              </form>
              {/* <div className="below-text">
                <a href="#">I DON'T HAVE A WEBSITE</a>
              </div> */}
            </div>
          </div>
          <Footer />
        </div>
        {/* ..........Mobile view.............. */}
        
        <div className="mob-view">

          <div className="home-wrap" >
            {/* ..........Home/header.............. */}
            <Sidebar 
            drawerClickHandler={this.drawerToggleClickHandler} />
            {sideDrawer }
            {backdrop}


            {/* ..........Home/main heading........ */}
            <div className="home-main-back" style={{marginTop:"25%"}}>
              <section className="mai-heading">
              <LogoIcon type="img"  alt="BrandName" width="250px" height="auto" title="BrandName" />
              </section>
              
              {/* ..........Home/content............. */}
              <section className="mob-home-content">
                <p>
                  Hire the best marketing teams, databases and bots that promote
                  your business website, get more leads, calls and sales.
                </p>
              </section>
              {/* ..........Home/input............... */}
              <form onSubmit={this.handleSubmit}>
                <section className="home-input">
                  <div className="home-competitors-container">
                    <div className="home-left-icon">
                      <img src={worldwideImage} alt="website" />
                    </div>
                    <div className="home-right-icon">
                      <div className="web-address-label">
                        enter website address
                    </div>
                      <div className="web-address-input-container">
                        <input
                          required={true}
                          id="domain"
                          name="domain"
                          value={domain}
                          autoFocus
                          className="web-address-input"
                          type="text"
                          // placeholder="your competitor domain.com"
                          style={{
                            border: "none",
                            width: "100%",
                            fontSize: "15px",
                            left: "1%",
                            marginLeft: "1%"
                          }}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </section>
                {/* ..........Home/button.............. */}
                <section className="home-button">
                  <button
                    type="submit"
                    className="home-btn-compare"
                    disabled={this.state.loading || !this.state.buttonActive}
                  >
                    {!this.state.loading ? 'GET STARTED' : 'Processing...'}
                    <div className="right-icon-container">
                      {
                        this.state.loading ?
                          <CircularProgress className="spinner" size={18} /> :
                          <KeyboardArrowRightIcon />
                      }
                    </div>
                  </button>
                </section>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Home);
