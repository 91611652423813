import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import { makeStyles, createStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import StarIcon from "@material-ui/icons/StarBorder";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import PropTypes from "prop-types";
import { LoginView } from "../LoginPage/LoginPage";

const useStyles = makeStyles(theme =>
  createStyles({
    heroContent: {
      padding: theme.spacing(8, 0, 6)
    }
  })
);

export default function LoginDialog(props) {
  const classes = useStyles();

  const { onClose, open } = props;

  function handleClose() {
    onClose();
  }

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="md" fullScreen={window.innerWidth <= 1250}>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        {window.history.pushState(
          {
            from: { pathname: window.location.pathname }
          },
          ""
        )}
        <LoginView />
      </Container>
      <DialogContentText className={classes.content}></DialogContentText>
      <DialogActions className={classes.actions}></DialogActions>
    </Dialog>
  );
}

LoginDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};
