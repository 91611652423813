import React from 'react';
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton';
import brandLogo from "../../img/brand.png";
import './Sidebar.css';

const sidebar = props => (
    <header className="sidebar">
            <nav className = "sidebarnav">
              <div>
                <DrawerToggleButton click={props.drawerClickHandler} />
              </div>
            
              <div className="sidebarlogo"><a href="/"><img className="mob-logo" src={brandLogo} alt="logo" /></a></div>
              {/*<div className="sidebaritems">
                <ul>
                  <li><a href = "/">Dashboard</a></li>
                  <li><a href = "/">Tasks</a></li>
                </ul>
                </div>*/}
            </nav>  
     </header>
);
export default sidebar;