import React, { Component } from 'react';
import PropTypes from 'prop-types';
import downArrow from '../image/arrow-down.svg';
import AssignContainer from "./AssignContainer";
import axios from "axios";
import Avatar from "react-avatar";
import TaskDetailList from "./TaskDetailList";
import Footer from "../Footer/Footer";
import TasksHeaderMobile from "../Header/TasksHeaderMobile";
import Chip from '@material-ui/core/Chip';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { green, red } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

export default class TaskDetailMobile extends Component {
  constructor(props) {
    super(props);
    let projectId = new URL(document.location).searchParams.get("projectId");
    let taskId = new URL(document.location).searchParams.get("task");
    this.state = {
      projectId: projectId ? projectId : "",
      taskId: taskId ? taskId : "",
      status: "",
      assignees: [],
      snackbarOpen: false,
      snackbarMessage: undefined
    };
  }

  state = {
    openDeclineTask: false,
    openMarkasDone: false,
    openReOpen: false
  }

  handleClickOpenDeclineTask = () => {
    this.setState({ openDeclineTask: true });
  };

  handleClickOpenMarkasDone = () => {
    this.setState({ openMarkasDone: true });
  };

  handleClickOpenReOpen = () => {
    this.setState({ openReOpen: true });
  };

  handleCloseDeclineTask = () => {
    this.setState({ openDeclineTask: false });
  };

  handleCloseMarkasDone = () => {
    this.setState({ openMarkasDone: false });
  };

  handleCloseReOpen = () => {
    this.setState({ openReOpen: false });
  };

  componentDidMount() {
    this.handleTaskStatus(true);

    let { message } = window.history.state || { message: "" }
    if (message) {
      this.setState({ snackbarMessage: message, snackbarOpen: true })
      window.history.replaceState(null, '')
    }
  }

  handleSnackbarClose = () => {
    this.setState({ snackbarOpen: false })
  }

  getTaskDetailsWrapper = () => {
    const { projectId, taskId, pageCount, getTaskDetails } = this.props;
    getTaskDetails(projectId, taskId, pageCount);
  };

  handleTaskStatus = (retrieve = false, update = false, newStatus = "") => {
    const { projectId, taskId } = this.state;
    var mai_task_manager_host = localStorage.getItem("server") !== null ?
      process.env.REACT_APP_MAI_TASK_MANAGER_HOST.split('.')[0] + '.' + localStorage.getItem("server") :
      process.env.REACT_APP_MAI_TASK_MANAGER_HOST

    retrieve &&
      axios
        .get(
          `${mai_task_manager_host}/api/v1/tasks?project_id=${projectId}&error_code=${taskId}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            }
          }
        )
        .then(tasks => {
          this.setState({ status: tasks.data.data.result[0].status });
          // console.log(tasks.data.data.result);
        })
        .catch(err => {
          console.log(err.message);
        });
    update &&
      axios
        .put(
          `${mai_task_manager_host}/api/v1/tasks?project_id=${projectId}&error_code=${taskId}`,
          { status: newStatus },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            }
          }
        )
        .then(res => {
          // console.log(res.data);
        })
        .catch(err => {
          console.log(err.message);
        });
  };

  handleMarkTaskButton = () => {
    const { status } = this.state;
    if (status === "Open") {
      return (
        <div style={{ float: "left" }}>
          <div style={{ float: "left", marginLeft: 42 }}>
            {this.state.assignees.slice(0, 2).map((email, index) => (
              <Avatar
                name={email}
                round={true}
                size={46}
                key={index}
                style={{ margin: "0 20px 0 -42px", zIndex: -index }}
              />
            ))}
            {this.state.assignees.length > 2 ?
              (<Avatar
                name={`+ ${this.state.assignees.length - 2}`}
                round={true}
                size={46}
                key="4"
                color="#737373"
                style={{ margin: "0 20px 0 -42px", zIndex: 10 }}
              />)
              :
              ''}
          </div>
          <div style={{ float: "left" }}>
            <AssignContainer
              assigned={assignees => {
                this.setState({ assignees });
              }}
            />
          </div>
        </div>
      );
    } else if (status === 'Completed' || status === 'Declined') {
      return (
        <button
          className="assign"
          onClick={this.handleClickOpenReOpen}
        >
          REOPEN
        </button>
      )
    } else {
      return (
        <span></span>
      )
    }
  };

  render() {
    const {
      history,
      errorData,
      taskList,
      pageCount,
      projectId,
      // taskId,
      projectDetails,
      dropDownItems
      // data,
      // comments
    } = this.props;

    let expert = {};
    if (errorData.experts) {
      expert = errorData.experts[Math.floor(Math.random() * errorData.experts.length)];
    }

    return (
      <div className="mob-view">
        {/*} {this.state.snackbarMessage !== undefined && <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                open={this.state.snackbarOpen} autoHideDuration={10000}
                onClose={this.handleSnackbarClose}

              >
                <SnackbarContent
                  style={{ backgroundColor: '#47d147', fontWeight: "bold" }}
                  message={this.state.snackbarMessage}
                  action={
                    <React.Fragment>
                      <IconButton size="small" aria-label="close" color="inherit"
                        onClick={this.handleSnackbarClose}>
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </React.Fragment>
                  }
                />
                </Snackbar>}*/}
        <TasksHeaderMobile hideTitle={true} hideFilters={true} history={history} />
        {taskList.length > 0 ?
          <main className="container-fluid">
            <section className="heading-wrapper">
              <div className="detail-mob-main">
                <div className="detail-mob-heading">{errorData.error}</div>
                <div className={`ratingMap-tag ${errorData.priority}`}>{errorData.priority}</div>
                <div>{this.handleMarkTaskButton()}

                </div>


                <section className="ratingBack">
                  <div className="task-actions">
                    {this.state.status === 'Open' ?
                      <React.Fragment>

                        <div style={{ marginTop: "5px" }}>
                          <Chip
                            avatar={<CheckCircleIcon
                              style={{ color: green[500] }} />}
                            label="Mark as done"
                            onClick={this.handleClickOpenMarkasDone} />

                          <Dialog
                            style={{ fontFamily: "IBM Plex Sans" }}
                            open={this.state.openMarkasDone}
                            onClose={this.handleCloseMarkasDone}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle id="alert-dialog-title">{"Mark as Done"}</DialogTitle>

                            <div class="confirmModalbody">
                              Are you sure this task is done?
                                        </div>

                            <div class="modal-footer">
                              <button
                                type="button"
                                class="btn no-btn"
                                data-dismiss="modal"
                                onClick={this.handleCloseMarkasDone}
                              >
                                No
                                          </button>
                              <button
                                type="button"
                                class="btn yes-btn"
                                data-dismiss="modal"
                                onClick={() => {
                                  this.handleTaskStatus(false, true, "Completed");
                                  this.setState({ status: "Completed" });
                                  this.handleCloseMarkasDone();
                                }}
                              >
                                Yes
                                          </button>
                            </div>
                          </Dialog>
                        </div>



                        <div style={{ marginTop: "5px" }}>
                          <Chip
                            avatar={<RemoveCircleIcon
                              style={{ color: red[500] }} />}
                            label="Decline task"
                            onClick={this.handleClickOpenDeclineTask} />

                          <Dialog
                            style={{ fontFamily: "IBM Plex Sans" }}
                            open={this.state.openDeclineTask}
                            onClose={this.handleCloseDeclineTask}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle id="alert-dialog-title">{"Decline Task"}</DialogTitle>

                            <div class="confirmModalbody">
                              Are you sure you want to decline this task?
                                        </div>

                            <div class="modal-footer">
                              <button
                                type="button"
                                class="btn no-btn"
                                onClick={this.handleCloseDeclineTask}
                              >
                                No
                                          </button>
                              <button
                                type="button"
                                class="btn yes-btn"
                                onClick={() => {
                                  this.handleTaskStatus(false, true, "Declined");
                                  this.setState({ status: "Declined" });
                                  this.handleCloseDeclineTask();
                                }}
                              >
                                Yes
                                          </button>
                            </div>
                          </Dialog>
                        </div>

                      </React.Fragment>
                      :
                      <Chip
                        variant="outlined"
                        color={this.state.status === 'Completed' ? 'primary' : 'secondary'}
                        size="small"
                        label={this.state.status} />
                    }
                  </div>
                </section>



                <div className="mob-ratingPagesCount">
                  <b>{pageCount}</b> PAGES
                            </div>
                <div className="details-assigned-container">
                  {/* <div className="details-assignPerson-one">
                              </div>
                              <div className="details-assignPerson-rest">
                              </div> */}

                </div>

              </div>
            </section>





            {/* <div className="decription-profile">
                                <div className="detail-mob-white-box">
                                    <div className="decription-profile-image">
                                        <img src={picTwo} />
                                    </div>
                                    <div className="decription-profile-info">
                                        <div className="decription-profile-name">Jerome Gaveau</div>
                                        <div className="decription-profile-status">Assigned</div>
                                    </div>
                                    <div className="decription-arrow">
                                        <img src={arrow} />
                                    </div>
                                </div>
                            </div> */}

            <section className="mob-affected-container">
              <div className="detail-mob-main">
                <div id="accordion">
                  <div className="card">
                    <div className="card-header" data-toggle="collapse" href="#description-collapse">
                      <div className="affected-expert-mob-head">
                        <span>DESCRIPTION</span>
                      </div>
                      <div className="decription-arrow">
                        <img src={downArrow} alt="expand" />
                      </div>
                    </div>
                    <div id="description-collapse" className="collapse" data-parent="#accordion">
                      <div className="affected-card">
                        {errorData.what ?
                          <>
                            <div className="mob-block-head">
                              <span>What is wrong?</span>
                            </div>

                            <div className="description-text">
                              <p>{errorData.what}</p>
                            </div>
                          </>
                          :
                          null
                        }
                        {errorData.why ?
                          <>
                            <div className="mob-block-head">
                              <span>Why it is important?</span>
                            </div>

                            <div className="description-text">
                              <p>{errorData.why}</p>
                            </div>
                          </>
                          :
                          null
                        }
                        {errorData.how ?
                          <>
                            <div className="mob-block-head">
                              <span>How to fix it?</span>
                            </div>

                            <div className="description-text">
                              <p>{errorData.how}</p>
                            </div>
                          </>
                          :
                          null
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="mob-affected-container">
              <div className="detail-mob-main">
                <div id="accordion">
                  <div className="card">
                    <div className="card-header" data-toggle="collapse" href="#affected-collapse">
                      <div className="affected-expert-mob-head">
                        <span>AFFECTED PAGES</span>
                      </div>
                      <div className="decription-arrow">
                        <img src={downArrow} alt="expand" />
                      </div>
                    </div>
                    <div id="affected-collapse" className="collapse" data-parent="#accordion">
                      <div className="affected-card">
                        <TaskDetailList
                          domain={projectDetails.domain}
                          history={history}
                          projectId={projectId}
                          taskList={taskList}
                          errorData={errorData}
                          expert={expert}
                          pageCount={pageCount}
                          getTaskDetailsWrapper={this.getTaskDetailsWrapper}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="mob-expert-container">
              <div className="detail-mob-main">
                <div id="accordion">
                  {expert ?
                    <div className="card">
                      <div className="card-header" data-toggle="collapse" href="#expert-collapse">
                        <div className="affected-expert-mob-head">
                          <span>WHAT EXPERTS SAY</span>
                        </div>
                        <div className="decription-arrow">
                          <img src={downArrow} alt="expand" />
                        </div>
                      </div>
                      <div id="expert-collapse" className="collapse" data-parent="#accordion">
                        <div className="affected-card">
                          <div className="mob-expert-white-box">
                            <div className="mob-expert-say-profile">
                              <div className="expert-say-profile-image">
                                <img src={expert.image} alt={expert.name} style={{ width: '100%', borderRadius: '50%' }} />
                              </div>
                              <div className="decription-profile-info">
                                <div className="decription-profile-name">{expert.name}</div>
                                <div className="decription-profile-status">{expert.position}</div>
                              </div>
                            </div>
                            <div className="mob-expert-say-text">
                              <span>{expert.statement}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    null
                  }
                </div>

              </div>
            </section>

            <section className="comment-container">
              <div className="detail-mob-main">
                {/* <div className="affected-expert-mob-head">
                                <span>ADD YOUR COMMENT</span>
                            </div>

                            <div className="mob-saySomthing">
                                <input type="text" placeholder="Say something" className="mob-write-comments" />
                                <img src={microphoneOfVoice} />
                            </div>
                            <CommentMobile comments={comments} /> */}
              </div>
            </section>
            <Footer />

            <Dialog
              style={{ fontFamily: "IBM Plex Sans" }}
              open={this.state.openReOpen}
              onClose={this.handleCloseReOpen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Reopen Task"}</DialogTitle>

              <div class="confirmModalbody">
                Do you want to reopen this task?
                      </div>

              <div class="modal-footer">
                <button
                  type="button"
                  class="btn no-btn"
                  data-dismiss="modal"
                  onClick={this.handleCloseReOpen}
                >
                  No
                        </button>
                <button
                  type="button"
                  class="btn yes-btn"
                  data-dismiss="modal"
                  onClick={() => {
                    this.handleTaskStatus(false, true, "Open");
                    this.setState({ status: "Open" });
                    this.handleCloseReOpen();
                  }}
                >
                  Yes
                        </button>
              </div>
            </Dialog>

          </main>
          :
          <div style={{ marginTop: "57%", marginLeft: "37%" }}>
            <div class="boxes">
              <div class="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div class="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div class="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div class="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        }
      </div>

    );
  }
}

TaskDetailMobile.propTypes = {
  history: PropTypes.object.isRequired,
  dropDownItems: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  taskList: PropTypes.array.isRequired,
  errorData: PropTypes.object.isRequired,
  projectDetails: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired,
  taskId: PropTypes.string.isRequired,
  pageCount: PropTypes.number.isRequired,
  comments: PropTypes.array.isRequired,
  getTaskDetails: PropTypes.func.isRequired
};
