import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Content extends Component {
  render() {
    const { errorData } = this.props;
    return (
        <section className="row contentBack">
        <div className="col-sm-4 cus-padding">
            {errorData.what ?
                <div className="content">
                    <h6><b>What is wrong?</b></h6>
                    <p>{errorData.what}</p>
                </div>
                :
                null
            }
        </div>
        <div className="col-sm-4">
            {errorData.why ?
                <div className="content">
                    <h6><b>Why it is important?</b></h6>
                    <p>{errorData.why}</p>
                </div>
                :
                null
            }
        </div>
        <div className="col-sm-4">
            {errorData.how ?
                <div className="content">
                    <h6><b>How to fix it?</b></h6>
                    <p>{errorData.how}</p>
                </div>
                :
                null
            }
        </div>
    </section>
    );
  }
}

Content.propTypes = {
    errorData: PropTypes.object.isRequired,
};
