import React, { Component } from "react";

import '../../App.css'

import '../TaskDetails/taskDetail.css';
import '../Tasks/style.css';
import './Dashboard.css';

class ConnectedApp extends Component {

    render() {
        return (
            <div className="app-box">

                <div className="app-image-div">
                    <img src={this.props.img} className="app-img" />
                </div>
                <div className="app-name-div">
                    <span className="app-name" style={{ position: "absolute" }}>{this.props.name}</span>
                </div>

            </div>
        );
    }
}

export default ConnectedApp;