import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
// import LoginDialog from "../../LoginDialog/LoginDialog";

import "./TaskList.css";
import TaskComp from "../../TaskComp";
import BulkSelectPriority from "../BulkSelectComponents/Priority";
import BulkSelectStatus from "../BulkSelectComponents/Status";
import { Checkbox } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Request from "../../../request";

export default class TaskList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: localStorage.getItem("user"),
      // loginOpen: false
      taskIdArray: [],
      enableBulkHeader: false,
      applyButtonloading: false,
      status: '',
      priority: ''
    };
  }

  apiBulkRequest = async () => {
    const { projectId } = this.props;
    const { taskIdArray, status, priority } = this.state;
    console.log(priority, status);
    let tempdata = {
      error_code: taskIdArray,
    };

    if (!!status) {
      tempdata = {
        ...tempdata,
        status: status,
      };
    }

    if (!!priority) {
      tempdata = {
        ...tempdata,
        priority: priority,
      };
    }

    const response = await Request.updateMultipleTasks(tempdata, projectId);
    console.log(response);
    this.setState({
      applyButtonloading: false,
    });
    this.onApplyBulkChange();
  };

  handleChange = (event, key) => {
    const { checked } = event.target;
    const { taskIdArray, enableBulkHeader } = this.state;
    let temp = taskIdArray;

    if (checked) {
      if (!temp.includes(key)) {
        temp.push(key);
      }
    } else {
      if (temp.includes(key)) {
        let index = temp.indexOf(key);
        temp.splice(index, 1);
      }
    }
    let headerFlag = false;

    if (taskIdArray.length > 0) {
      headerFlag = true;
    }

    this.setState({
      taskIdArray: temp,
      enableBulkHeader: headerFlag,
    });
  };

  handleAllChange = (event) => {
    const { tasks } = this.props;
    let selectedTaskArray = tasks && tasks.map((t) => t.error_code);
    this.setState({
      taskIdArray: selectedTaskArray,
    });
  };
  handleNoneChange = (event) => {
    // const { tasks } = this.props;

    this.setState({
      taskIdArray: [],
      enableBulkHeader: false,
    });
  };

  allSelectChecked = () => {
    const { taskIdArray } = this.state;
    const { tasks } = this.props;
    console.log(tasks.length, taskIdArray.length, "LENGTH");
    if (tasks.length === taskIdArray.length()) {
      return true;
    } else {
      return false;
    }
  };

  onApplyBulkChange = () => {
    this.setState({ taskIdArray: [], priority: '', status: '' });
  };
  // goToTaskDetails = (projectId, task) => {
  //   const { user } = this.state;
  //   if (user === "Guest") {
  //     this.setState({ loginOpen: true });
  //   } else {
  //     this.props.history.push(
  //       `/taskdetail?projectId=${projectId}&task=${task}`
  //     );
  //   }
  // };

  goToTaskDetails = (projectId, task) => {
    window.history.pushState(
      {
        from: { pathname: `/taskdetail?projectId=${projectId}&task=${task}` },
      },
      "",
      "/login"
    );
    window.history.go();
  };

  handleApplyButton = () => {
    this.setState({
      applyButtonloading: true,
    });

    this.apiBulkRequest();
  };

  handlePriorityChange = (value) => {
    const { priority } = this.state;

    if (priority !== value) {
      this.setState({
        priority: value,
      });
    }
  };

  handleStatusChange = (value) => {
    const { status } = this.state;

    if (status !== value) {
      this.setState({
        status: value,
      });
    }
  };

  render() {
    const tasks = this.props.tasks;
    const projectId = this.props.projectId;
    const {
      taskIdArray,
      enableBulkHeader,
      applyButtonloading,
      status,
      priority,
    } = this.state;

    // const handleCloseLogin = () => {
    //   this.setState({ loginOpen: false });
    // };

    const taskList = tasks.map((task, index) => {
      if (this.state.user === "Guest") {
        return (
          <div onClick={() => this.goToTaskDetails(projectId, task.error_code)}>
            <TaskComp
              taskId={task.taskId}
              taskName={task.title}
              taskAssignees={task.assignees}
              taskSignificance={task.priority}
              pageAffected={task.pageCount}
              trafficAffected={"###"}
              taskStatus={task.status}
              number={1}
              user={this.state.user}
            />
          </div>
        );
      } else {
        return (
          <div class="task-list" key={task.taskId}>
            <div class="tasklist-div">
              <TaskComp
                taskId={task.taskId}
                taskName={task.title}
                taskAssignees={task.assignees}
                taskSignificance={task.priority}
                pageAffected={task.pageCount}
                trafficAffected={"###"}
                taskStatus={task.status}
                number={1}
                checked={taskIdArray.includes(task.error_code)}
                onCheckBoxChange={(e) => this.handleChange(e, task.error_code)}
                error_code={task.error_code}
                projectId={projectId}
                user={this.state.user}
              />
            </div>
          </div>
        );
      }
    });
    return (
      <div>
        {/* <LoginDialog open={this.state.loginOpen} onClose={handleCloseLogin} /> */}
        <section className="tasks-wrapper">
          {taskIdArray.length > 0 &&
          this.state.user !== "Guest" && ( // remove true for enabling the header on clicking the checkbox
              <div className="Filter-Header">
                <div className="bulk-button-div">
                  {/* <Checkbox
                    checked={tasks.length === taskIdArray.length}
                    onChange={e => this.handleAllChange(e)}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  /> */}
                  <b style={{ padding: "10px 20px", fontSize: "18px" }}>
                    Select
                  </b>
                  <div className="select-button">
                    <Button
                      className="buttonSelect"
                      onClick={this.handleAllChange}
                      // color="default"
                      variant="outlined"
                      disabled={taskIdArray.length === tasks.length}
                    >
                      {" "}
                      All{" "}
                    </Button>
                  </div>
                  <b style={{ padding: "10px 10px" }}>/</b>
                  <div className="select-button">
                    <Button
                      className="buttonSelect"
                      onClick={this.handleNoneChange}
                      // color="default"
                      variant="outlined"
                      disabled={!taskIdArray.length}
                    >
                      {" "}
                      None{" "}
                    </Button>
                  </div>
                  {/* <b style={{ padding: "0 20px" }}>{}Select All </b> */}
                </div>
                <div className="bulk-actions">
                  <div className="Priority-Header">
                    <BulkSelectPriority
                      taskIdList={taskIdArray}
                      projectId={projectId}
                      onPriorityChange={this.handlePriorityChange}
                      reset={this.onApplyBulkChange}
                    />
                  </div>
                  <div className="Priority-Header">
                    <BulkSelectStatus
                      taskIdList={taskIdArray}
                      projectId={projectId}
                      onStatusChange={this.handleStatusChange}
                      reset={this.onApplyBulkChange}
                    />
                  </div>
                  <div className="applyButton">
                    <Button
                      className="applyButton"
                      onClick={this.handleApplyButton}
                      color="primary"
                      variant="outlined"
                      disabled={
                        (!!!priority && !!!status) || applyButtonloading
                      }
                    >
                      {applyButtonloading ? (
                        <CircularProgress size={16} />
                      ) : (
                        "Apply"
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            )}

          {taskList}
        </section>
      </div>
    );
  }
}

TaskList.propTypes = {
  history: PropTypes.object.isRequired,
};
