import React, { Component } from "react";
import Header from "../Header/Header";
import SearchField from "react-search-field";
import { async } from "q";
import { Redirect } from "react-router-dom";
import axios from "axios";

class Tasks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      domain: this.props.domain || ""
    };
  }
  render() {
    const { filters, tasks } = this.state;
    return (
      <div>
        {this.renderRedirect()}
        {this.props.domain ? (
          this.sendToCrawler()
        ) : (
          <div>
            <div className="desktop-view">
              <Header projects={this.state.projects} />
            </div>
            <div
              style={{
                position: "absolute",
                left: "50%",
                top: "30%",
                transform: "translate(-50%, -50%)"
              }}
            >
              <SearchField
                placeholder="Enter your domain here"
                onChange={this.onDomainChange}
                onEnter={this.sendToCrawler}
                onSearchClick={this.sendToCrawler}
              />
            </div>
          </div>
        )}
      </div>
    );
  }

  onDomainChange = domain => {
    this.setState({ domain });
  };

  sendToCrawler = () => {
    try {
      if (this.state.domain) {
        axios
          .post(
            `${process.env.REACT_APP_MAI_SERVER_HOST}/api/v1/projects`,
            {
              domain: this.state.domain,
              depth: 2
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
              }
            }
          )
          .then(project => {
            let projectId = project.data._id;
            this.setState({ redirect: "tasks", projectId: projectId });
          })
          .catch(err => {
            alert("Api error");
            console.log(err.message);
            const a = err.message.search("401");
            if (a > 0) {
              // alert("Session expired!! Click OK to reload!!");
              localStorage.removeItem("token");
              window.location.replace("/");
            } //token expired
          });
      }
    } catch (err) {
      console.log(err);
    }
  };
  renderRedirect = () => {
    let to = `/tasks?projectId=${this.state.projectId}`;
    if (this.state.redirect === "tasks") {
      return <Redirect to={to} />;
    }
  };
}

export default Tasks;
