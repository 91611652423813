import React, { Component } from "react";
import PropTypes from "prop-types";
import MiddleEllipsis from "react-middle-ellipsis";
import axios from "axios";
import close from "../image/close.svg";
import correctSignal from "../image/correct-signal.svg";
import pricingLink from "../image/Path 257.svg";
// import menuIcon from "../image/menu-(2).svg";
// import warningIcon from "../image/warning.svg";
// import glassIcon from "../image/magnifying-glass.svg";
// import checkIcon from "../image/iconfinder_check_alt_118699@2x.png";
import picTwo from "../image/pic-2.jpg";
import "../../App.css";
import "../Header/Header.css";
import "../Footer/Footer.css";
import "./pageDetails.css";
// import Comments from "../TaskDetails/Comments";
import IssuesList from "./issuesList";
import TaskList from "./TasksList";
import Footer from "../Footer/Footer";
import AssignContainer from "../TaskDetails/AssignContainer";
import Header from "../Header/Header.js";
import Avatar from "react-avatar";
import TasksHeaderMobile from "../Header/TasksHeaderMobile";
// import { userService } from "../../Services";

// const data = [
//   {
//     name: "Page A",
//     uv: 4000,
//     pv: 2400,
//     amt: 2400
//   },
//   {
//     name: "Page B",
//     uv: 3000,
//     pv: 1398,
//     amt: 2210
//   },
//   {
//     name: "Page C",
//     uv: 2000,
//     pv: 9800,
//     amt: 2290
//   },
//   {
//     name: "Page D",
//     uv: 2780,
//     pv: 3908,
//     amt: 2000
//   },
//   {
//     name: "Page E",
//     uv: 1890,
//     pv: 4800,
//     amt: 2181
//   },
//   {
//     name: "Page F",
//     uv: 2390,
//     pv: 3800,
//     amt: 2500
//   },
//   {
//     name: "Page G",
//     uv: 3490,
//     pv: 4300,
//     amt: 2100
//   }
// ];

// const commentsData = [
//   {
//     firstName: "Leo",
//     lastName: "Spencer",
//     commentText:
//       "Believe in yourself, take on your challenges, dig deep within yourself to conquer fears. Never let anyone bring you down. You got to keep going.",
//     image: picTwo,
//     date: "8 Nov",
//     commentCount: "256",
//     likeCount: "260"
//   },
//   {
//     firstName: "Leo",
//     lastName: "Spencer",
//     commentText:
//       "Believe in yourself, take on your challenges, dig deep within yourself to conquer fears. Never let anyone bring you down. You got to keep going.",
//     image: picTwo,
//     date: "7 Nov",
//     commentCount: "256",
//     likeCount: "260"
//   },
//   {
//     firstName: "Leo",
//     lastName: "Spencer",
//     commentText:
//       "Believe in yourself, take on your challenges, dig deep within yourself to conquer fears. Never let anyone bring you down. You got to keep going.",
//     image: picTwo,
//     date: "6 Nov",
//     commentCount: "256",
//     likeCount: "260"
//   },
//   {
//     firstName: "Leo",
//     lastName: "Spencer",
//     commentText:
//       "Believe in yourself, take on your challenges, dig deep within yourself to conquer fears. Never let anyone bring you down. You got to keep going.",
//     image: picTwo,
//     date: "5 Nov",
//     commentCount: "256",
//     likeCount: "260"
//   }
// ];

class Details extends Component {
  constructor(props) {
    super(props);
    let projectId = new URL(document.location).searchParams.get("projectId");
    let page = new URL(document.location).searchParams.get("page");
    this.state = {
      projectId: projectId ? projectId : "",
      page: page ? page : "",
      htmlAttrs: {},
      taskList: [],
      totalCount: 0,
      projectDetails: {},
      // status: "",
      // assignees: []
    };
  }

  componentDidMount() {
    const params = new URL(document.location).searchParams;
    const projectId = params.get("projectId");
    const page = params.get("page");
    this.setState({ projectId, page });
    this.getPageAttributes(projectId, decodeURIComponent(page));
    this.getTaskList(projectId, page, 5);
    this.getProjectDetails(projectId);
    // this.handleTaskStatus(true);
  }

  // handleTaskStatus = (retrieve = false, update = false, newStatus = "") => {
  //   const { projectId, page } = this.state;
  //   retrieve &&
  //     axios
  //       .get(
  //         `${process.env.REACT_APP_MAI_TASK_MANAGER_HOST}/api/v1/tasks/pages?project_id=${projectId}&url=${page}`,
  //         {
  //           headers: {
  //             Authorization: "Bearer " + localStorage.getItem("token")
  //           }
  //         }
  //       )
  //       .then(tasks => {
  //         this.setState({ status: tasks.data.data.result[0].status });
  //         // console.log(tasks.data.data.result);
  //       })
  //       .catch(err => {
  //         console.log(err.message);
  //       });
  //   update &&
  //     axios
  //       .put(
  //         `${process.env.REACT_APP_MAI_TASK_MANAGER_HOST}/api/v1/tasks?project_id=${projectId}&url=${page}`,
  //         { status: newStatus },
  //         {
  //           headers: {
  //             Authorization: "Bearer " + localStorage.getItem("token")
  //           }
  //         }
  //       )
  //       .then(res => {
  //         // console.log(res.data);
  //       })
  //       .catch(err => {
  //         console.log(err.message);
  //       });
  // };

  // handleMarkTaskButton = () => {
  //   const { status } = this.state;
  //   if (status === "Open") {
  //     return (
  //       <React.Fragment>
  //         <div className="mark-task">
  //           <img src={correctSignal} />
  //           <button
  //             className="mark-task-button"
  //             data-toggle="modal"
  //             data-target="#doneModal"
  //           >
  //             MARK AS DONE
  //           </button>
  //         </div>
  //         <div className="decline-task">
  //           <img src={close} />
  //           <button
  //             className="decline-task-button"
  //             data-toggle="modal"
  //             data-target="#declineModal"
  //           >
  //             DECLINE TASK
  //           </button>
  //         </div>
  //         <button
  //           onClick={this.showAssignContainer}
  //           className="assign"
  //           data-target="#sideBarModal"
  //           data-toggle="modal"
  //         >
  //           ASSIGN
  //         </button>
  //         {this.state.assignees.slice(0, 5).map((email, index) => (
  //           <Avatar
  //             name={email}
  //             round={true}
  //             size={46}
  //             key={index}
  //             style={{ margin: "0 20px 0 -35px", zIndex: -index }}
  //           />
  //         ))}
  //       </React.Fragment>
  //     );
  //   } else if (status === "Closed") {
  //     return (
  //       <React.Fragment>
  //         <div className="mark-task">
  //           <img src="" />
  //           <button className="mark-task-button"></button>
  //         </div>
  //         <div className="mark-task">
  //           <img src={correctSignal} />
  //           <button className="mark-task-button">COMPLETED</button>
  //         </div>
  //       </React.Fragment>
  //     );
  //   } else if (status === "Declined") {
  //     return (
  //       <React.Fragment>
  //         <div className="mark-task">
  //           <img src="" />
  //           <button className="mark-task-button"></button>
  //         </div>
  //         <div className="decline-task">
  //           <img src={close} />
  //           <button className="decline-task-button">DECLINED</button>
  //         </div>
  //       </React.Fragment>
  //     );
  //   }
  // };

  getPageAttributes = (projectId, page) => {
    var mai_server_host = localStorage.getItem("server") !== null ?
      process.env.REACT_APP_MAI_SERVER_HOST.split('.')[0] + '.' + localStorage.getItem("server") :
      process.env.REACT_APP_MAI_SERVER_HOST

    var mai_html_parser_host = localStorage.getItem("server") !== null ?
      process.env.REACT_APP_MAI_HTML_PARSER_HOST.split('.')[0] + '.' + localStorage.getItem("server") :
      process.env.REACT_APP_MAI_HTML_PARSER_HOST

    axios
      .get(
        `${mai_server_host}/api/v1/projects/${projectId}`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") }
        }
      )
      .then(res => {
        axios
          .post(`${mai_html_parser_host}/api/v1/parsed`, {
            hostname: res.data.domain,
            page
          })
          .then(res => {
            this.setState({ htmlAttrs: res.data });
          })
          .catch(err => console.log(err.message));
      })
      .catch(err => {
        console.log(err.message);
        const a = err.message.search("401");
        if (a > 0) {
          // alert("Session expired!! Click OK to reload!!");
          localStorage.removeItem("token");
          window.location.replace("/");
        }
      }); //token expired);
  };

  getTaskList = (projectId, page, totalCount) => {
    var mai_task_manager_host = localStorage.getItem("server") !== null ?
      process.env.REACT_APP_MAI_TASK_MANAGER_HOST.split('.')[0] + '.' + localStorage.getItem("server") :
      process.env.REACT_APP_MAI_TASK_MANAGER_HOST

    axios
      .get(
        `${mai_task_manager_host}/api/v1/tasks?projectId=${projectId}&url=${page}&pageNumber=1&perPage=${totalCount}`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") }
        }
      )
      .then(res => {
        this.setState({
          totalCount: res.data.data.totalCount,
          taskList: res.data.data.result
        });
      })
      .catch(err => console.log(err));
  };

  getProjectDetails = projectId => {
    var mai_server_host = localStorage.getItem("server") !== null ?
      process.env.REACT_APP_MAI_SERVER_HOST.split('.')[0] + '.' + localStorage.getItem("server") :
      process.env.REACT_APP_MAI_SERVER_HOST

    axios
      .get(
        `${mai_server_host}/api/v1/projects/${projectId}`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") }
        }
      )
      .then(res => {
        this.setState({ projectDetails: res.data });
      })
      .catch(err => {
        console.log(err.message);
        const a = err.message.search("401");
        if (a > 0) {
          // alert("Session expired!! Click OK to reload!!");
          localStorage.removeItem("token");
          window.location.replace("/");
        }
      }); //token expired);
  };

  render() {
    const {
      page,
      htmlAttrs,
      taskList,
      projectDetails,
      projectId,
      totalCount
    } = this.state;
    const { history } = this.props;
    // console.log(this.state.assignees);
    return (
      <div>
      <div className="desktop-view">
        <Header />

        {/* Main Division */}
        {taskList.length > 0 ?
          <main className="container-fluid">
            {/* Section-1/Assign */}
            <div className="table-breadcrumbs">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <button
                      className="plain-button"
                      onClick={() =>
                        history.push(`/tasks?projectId=${projectId}`)
                      }
                    >
                      {projectDetails.domain}
                    </button>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Tasks
                 </li>
                </ol>
              </nav>
            </div>
            <section className="pricing-Back">
              <div className="pricing-block">
                <div className="pricing-icon">
                  <a href={page} target="_blank" rel="noopener noreferrer">
                    <img src={pricingLink} alt="goto" />
                  </a>
                </div>
                <div
                  className="pricing-text"
                  title={page
                    .replace(/\/$/, "")
                    .substr(page.replace(/\/$/, "").lastIndexOf("/") + 1)}
                >
                  
                    <span>
                      {page
                        .replace(/\/$/, "")
                        .substr(page.replace(/\/$/, "").lastIndexOf("/") + 1)}
                    </span>
                  
                </div> <br/>
                <div className="pricing-tag">Medium</div>
              </div>
              {/* {this.handleMarkTaskButton()} */}
              <div className="details-assigned-container">
                {/* <div className="details-assignPerson-one">
                             </div>
                             <div className="details-assignPerson-rest">
                             </div> */}
              </div>
            </section>
            {/* Section-2/Rating */}
            <div className="mainWrapper">
              

              <div className="ratingTraffic">
                  <b>{totalCount}</b> TASKS
               </div>

              {/* Section-3/content in column */}
              <IssuesList htmlAttrs={htmlAttrs} />

              {/* Section-4/Affected page / Task List */}
              <TaskList
                projectId={projectId}
                page={page}
                taskList={taskList}
                totalCount={totalCount}
                getTaskList={this.getTaskList}
                history={history}
              />

              {/* Section-5/write comment */}
              {/* <section className="saySomthing">
                             <input type="text" placeholder="Say something" className="write-comments" />
                             <img src="img/telegram.svg" />
                         </section> */}
              {/* Section-6/ show Comments */}
              {/* <section className="comments-wrapper">
                             <Comments comments={commentsData} />
                         </section> */}
            </div>
            {/* Section-7/Pre footer */}
            <Footer />
            {/* 
           <div class="container">
             <div class="modal" id="doneModal">
               <div class="confirm-modal-dialog confirm-modal">
                 <div class="modal-content">
                   <div class="modal-header">
                     <h4 class="modal-title">Mark as done</h4>
                     <button type="button" class="close" data-dismiss="modal">
                       &times;
                     </button>
                   </div>
 
                   <div class="confirmModalbody">
                     Are you sure this task is done?
                   </div>
 
                   <div class="modal-footer">
                     <button
                       type="button"
                       class="btn no-btn"
                       data-dismiss="modal"
                     >
                       No
                     </button>
                     <button
                       type="button"
                       class="btn yes-btn"
                       data-dismiss="modal"
                       onClick={() => {
                         this.handleTaskStatus(false, true, "Closed");
                         this.setState({ status: "Closed" });
                       }}
                     >
                       Yes
                     </button>
                   </div>
                 </div>
               </div>
             </div>
           </div>
 
           <div class="container">
             <div class="modal" id="declineModal">
               <div class="confirm-modal-dialog confirm-modal">
                 <div class="modal-content">
                   <div class="modal-header">
                     <h4 class="modal-title">Decline Task</h4>
                     <button type="button" class="close" data-dismiss="modal">
                       &times;
                     </button>
                   </div>
 
                   <div class="confirmModalbody">
                     Are you sure you want to decline this task?
                   </div>
 
                   <div class="modal-footer">
                     <button
                       type="button"
                       class="btn no-btn"
                       data-dismiss="modal"
                     >
                       No
                     </button>
                     <button
                       type="button"
                       class="btn yes-btn"
                       data-dismiss="modal"
                       onClick={() => {
                         this.handleTaskStatus(false, true, "Declined");
                         this.setState({ status: "Declined" });
                       }}
                     >
                       Yes
                     </button>
                   </div>
                 </div>
               </div>
             </div>
           </div>
 
           <AssignContainer
             assigned={assignees => {
               this.setState({ assignees });
             }}
           /> */}
          </main>
          :
          <div style={{ marginTop: "40vh", marginBottom: "40vh", marginLeft: "50vw" }}>
            <div class="boxes">
              <div class="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div class="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div class="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div class="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        }
        
      </div>
      <div className="mob-view">
      <TasksHeaderMobile hideTitle={true} hideFilters={true} history={history} />
      {taskList.length > 0 ?
          <main className="container-fluid">
            {/* Section-1/Assign */}
            <div className="mob-table-breadcrumbs">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <button
                      className="plain-button"
                      onClick={() =>
                        history.push(`/tasks?projectId=${projectId}`)
                      }
                    >
                      {projectDetails.domain}
                    </button>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Tasks
                 </li>
                </ol>
              </nav>
            </div>
            <section className="mob-pricing-Back">
              <div className="pricing-block">
                <div className="pricing-icon">
                  <a href={page} target="_blank" rel="noopener noreferrer">
                    <img src={pricingLink} alt="goto" />
                  </a>
                </div>
                <div
                  className="pricing-text"
                  title={page
                    .replace(/\/$/, "")
                    .substr(page.replace(/\/$/, "").lastIndexOf("/") + 1)}
                >
                 
                    <span>
                      {page
                        .replace(/\/$/, "")
                        .substr(page.replace(/\/$/, "").lastIndexOf("/") + 1)}
                    </span>
                </div><br/>
                <div className="pricing-tag">Medium</div>
              </div>
              {/* {this.handleMarkTaskButton()} */}
              <div className="details-assigned-container">
                {/* <div className="details-assignPerson-one">
                             </div>
                             <div className="details-assignPerson-rest">
                             </div> */}
              </div>
            </section>
            {/* Section-2/Rating */}

            <div className="mob-mainWrapper">
            <div className="ratingTraffic" style={{ marginLeft:"2.5%"}}>
                  <b>{totalCount}</b> TASKS
            </div>
              
              <div style={{width:"95%", marginLeft:"2.5%"}}>
              {/* Section-3/content in column */}
                <IssuesList htmlAttrs={htmlAttrs} />
              </div>
              {/* Section-4/Affected page / Task List */}
              
              <TaskList
                projectId={projectId}
                page={page}
                taskList={taskList}
                totalCount={totalCount}
                getTaskList={this.getTaskList}
                history={history}
              />
              

              {/* Section-5/write comment */}
              {/* <section className="saySomthing">
                             <input type="text" placeholder="Say something" className="write-comments" />
                             <img src="img/telegram.svg" />
                         </section> */}
              {/* Section-6/ show Comments */}
              {/* <section className="comments-wrapper">
                             <Comments comments={commentsData} />
                         </section> */}
            </div>
            {/* Section-7/Pre footer */}
            <Footer />
            {/* 
           <div class="container">
             <div class="modal" id="doneModal">
               <div class="confirm-modal-dialog confirm-modal">
                 <div class="modal-content">
                   <div class="modal-header">
                     <h4 class="modal-title">Mark as done</h4>
                     <button type="button" class="close" data-dismiss="modal">
                       &times;
                     </button>
                   </div>
 
                   <div class="confirmModalbody">
                     Are you sure this task is done?
                   </div>
 
                   <div class="modal-footer">
                     <button
                       type="button"
                       class="btn no-btn"
                       data-dismiss="modal"
                     >
                       No
                     </button>
                     <button
                       type="button"
                       class="btn yes-btn"
                       data-dismiss="modal"
                       onClick={() => {
                         this.handleTaskStatus(false, true, "Closed");
                         this.setState({ status: "Closed" });
                       }}
                     >
                       Yes
                     </button>
                   </div>
                 </div>
               </div>
             </div>
           </div>
 
           <div class="container">
             <div class="modal" id="declineModal">
               <div class="confirm-modal-dialog confirm-modal">
                 <div class="modal-content">
                   <div class="modal-header">
                     <h4 class="modal-title">Decline Task</h4>
                     <button type="button" class="close" data-dismiss="modal">
                       &times;
                     </button>
                   </div>
 
                   <div class="confirmModalbody">
                     Are you sure you want to decline this task?
                   </div>
 
                   <div class="modal-footer">
                     <button
                       type="button"
                       class="btn no-btn"
                       data-dismiss="modal"
                     >
                       No
                     </button>
                     <button
                       type="button"
                       class="btn yes-btn"
                       data-dismiss="modal"
                       onClick={() => {
                         this.handleTaskStatus(false, true, "Declined");
                         this.setState({ status: "Declined" });
                       }}
                     >
                       Yes
                     </button>
                   </div>
                 </div>
               </div>
             </div>
           </div>
 
           <AssignContainer
             assigned={assignees => {
               this.setState({ assignees });
             }}
           /> */}
          </main>
          :
          <div style={{ marginTop: "75%",  marginLeft: "37%" }}>
            <div class="boxes">
              <div class="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div class="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div class="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div class="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        }
        
      </div>
      </div>
      
    );
    
  }
}

Details.propTypes = {
  history: PropTypes.object.isRequired
};

export default Details;
