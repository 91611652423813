import React from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
  button: {
    display: "block",
    marginTop: theme.spacing(3),
    marginLeft: "3px",
    marginRight: "3px"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
}));

export default function ControlledOpenSelect(props) {
  const classes = useStyles();
  const [priority, setPriority] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const handleChange = event => {
    setPriority(event.target.value);
    const { onPriorityChange } = props;

    onPriorityChange && onPriorityChange(event.target.value)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const checkEnalbleSelect = () => {
    const { taskIdList } = props;
    if (taskIdList.length === 0 && !!!priority) return true;
    else return false;
  };
  return (
    <div style={{ display: "flex" }}>
      <div>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-controlled-open-select-label">
            Priority
          </InputLabel>
          <Select
            labelId="demo-controlled-open-select-label"
            id="demo-controlled-open-select"
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={priority}
            onChange={handleChange}
            disabled={checkEnalbleSelect()}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={"High"}>High</MenuItem>
            <MenuItem value={"Medium"}>Medium</MenuItem>
            <MenuItem value={"Low"}>Low</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
}
