import React from "react";
import axios from "axios";
import { userService } from "../../Services";
import searchIcon from "../../img/icons8-search.svg";
import Avatar from "react-avatar";
// import person from "../../img/person-1.jpg";
import addIcon from "../../img/add-user.svg";
// import './styles.css'
import UpdateIcon from '@material-ui/icons/Update';
import RepeatSharpIcon from '@material-ui/icons/RepeatSharp';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


export default class AssignContainer extends React.Component {
  constructor(props) {
    super(props);
    let projectId = new URL(document.location).searchParams.get("projectId");
    let task = new URL(document.location).searchParams.get("task");
    let page = new URL(document.location).searchParams.get("page");
    this.state = {
      projectId: projectId ? projectId : "",
      task: task ? task : "",
      page: page ? page : "",
      collaborators: [],
      email: "",
      assignees: [],
      unassigned: [],
      unassignedStatus:{},
      adduser: false
    };
  }

  componentDidMount() {
    const { page, task, projectId } = this.state;
    this.getProjectCollab(projectId);
    page && this.getDataByPage(projectId, page);
    task && this.getDataByTask(projectId, task);
    setTimeout(() => {
      this.updateStatus()
    }, 500);
    
  }

  handleUnassigned = (newAssignees, newCollaborators) => {
    const { assignees, unassigned, collaborators } = this.state;
    const uniqueAssignees = new Set([...assignees, ...newAssignees]);
    const uniqueCollaborators = new Set([
      ...collaborators,
      ...newCollaborators
    ]);
    const uniqueUnassigned = new Set(
      Array.from(uniqueCollaborators).filter(
        x => !Array.from(uniqueAssignees).includes(x)
      )
    );
    this.props.assigned(Array.from(uniqueAssignees));
    this.setState({
      assignees: Array.from(uniqueAssignees),
      unassigned: Array.from(uniqueUnassigned),
      collaborators: Array.from(uniqueCollaborators)
    });
    var unassignedStatus={}
    uniqueUnassigned.forEach(email=>{
      unassignedStatus[email]=false
    })
    this.setState({unassignedStatus})
  };

  getDataByTask = async (projectId, task) => {
    var mai_task_manager_host = localStorage.getItem("server") !== null ?
      process.env.REACT_APP_MAI_TASK_MANAGER_HOST.split('.')[0] + '.' + localStorage.getItem("server") :
      process.env.REACT_APP_MAI_TASK_MANAGER_HOST

    axios
      .get(
        `${mai_task_manager_host}/api/v1/tasks?project_id=${projectId}&error_code=${task}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          }
        }
      )
      .then(tasks => {
        this.handleUnassigned(tasks.data.data.result[0].assignees, []);
      })
      .catch(err => {
        console.log(err.message);
      });
  };

  getDataByPage = async (projectId, page) => {
    var mai_task_manager_host = localStorage.getItem("server") !== null ?
      process.env.REACT_APP_MAI_TASK_MANAGER_HOST.split('.')[0] + '.' + localStorage.getItem("server") :
      process.env.REACT_APP_MAI_TASK_MANAGER_HOST

    axios
      .get(
        `${mai_task_manager_host}/api/v1/tasks/pages?project_id=${projectId}&url=${page}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          }
        }
      )
      .then(tasks => {
        this.handleUnassigned(tasks.data.data.result[0].assignees, []);
      })
      .catch(err => {
        console.log(err.message);
      });
  };

  getProjectCollab = projectId => {
    var mai_server_host = localStorage.getItem("server") !== null ?
      process.env.REACT_APP_MAI_SERVER_HOST.split('.')[0] + '.' + localStorage.getItem("server") :
      process.env.REACT_APP_MAI_SERVER_HOST

    axios
      .get(
        `${mai_server_host}/api/v1/projects/${projectId}`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") }
        }
      )
      .then(res => {
        this.handleUnassigned([], [...res.data.collaborators, res.data.email]);
      })
      .catch(err => {
        console.log(err.message);
      });
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    if (/(.+)@(.+){2,}\.(.+){2,}/.test(value)) {
      if (this.state.collaborators.length > 0) {
        const adduser = this.state.collaborators.every(
          email => !email.includes(value)
        );
        this.setState({ adduser });
      }
    } else {
      this.setState({ adduser: false });
    }
  };

  handleEnterKeyPress = e => {
    if (e.key && e.key === "Enter") this.handleSubmit();
  };

  handleSubmit = () => {
    const { email, collaborators, unassigned, projectId } = this.state;
    if (/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
      // valid email
      
      
      userService.EmailSignup(email,"Assign").then( data => {
        //console.log(data)
      const uniqueCollaborators = new Set(collaborators);
      const uniqueUnassigned = new Set(unassigned);
      uniqueCollaborators.add(email);
      uniqueUnassigned.add(email);
      this.setState({
        collaborators: Array.from(uniqueCollaborators),
        unassigned: Array.from(uniqueUnassigned),
        email: "",
        adduser: false
      });
      var mai_server_host = localStorage.getItem("server") !== null ?
        process.env.REACT_APP_MAI_SERVER_HOST.split('.')[0] + '.' + localStorage.getItem("server") :
        process.env.REACT_APP_MAI_SERVER_HOST
      axios
        .put(
          `${mai_server_host}/api/v1/projects/${projectId}`,
          { collaborators: Array.from(uniqueCollaborators) },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            }
          }
        )
        .then(res => {
          // console.log(res.data.collaborators);
        })
        .catch(err => {
          console.log(err.message);
        });
        this.updateStatus()
      })
      .catch(error => {alert("unable to send verification mail");})



    } else {
      // invalid email
      alert("Invalid Email Address\nFormat: user@domain.extension");
    }
  };

  updateAssignees = assignees => {
    const { page, task, projectId } = this.state;
    // console.log(assignees);
    var mai_task_manager_host = localStorage.getItem("server") !== null ?
      process.env.REACT_APP_MAI_TASK_MANAGER_HOST.split('.')[0] + '.' + localStorage.getItem("server") :
      process.env.REACT_APP_MAI_TASK_MANAGER_HOST

    page &&
      axios
        .put(
          `${mai_task_manager_host}/api/v1/tasks?project_id=${projectId}&url=${page}`,
          { assignees: assignees },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            }
          }
        )
        .then(res => {
          // console.log(res.data);
        })
        .catch(err => {
          console.log(err.message);
        });
    task &&
      axios
        .put(
          `${mai_task_manager_host}/api/v1/tasks?project_id=${projectId}&error_code=${task}`,
          { assignees: assignees },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            }
          }
        )
        .then(res => {
          // console.log(res.data);
        })
        .catch(err => {
          console.log(err.message);
        });
  };
  handleAssign = email => {
    const assignees = [...this.state.assignees, email];
    this.setState({
      assignees,
      unassigned: this.state.unassigned.filter(e => e !== email)
    });
    this.props.assigned(assignees);
    this.updateAssignees(assignees);
    this.assignedMail(email)
  };

  handleRemove = email => {
    const assignees = this.state.assignees.filter(e => e !== email);
    this.setState({
      assignees,
      unassigned: [...this.state.unassigned, email]
    });
    setTimeout(() => {
      this.updateStatus()
    }, 300);
    this.props.assigned(assignees);
    this.updateAssignees(assignees);
  };

  placeholder = () => {
    if (this.state.collaborators.length > 0) {
      return "Search / Add Team Members";
    } else {
      return "Add Team Members";
    }
  };

  handleAddUser = () => {
    if (this.state.collaborators.length === 0) {
      return true;
    } else {
      return this.state.adduser;
    }
  };

  updateStatus = ()=>{
    var {unassigned} = this.state
    unassigned.forEach(email=>{
      this.getEmailStatus(email)
    })
  }


   getEmailStatus =  (email) =>{
    var {unassignedStatus} = this.state
    userService.UserStatus(email).then(data=>{
        
        if (data.data.status === "success"){
          console.log("SDs",email,data.data.data.status)
          unassignedStatus[email]=data.data.data.status
          this.setState({unassignedStatus})
        }
        else{
          unassignedStatus[email]=false
          this.setState({unassignedStatus})
        }
    })
    .catch(err=>{
      console.log(err)
      unassignedStatus[email]=false
          this.setState({unassignedStatus})
    })

  }
  resendMail = (email)=> {
      userService.EmailSignup(email,"resend")
  }

  assignedMail = (email)=>{
    const {mainProps}=this.props
    try{
    var data={
      "toList": email,
      "templateId": "assignTask",
      "templateData": {
        "subject":"New task assigned to you in "+mainProps.projectDetails.domain,
        "assignedBy":localStorage.getItem("user"),
        "projectId": mainProps.projectDetails.domain,
        "ProjectURL": window.location.href.split("/task")[0]+`/tasks?projectId=${mainProps.projectId}`,
        "taskNo":"1",
        "tasks":
        [
          {
            "taskId":mainProps.errorData.error,
            "taskURL":window.location.href
          }
         ]
      }
    }
    }
    catch{
      return
    }

    userService.assignedMail(data).then().catch(err=>console.log(err))
    
  }

  state = {
    openAssignContainer:false
  }

  handleClickOpenAssignContainer = () => {
    this.setState({openAssignContainer:true});
  };

  handleCloseAssignContainer = () => {
    this.setState({openAssignContainer:false});
  };

  render() {
    const { assignees, unassigned, email } = this.state;
    return (
      <>
      <div className = "desktop-view">
      <div className="container">
        <div className="modal fade" id="sideBarModal" >
          <div className="modal-dialog">
            <div className="modal-content modal-sidebar-content">
              <div className="side-bar-wrapper">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>

                <div className="modal-header modal-sidebar-header">
                  <section className="search-back">
                    <div className="search-bar">
                      <input
                        type="text"
                        name="email"
                        value={email}
                        className="search-input"
                        placeholder={this.placeholder()}
                        onChange={this.handleChange}
                        onKeyPress={this.handleEnterKeyPress}
                      />

                      <div className="search-icon">
                        {this.handleAddUser() === true ?
                          (<button className="add-assignee-button"
                            onClick={this.handleSubmit}> +ADD </button>)
                          : ""}
                      </div>
                    </div>
                  </section>
                </div>

                <div className="modal-body ">
                  <div className="container-custom">
                    <section>
                      <div className="sidebar-employee-title">
                        Assigned Team Members 
                      </div>

                      {assignees.map(
                        email =>
                          email.includes(this.state.email) && (
                            <div className="sidebar-employee-details">
                              <div className="employee-image">
                                <Avatar name={email} round={true} size={50} />
                              </div>
                              <div className="employee-detail">
                                <div className="em-name">{email.split('@')[0]}</div>
                                <div className="em-designation">
                                  {'@' + email.split('@')[1]}
                                </div>
                              </div>
                              <div className="em-button-container">
                                <div className="btn-task">
                                  <button
                                    type="button"
                                    className="btn"
                                    onClick={() => this.handleRemove(email)}
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                            </div>
                          )
                      )}
                    </section>
                    <section>
                      <div className="sidebar-employee-title">
                        Unassigned Team Members <span >
                        <Tooltip title="update the list" interactive><button className="updatelist" onClick={()=>this.updateStatus()}><UpdateIcon/></button>
                        </Tooltip>
                        </span>
                      </div>

                      {unassigned.map(
                        email =>
                          email.includes(this.state.email) && (
                            <div className="sidebar-employee-details">
                              <div className="employee-image">
                                <Avatar name={email} round={true} size={50} />
                              </div>
                              <div className="employee-detail">
                                <div className="em-name">{email.split('@')[0]}</div>
                                <div className="em-designation">
                                  {'@' + email.split('@')[1]}
                                </div>
                              </div>
                              <div className="em-button-container">
                                <div style={{float: 'left'}}>
                                {(this.state.unassignedStatus[email])?null:
                                <Tooltip title="Resend verification mail" interactive>
                                      <button onClick={()=>this.resendMail(email)} className="updatelist" style={{"margin":"5px","margin-top":"15px"}}> <RepeatSharpIcon/></button>
                                </Tooltip> }
                                </div>

                                <div className="btn-task">
                                {/* { console.log(this.state.unassignedStatus)} */}
                                {(this.state.unassignedStatus[email])?
                                  <button
                                    type="button"
                                    className="btn"
                                    onClick={() => this.handleAssign(email)}
                                  >
                                    Assign
                                  </button>
                                  :
                                  <button
                                    type="button"
                                    className="btn"
                                    onClick={() => this.handleAssign(email)}
                                    disabled="true"
                                  >
                                    Unverified
                                  </button>
                                }
                                </div>
                              </div>
                            </div>
                          )
                      )}
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div className="mob-view">
      {this.props.hideAssignButton ? (
                    <p style={{marginLeft:"-20%"}} onClick={this.showAssignContainer} onClick={this.handleClickOpenAssignContainer}>Assign</p>
                ) : (
                  <button
                  style={{marginLeft:"-3%"}}
                        onClick={this.showAssignContainer}
                        className="assign"
                        onClick={this.handleClickOpenAssignContainer}
                      >
                        ASSIGN
                  </button>
      )}

      
      
      
      
      <Dialog fullScreen open={this.state.openAssignContainer} onClose={this.handleCloseAssignContainer}>

      
                <button type="button" className="close" style={{position:"absolute", top:"5px", right:"0", width:"60px", fontSize:"35px"}} onClick={this.handleCloseAssignContainer}>
                  &times;
                </button>

                <div className="modal-header modal-sidebar-header">
                  <section className="search-back">
                    <div className="search-bar">
                      <input
                        type="text"
                        name="email"
                        value={email}
                        className="search-input"
                        placeholder={this.placeholder()}
                        onChange={this.handleChange}
                        onKeyPress={this.handleEnterKeyPress}
                      />

                      <div className="search-icon">
                        {this.handleAddUser() === true ?
                          (<button className="add-assignee-button"
                            onClick={this.handleSubmit}> +ADD </button>)
                          : ""}
                      </div>
                    </div>
                  </section>
                </div>

                <div className="modal-body ">
                  <div className="container-custom">
                    <section>
                      <div className="sidebar-employee-title">
                        Assigned Team Members 
                      </div>

                      {assignees.map(
                        email =>
                          email.includes(this.state.email) && (
                            <div className="sidebar-employee-details">
                              <div className="employee-image">
                                <Avatar name={email} round={true} size={50} />
                              </div>
                              <div className="employee-detail">
                                <div className="em-name">{email.split('@')[0]}</div>
                                <div className="em-designation">
                                  {'@' + email.split('@')[1]}
                                </div>
                              </div>
                              <div className="em-button-container">
                                <div className="btn-task">
                                  <button
                                    type="button"
                                    className="btn"
                                    onClick={() => this.handleRemove(email)}
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                            </div>
                          )
                      )}
                    </section>
                    <section>
                      <div className="sidebar-employee-title">
                        Unassigned Team Members 
                        <button style ={{border: "none",backgroundColor: "rgba(0,0,0,0)", marginLeft:"10%"}} onClick={()=>this.updateStatus()}><UpdateIcon/></button>
                      </div>

                      {unassigned.map(
                        email =>
                          email.includes(this.state.email) && (
                            <div className="sidebar-employee-details">
                              <div className="employee-image">
                                <Avatar name={email} round={true} size={50} />
                              </div>
                              <div className="employee-detail">
                                <div className="em-name">{email.split('@')[0]}</div>
                                <div className="em-designation">
                                  {'@' + email.split('@')[1]}
                                </div>
                              </div>
                              <div className="em-button-container">
                                <div style={{float: 'left'}}>
                                {(this.state.unassignedStatus[email])?null:
                                <Tooltip title="Resend verification mail" interactive>
                                      <button onClick={()=>this.resendMail(email)} className="updatelist" style={{"margin":"5px","margin-top":"15px"}}> <RepeatSharpIcon/></button>
                                </Tooltip> }
                                </div>

                                <div className="btn-task">
                                { console.log(this.state.unassignedStatus)}
                                {(this.state.unassignedStatus[email])?
                                  <button
                                    type="button"
                                    className="btn"
                                    onClick={() => this.handleAssign(email)}
                                  >
                                    Assign
                                  </button>
                                  :
                                  <button
                                    type="button"
                                    className="btn"
                                    onClick={() => this.handleAssign(email)}
                                    disabled="true"
                                  >
                                    Unverified
                                  </button>
                                }
                                </div>
                              </div>
                            </div>
                          )
                      )}
                    </section>
                  </div>
                </div>
      </Dialog>
      </div>
      </>
    );
  }
}
