import React, { Component } from "react";
import PropTypes from "prop-types";
// import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";

import _ from "lodash";
import LeftLists from "./LeftList";
// import AppBox from "../../AppBox/AppBox";
// import minus from "../../image/minus.svg";
// import worldwide from "../../image/worldwide.svg";
// import Arrow from "../../image/Arrow.svg";
import Footer from "../../Footer/Footer";
import Loader from "../../Loader/Loader";

import Header from "../../Header/Header";
import TaskList from "./TaskList";
// import Competitors from "./Competitors";

import Visualizations from "../Visualizations";
import "./TaskDesktop.css";

export default class TaskDesktop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbarOpen: false,
      snackbarMessage: undefined,
      noData: true,
    };
  }

  componentDidMount() {
    let { message } = window.history.state || { message: "" };
    if (message) {
      this.setState({ snackbarMessage: message, snackbarOpen: true });
      window.history.replaceState(null, "");
    }
    setTimeout(() => {
      const {
        tasksCount,
        fetchedCount,
        analysedCount,
        discoveredCount,
      } = this.props;
      if (
        !(
          discoveredCount > 1 ||
          tasksCount > 0 ||
          fetchedCount > 0 ||
          analysedCount > 0
        )
      ) {
        this.setState({ noData: false });
      }
    }, 30000);
  }

  handleSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };

  render() {
    const {
      history,
      projectId,
      projects,
      totalCount,
      uniqueCount,
      tasks,
      setHeaderSelection,
      setFiltersApplied,
      filters,
      isDomainError,
      errorMessage,
      crawledCount,
      fetchedCount,
      analysedCount,
      discoveredCount,
      tasksCount,
    } = this.props;

    const { noData, snackbarMessage, snackbarOpen } = this.state;

    const conditionalRendering = !(
      discoveredCount > 1 ||
      tasksCount > 0 ||
      fetchedCount > 0 ||
      analysedCount > 0
    );

    const getChartData = () => {
      var chartData = {
        SEO: {
          High: 0,
          Low: 0,
          Medium: 0,
        },
        PPC: {
          High: 0,
          Low: 0,
          Medium: 0,
        },
        Social: {
          High: 0,
          Low: 0,
          Medium: 0,
        },
        Crawl: {
          High: 0,
          Low: 0,
          Medium: 0,
        },
      };

      tasks.forEach((task) => {
        var { tag, priority, pageCount } = task;
        chartData[tag][priority] += pageCount;
      });

      return chartData;
    };

    const selectedProject = _.find(projects, { _id: projectId });
    const chartData = getChartData();

    const allSelected = Object.keys(filters)
      .splice(0, 4)
      .filter((cat) => {
        if (cat === "status") {
          return (
            filters[cat][0].selected === true ||
            filters[cat][1].selected === true
          );
        }
        return filters[cat][0].selected === true;
      });

    return (
      <div className="desktop-view">
        {snackbarMessage !== undefined && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={snackbarOpen}
            autoHideDuration={10000}
            onClose={this.handleSnackbarClose}
          >
            <SnackbarContent
              style={{ backgroundColor: "#47d147", fontWeight: "bold" }}
              message={snackbarMessage}
              action={
                <React.Fragment>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={this.handleSnackbarClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              }
            />
          </Snackbar>
        )}

        {!snackbarOpen && discoveredCount > crawledCount && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={true}
            autoHideDuration={null}
          >
            <SnackbarContent
              style={{
                backgroundColor: "#f26363",
                fontFamily: "IBM Plex Sans",
                fontWeight: "bold",
                fontSize: 15,
              }}
              message={"Crawl limit reached, please upgrade! "}
              action={
                <React.Fragment>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      history.push(`/Accounts`);
                    }}
                    style={{ backgroundColor: "#f0e9e9" }}
                  >
                    UPGRADE
                  </Button>
                </React.Fragment>
              }
            />
          </Snackbar>
        )}

        <Header
          projectId={projectId}
          setHeaderSelection={setHeaderSelection}
          projects={projects}
        />
        {conditionalRendering ? (
          noData ? (
            <div
              style={{
                marginTop: "40vh",
                marginBottom: "40vh",
                marginLeft: "50vw",
              }}
            >
              <div class="boxes">
                <div class="box">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div class="box">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div class="box">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div class="box">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ textAlign: "center" }}>
              <img
                src={require("./nodata.png")}
                style={{
                  maxHeight: "50vh",
                  maxWidth: "50vw",
                  marginTop: "5vh",
                }}
              />
              <p style={{ marginTop: 45 }}>
                <span style={{ fontSize: 50, color: "#ff6969" }}>
                  No data to be shown
                </span>
                <br />
                <div style={{ marginTop: 15 }}>
                  <small style={{ fontSize: 20 }}>
                    Seems like the website is too lean to crawl and generate
                    data for. Please try another website URL.
                  </small>
                </div>
              </p>
            </div>
          )
        ) : (
          <div>
            <main className="main-div">
              <div className="row main-wrapper">
                <div>
                  <div className="header-main">
                    <ul className="breadcrumb">
                      <li>
                        {selectedProject ? (
                          <a
                            href={selectedProject.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {selectedProject.domain}
                          </a>
                        ) : null}
                      </li>
                    </ul>
                    <span className="page-head">TASKS</span>
                  </div>
                  <LeftLists
                    setFiltersApplied={setFiltersApplied}
                    filters={filters}
                  />
                </div>

                <div className="main-tasks-container">
                  {allSelected.length === 4 ? (
                    <Visualizations
                      filters={filters}
                      chartData={chartData}
                      textData={{
                        crawledCount,
                        analysedCount,
                        tasksCount,
                        fetchedCount,
                        discoveredCount,
                      }}
                    />
                  ) : (
                    <span></span>
                  )}
                  {isDomainError ? (
                    <p className="domain-error">{errorMessage}</p>
                  ) : totalCount === undefined ? (
                    <div
                      style={{
                        marginTop: "40vh",
                        left: "50vw",
                        position: "absolute",
                      }}
                    >
                      <div class="boxes">
                        <div class="box">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                        <div class="box">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                        <div class="box">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                        <div class="box">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div>{!totalCount ? <span /> : null}</div>
                      {totalCount !== 0 && selectedProject ? (
                        <div>
                          <section className="tasks-for-website">
                            {uniqueCount.toLocaleString()} Tasks for{" "}
                            {selectedProject.domain}
                          </section>
                        </div>
                      ) : noData && !totalCount && !analysedCount ? (
                        <p className="task-loading">Tasks are loading...</p>
                      ) : totalCount !== undefined && discoveredCount > 1 ? (
                        <p className="domain-error">No Tasks Available.</p>
                      ) : null}
                      <TaskList
                        tasks={tasks}
                        projectId={projectId}
                        history={history}
                      />
                    </>
                  )}
                </div>
              </div>
            </main>
            <Footer />
          </div>
        )}
      </div>
    );
  }
}

TaskDesktop.propTypes = {
  history: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired,
  projects: PropTypes.array.isRequired,
  totalCount: PropTypes.number.isRequired,
  uniqueCount: PropTypes.number.isRequired,
  tasks: PropTypes.array.isRequired,
  setHeaderSelection: PropTypes.func.isRequired,
  setFiltersApplied: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  isDomainError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
};
